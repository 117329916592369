import styled from '@emotion/styled';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AnomalyTimeLineChart from '@/components/molecules/Charts/AnomalyTimeLineChart';
import ColumnChart from '@/components/molecules/Charts/ColumnChart';
import mapChainData from '@/helpers/mapChainData';
import { IExtendedAlertUpdateDto } from '@/types/alert';

const UpdatePanel = styled.div`
  display: flex;
  width: auto;
  height: auto;
  padding: 30px 100px 30px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #2e2e31;
`;

const ContainerUpdates = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 30px 50px 30px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #2e2e31;
`;

const ContainerTitle = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;

const SubTitle = styled.span`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
`;

const Description = styled.span`
  color: #f7f7f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledDate = styled.span`
  color: #f7f7f7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const UpdateTitle = styled.span`
  color: #f7f7f7;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;

const ContainerTimestamp = styled.div`
  display: flex;
  width: 107px;
  height: 27px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid #242424;
  background: #1b1b1e;
`;

const StyledCalendarTodayOutlinedIcon = styled(CalendarTodayOutlinedIcon)`
  color: #f7f7f7;
  width: 16px;
  height: 16px;
`;

const TitleAndTimestampContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

interface UpdatesPanelProps {
  updates: IExtendedAlertUpdateDto[];
  alertTimestamp: string;
  alertChangeAmount: number | null;
  alertId: string;
}

const UpdatesPanel = ({ updates, alertId }: UpdatesPanelProps) => {
  const counterUpdates = updates.length;
  return (
    <UpdatePanel>
      <ContainerTitle>updates</ContainerTitle>
      <SubTitle>This alert has had {counterUpdates} updates at the moment. Check the detail below:</SubTitle>
      {updates
        .filter((update) => !update.deleted)
        .map((update, index) => {
          const chartData = mapChainData(update.chainData);

          const anomalyChainData = chartData.sort((a, b) => a.x - b.x)[chartData.length - 1];

          return (
            <ContainerUpdates key={index}>
              <TitleAndTimestampContainer>
                <UpdateTitle>{update.title}</UpdateTitle>
                <ContainerTimestamp>
                  <StyledCalendarTodayOutlinedIcon />
                  <StyledDate>{update.timestamp}</StyledDate>
                </ContainerTimestamp>
              </TitleAndTimestampContainer>
              <Description>
                <div dangerouslySetInnerHTML={{ __html: update.details }} />
              </Description>

              {update?.showChart && update?.chainData && (
                <>
                  <AnomalyTimeLineChart alertId={alertId} anomalyChainData={anomalyChainData} chartData={chartData} />
                  <ColumnChart flowData={mapChainData(update.netflowData)} />
                </>
              )}
            </ContainerUpdates>
          );
        })}
    </UpdatePanel>
  );
};

export default UpdatesPanel;
