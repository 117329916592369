export enum ESettingsNotifications {
  NEWSLETTER = 'newsletter',
  ANOMALIES = 'anomalies',
  UPDATES = 'updates',
  STATISTICAL_RATINGS = 'statistical_ratings',
}

export interface INotification {
  name: ESettingsNotifications;
  enabled: boolean;
}

export interface ICustomNotification {
  text: string;
  type: 'success' | 'error' | '';
}
