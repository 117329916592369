import { TFlowChart } from '@/types/flows';
import { IOption } from '@/types/select';

export const flowOptions: TFlowChart[] = [
  'netflow',
  'inflow',
  'outflow',
  'totalflow',
  'netflow-hourly',
  'inflow-hourly',
  'outflow-hourly',
  'totalflow-hourly',
];

export const flowDataChartOptions: IOption[] = [
  { value: 'netflow', label: 'Netflow' },
  { value: 'inflow', label: 'Inflow' },
  { value: 'outflow', label: 'Outflow' },
  { value: 'totalflow', label: 'Total flows' },
  { value: 'netflow-hourly', label: 'Netflow (hourly)' },
  { value: 'inflow-hourly', label: 'Inflow (hourly)' },
  { value: 'outflow-hourly', label: 'Outflow (hourly)' },
  { value: 'totalflow-hourly', label: 'Total flows (hourly)' },
];
