import { create } from 'zustand';
import { IExtendedAlertUpdateDto } from '@/types/alert';

interface AlertStoreState {
  updates: IExtendedAlertUpdateDto[];
  setUpdates: (newUpdates: IExtendedAlertUpdateDto[]) => void;
}

export const useAlertStore = create<AlertStoreState>((set) => ({
  updates: [],
  setUpdates: (newUpdates: IExtendedAlertUpdateDto[]) => set({ updates: newUpdates }),
}));
