import { availablePageSizes } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/constants';
import AdminPanelAlertList from '@/components/molecules/AdminPanelAlertList/AdminPanelAlertList';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { TAnomaly } from '@/types/anomaly';

interface IAnomaliesListProps {
  anomaliesList: TAnomaly[];
  inReviewAnomaliesSize: number;
  handleInReviewAnomaliesPageSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pageInReview: number;
  handleInReviewAnomaliesPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  totalInReviewAnomalies: number;
  anomaliesListError: Error | null;
  isLoadingAnomaliesList: boolean;
}

export const AnomaliesList = ({
  anomaliesList,
  inReviewAnomaliesSize,
  handleInReviewAnomaliesPageSize,
  pageInReview,
  handleInReviewAnomaliesPageChange,
  totalInReviewAnomalies,
  anomaliesListError,
  isLoadingAnomaliesList,
}: IAnomaliesListProps) => {
  if (anomaliesListError)
    return <div style={{ color: 'white' }}>Error getting alerts or anomalies: {anomaliesListError?.message}</div>;

  return (
    <>
      <AdminPanelAlertList
        variant="anomaly"
        title="In Review"
        alerts={anomaliesList}
        isLoading={isLoadingAnomaliesList}
        isEmptyList={!anomaliesList || anomaliesList.length === 0}
      />
      <TablePagination
        pageSize={inReviewAnomaliesSize}
        availablePageSizes={availablePageSizes}
        handlePageSize={handleInReviewAnomaliesPageSize}
        currentPage={pageInReview - 1}
        handlePageChange={handleInReviewAnomaliesPageChange}
        totalDataNumber={totalInReviewAnomalies}
        isFetching={isLoadingAnomaliesList}
      />
    </>
  );
};
