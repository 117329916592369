import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useCreateEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityForm/utils/useCreateEntityForm';
import { ControlledBaseTextField } from '@/components/atoms/BaseTextField/BaseTextField';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import {
  StyledForm,
  StyledInputWrapper,
  StyledWrapperButton,
  StyledInputsContainer,
} from './CreateNewEntityForm.styled';
import { ICreateNewEntityModalProps } from '../CreateNewEntityModal/CreateNewEntityModal';

export const CreateNewEntityForm = ({
  closeModal,
  refetchListOfEntities,
}: Pick<ICreateNewEntityModalProps, 'closeModal' | 'refetchListOfEntities'>) => {
  const { createEntityMethods, handleCreateEntityFormSubmit } = useCreateEntityForm({
    closeModal,
    refetchListOfEntities,
  });
  return (
    <FormProvider {...createEntityMethods}>
      <StyledForm onSubmit={handleCreateEntityFormSubmit}>
        <StyledInputsContainer>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Agio id" inputName="agioId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Arkham id" inputName="arkhamId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Coingecko id" inputName="coingeckoId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare id"
              inputName="cryptoCompareId"
              formMethods={createEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare name"
              inputName="cryptoCompareName"
              formMethods={createEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Glassnode id" inputName="glassnodeId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Type" inputName="type" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Subtype" inputName="subtype" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Status" inputName="status" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Similarweb domain"
              inputName="similarwebDomain"
              formMethods={createEntityMethods}
              helperText="Separate domains using semicolon and space: '; '"
              helperTextBackgroundColor="#242428"
            />
          </StyledInputWrapper>
        </StyledInputsContainer>

        <StyledWrapperButton>
          <AdminButton type="submit">Create</AdminButton>
        </StyledWrapperButton>
      </StyledForm>
    </FormProvider>
  );
};
