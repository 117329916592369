import styled from '@emotion/styled';
import ReactQuill from 'react-quill';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 47px;
  margin-top: 20px;
  margin-bottom: 45px;
`;

export const StyledSeverityContainer = styled.div`
  width: 100%;
  padding-block: 15px;
`;

export const StyledWrapperInput = styled.div`
  width: 253px;
  height: 30px;
  margin-top: 15px;
`;

export const StyledAlertDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 1300px;
`;

export const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    color: white;
  }

  .ql-toolbar {
    background-color: white;
  }
`;
