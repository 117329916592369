import { useWatch } from 'react-hook-form';
import BaseTextField, {
  ControlledBaseTextField,
  EInputValueFormatter,
} from '@/components/atoms/BaseTextField/BaseTextField';
import { formatFloatToPercent } from '@/helpers/helpers';
import { AllocationRowProps } from '@/components/LossCalculator/AllocationRow';

export const PdInput = ({
  formMethods,
  idx,
  isCustom,
}: Pick<AllocationRowProps, 'formMethods' | 'idx' | 'isCustom'>) => {
  const formValues = useWatch({
    name: 'rows',
    control: formMethods.control,
  });

  if (isCustom) {
    return (
      <ControlledBaseTextField
        valueFormatter={EInputValueFormatter.PERCENTAGE}
        formMethods={formMethods}
        inputName={`rows.${idx}.pd`}
      />
    );
  }

  return <BaseTextField value={formatFloatToPercent(formValues[idx].pd) || '%'} disabled />;
};
