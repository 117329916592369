import { ReactNode } from 'react';
import styled from '@emotion/styled';

interface TextProps {
  fontSize?: string;
  children: ReactNode;
}

const StyledText = styled.span<TextProps>`
  color: #ffffff;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: 600;
`;

const Text: React.FC<TextProps> = ({ fontSize, children, ...props }) => {
  return (
    <StyledText fontSize={fontSize} {...props}>
      {children}
    </StyledText>
  );
};

export default Text;
