import { useCallback, useMemo } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import { useManageCorrelationData } from '@/views/LossCalculatorView/utils/useManageCorrelationData';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { CorrelationMultiplierChart } from '@/views/LossCalculatorView/components/CorrelationMultiplier/CorrelationMultiplierChart';
import { StyledCorrelationInfo } from '@/views/LossCalculatorView/components/CorrelationMultiplier/CorrelationMultiplierChart.styles';
import {
  StyledCalculatorBottomLeftColumn,
  StyledCorrelationSliderContainer,
  StyledHeader,
  StyledSectionTitle,
  StyledSlider,
} from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { ILossCalculatorForm } from '@/views/LossCalculatorView/utils/types';

interface ICorrelationMultiplierProps {
  formMethods: UseFormReturn<ILossCalculatorForm>;
}

export const CorrelationMultiplier = ({ formMethods }: ICorrelationMultiplierProps) => {
  const formValues = useWatch({
    name: 'rows',
    control: formMethods.control,
  });

  const { correlation } = formMethods.watch();

  const { dataCorrelationMultiplierChart, entitiesForAxes, handleCorrelationChangeCommitted } =
    useManageCorrelationData({ formValues, correlation });

  const areAllPdsEmpty = useMemo(() => formValues.every((val) => val.pd === 0), [formValues]);

  const valueLabelFormat = useCallback((value: number) => {
    const formattedValue = value / 10;
    return formattedValue.toFixed(2);
  }, []);

  const maxSelectorMultiplier = useMemo(() => {
    const maxPd = formValues.reduce((acc, row) => Math.max(acc, row.pd), 0);
    const maxSelectorMultiplier = (1 / maxPd) * 100;

    return Math.floor(maxSelectorMultiplier * 100) / 100;
  }, [formValues]);

  const renderHeatmap = useMemo(() => {
    return <CorrelationMultiplierChart chartData={dataCorrelationMultiplierChart} axisData={entitiesForAxes} />;
  }, [dataCorrelationMultiplierChart]);

  return (
    <StyledCalculatorBottomLeftColumn>
      <StyledHeader>
        <StyledSectionTitle variant="h5">II. SELECT CORRELATION MULTIPLIER</StyledSectionTitle>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title="Selecting the correlation multiplier involves determining the degree of correlation between the probabilities of defaults among different entities within a portfolio. This parameter is crucial as it influences the simulation's accuracy in depicting how defaults in one part of the portfolio may affect others, thus aiding in more robust risk management strategies."
        >
          <img src={HelpIcon} alt="Help icon" />
        </Tooltip>
      </StyledHeader>
      <StyledCorrelationSliderContainer>
        <Stack spacing={2} direction="row" alignItems="center" width="100%" gap="10px">
          <div>Select Multiplier:</div>
          <Controller
            control={formMethods.control}
            name="correlation"
            render={({ field }) => (
              <StyledSlider
                aria-label="Correlation"
                defaultValue={10}
                onChangeCommitted={handleCorrelationChangeCommitted}
                min={10}
                max={maxSelectorMultiplier * 10}
                color="secondary"
                marks={[
                  { value: 10, label: '1.00' },
                  { value: maxSelectorMultiplier * 10, label: maxSelectorMultiplier.toFixed(2) },
                ]}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                disabled={areAllPdsEmpty}
                {...field}
              />
            )}
          />

          <StyledCorrelationInfo>
            {Number.isNaN(maxSelectorMultiplier) ? 'X.X' : String((correlation / 10).toFixed(2))}
          </StyledCorrelationInfo>
        </Stack>
      </StyledCorrelationSliderContainer>
      {renderHeatmap}
    </StyledCalculatorBottomLeftColumn>
  );
};
