import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import NavBar from '@/components/molecules/NavBar/NavBar';
import { ProcessPanel } from '@/components/ProcessPanel/ProcessPanel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
  gap: 25px;
  min-height: 100vh;
`;

const Title = styled.span`
  color: white;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 50px */
  letter-spacing: 2px;
  margin-bottom: 50px;
`;

const AdminLayout: React.FC = () => {
  return (
    <Container>
      <Title>Admin Panel</Title>
      <ProcessPanel />
      <NavBar />
      <Outlet />
    </Container>
  );
};

export default AdminLayout;
