import styled from '@emotion/styled';
import { Container } from '@mui/material';

export const StyledContainer = styled(Container)`
  padding: 35px;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
`;

export const StyledTitle = styled.span`
  margin-bottom: 20px;
  font-size: 30px;
  color: #fff;
`;
