import { AxiosError } from 'axios';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';

export const handleCatch = (reason: any, customErrorText: string) => {
  if (reason instanceof AxiosError && reason.response) {
    const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
    throw new Error(errorMessage);
  } else {
    return Promise.reject(new UnknownErrorAndWeCouldNot(customErrorText));
  }
};
