import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
  z-index: 1000;
  background: #000000cc;
  backdrop-filter: blur(2px);
`;

export const StyledModalAsBanner = styled.div`
  box-sizing: border-box;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  max-width: 700px;
  width: 100%;
  height: fit-content;
  background: linear-gradient(180deg, #543e9d 0%, #77708c 100%);
  box-shadow: 0 4px 0 #00000040;
`;

export const StyledAdminModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px 40px 40px;
  border-radius: 8px;
  max-width: 700px;
  width: calc(100% - 80px);
  max-height: 90%;
  background: #242428;
  overflow: auto;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
`;
