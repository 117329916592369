import { useFormContext } from 'react-hook-form';
import { PortfolioRiskMeasuresFormContent } from '@/views/LossCalculatorView/components/PortfolioRiskMeasures/PortfolioRiskMeasuresFormContent';
import Loader from '@/components/atoms/Loader/Loader';
import Button from '@/components/atoms/Button/Button';
import { StyledLoaderWrapper, StyledSectionTitle } from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { IResultOfLossCalculation } from '@/views/LossCalculatorView/utils/types';

export interface IPortfolioRiskMeasuresProps {
  totalExposure: number;
  resultOfLossCalculation: IResultOfLossCalculation | null;
  isCalculationLoading: boolean;
}

export const PortfolioRiskMeasures = ({
  totalExposure,
  resultOfLossCalculation,
  isCalculationLoading,
}: IPortfolioRiskMeasuresProps) => {
  const { setValue } = useFormContext();

  const handleClick = () => {
    setValue('disabledExtremes', true);
  };
  return (
    <>
      <StyledSectionTitle variant="h5">III. COMPUTE PORTFOLIO RISK MEASURES</StyledSectionTitle>
      {isCalculationLoading ? (
        <StyledLoaderWrapper height={335}>
          <Loader />
        </StyledLoaderWrapper>
      ) : (
        <PortfolioRiskMeasuresFormContent
          totalExposure={totalExposure}
          resultOfLossCalculation={resultOfLossCalculation}
        />
      )}
      <Button type="submit" variant="contained" fitContentWidth onClick={handleClick}>
        Run
      </Button>
    </>
  );
};
