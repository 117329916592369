import React, { useCallback, useState } from 'react';
import BasePanel from '@/components/atoms/BasePanel/BasePanel';
import Button from '@/components/atoms/Button/Button';
import {
  StyledButtonContainer,
  StyledNotification,
  StyledContentWrapper,
} from '@/components/ProcessPanel/ProcessPanel.styled';
import { useProcessRatingsFile } from '@/api/adminRatings/adminRatings.hooks';
import { useProcessRisksDecompositionFile } from '@/api/adminRisks/adminRisks.hooks';
import { ICustomNotification } from '@/types/notifications';
import { initialCustomNotification } from '@/constants/InitialState/notifications';

export const ProcessPanel = () => {
  const [notification, setNotification] = useState<ICustomNotification>(initialCustomNotification);
  const { processRatingsFile, isPending: isPendingRatings } = useProcessRatingsFile();
  const { processRisksDecompositionFile, isPending: isPendingRisks } = useProcessRisksDecompositionFile();

  const handleProcessRatingsFile = useCallback(async () => {
    setNotification(initialCustomNotification);

    processRatingsFile(undefined, {
      onSuccess: ({ message }) => {
        setNotification({ text: message, type: 'success' });
      },
      onError: ({ message }) => {
        setNotification({ text: message, type: 'error' });
      },
    });
  }, []);

  const handleProcessRisksDecompositionFile = useCallback(async () => {
    setNotification(initialCustomNotification);

    processRisksDecompositionFile(undefined, {
      onSuccess: ({ message }) => {
        setNotification({ text: message, type: 'success' });
      },
      onError: ({ message }) => {
        setNotification({ text: message, type: 'error' });
      },
    });
  }, []);

  return (
    <BasePanel title="Process Panel">
      <StyledContentWrapper>
        <StyledButtonContainer>
          <Button onClick={handleProcessRatingsFile} variant="admin" disabled={isPendingRatings}>
            Process Ratings file
          </Button>

          <Button onClick={handleProcessRisksDecompositionFile} variant="admin" disabled={isPendingRisks}>
            Process Risks decomposition file
          </Button>
        </StyledButtonContainer>

        {notification && (
          <StyledNotification isSuccess={notification.type === 'success'}>{notification.text}</StyledNotification>
        )}
      </StyledContentWrapper>
    </BasePanel>
  );
};
