import { IEditEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityDetails/components/EditEntityDetailsForm/utils/types';

export const defaultEditEntityFormValues: IEditEntityForm = {
  agioId: '',
  arkhamId: '',
  coingeckoId: '',
  cryptoCompareId: '',
  cryptoCompareName: '',
  glassnodeId: '',
  similarwebDomain: '',
  type: '',
  subtype: '',
  status: '',
};

export const SIMILARWEB_DOMAIN_SEPARATOR = '; ';
