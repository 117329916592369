import { RefObject } from 'react';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import enableExporting from 'highcharts/modules/exporting';
import enableExportData from 'highcharts/modules/export-data';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { getListOfNumbers } from '@/helpers/helpers';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledPaper } from '@/views/LossCalculatorView/components/DistributionOfDefaultsAndLosses/DistributionOfDefaultsAndLosses.styled';
import { StyledHeader, StyledSectionTitle } from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { IDistributionOfDefaultsAndLossesProps } from '@/views/LossCalculatorView/components/DistributionOfDefaultsAndLosses/DistributionOfDefaultsAndLosses';

enableExporting(Highcharts);
enableExportData(Highcharts);

const NUMBER_OF_DEFAULTS = 'Number of Defaults';

interface INumberOfDefaultsChartProps extends Pick<IDistributionOfDefaultsAndLossesProps, 'defaultsFrequency'> {
  chartRef: RefObject<HighchartsReactRefObject>;
}

export const NumberOfDefaultsChart = ({ defaultsFrequency, chartRef }: INumberOfDefaultsChartProps) => {
  const categories = getListOfNumbers(defaultsFrequency.length);

  const chartData = () => {
    return defaultsFrequency.map((dataEl) => {
      const numberOfDefaults = dataEl[0];
      const frequency = dataEl[1];

      return [+numberOfDefaults, frequency];
    });
  };

  const chartOptions = {
    chart: {
      type: 'column',
      backgroundColor: null,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories,
      title: {
        text: NUMBER_OF_DEFAULTS,
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          color: '#FFFFFF',
        },
      },
    },
    yAxis: {
      min: 0,
      max: !!defaultsFrequency.length && 100,
      title: {
        text: 'Frequency',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        format: '{text}%',
        style: {
          color: '#FFFFFF',
        },
      },
      gridLineColor: 'transparent',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      format: '<span style="color:{color}">\u25CF</span> ' + '{series.name}: <b>{y}</b>%<br/>',
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
      },
    },
    series: [
      {
        name: 'Frequency',
        data: chartData(),
        color: {
          linearGradient: { x0: 0, y0: 0, x1: 1, y1: 1 },
          stops: [
            [0, '#82789D'],
            [1, '#322759'],
          ],
        },
        borderColor: '#543E9D',
      },
    ],
    exporting: {
      ...DISABLE_CHART_EXPORTING_MENU.exporting,
      csv: {
        itemDelimiter: ';',
      },
      filename: NUMBER_OF_DEFAULTS,
      chartOptions: {
        chart: {
          backgroundColor: '#2c2c2e',
        },
        title: {
          text: NUMBER_OF_DEFAULTS,
          style: { color: '#FFFFFF' },
        },
      },
    },
  };

  return (
    <>
      <StyledPaper>
        <StyledHeader>
          <StyledSectionTitle variant="h5">{NUMBER_OF_DEFAULTS.toUpperCase()}</StyledSectionTitle>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              <div>
                A bar plot of the frequency at which a certain number of default occur in the next year. The frequency
                is obtained from 1M+ Monte-Carlo simulations. <br /> <br /> The empirical probability mass function of
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                the number of defaults can be downloaded as a csv file using the "Download Full Distribution" button.
              </div>
            }
          >
            <img src={HelpIcon} alt="Help icon" />
          </Tooltip>
        </StyledHeader>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
      </StyledPaper>
    </>
  );
};
