import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';

export const StyledRatingsDataGrid = styled(DataGrid)`
  border: transparent;
  color: #ffffff;

  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  .MuiDataGrid-columnHeaderTitle {
    opacity: 0.5;
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer {
    border-bottom: 1px solid #2e2e31;
    font-size: 14px;
  }

  .MuiDataGrid-columnHeader:focus {
    border: transparent;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    cursor: default;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row.Mui-selected,
  .MuiDataGrid-row.Mui-selected.Mui-hovered,
  .MuiDataGrid-row:hover {
    background: #222;
    cursor: default;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

export const StyledCompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: #4859f4;
  }
`;
