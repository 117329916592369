import { ApiEndpoints } from '@/types/routes';
import { IAuthApi, ILogInRequest, ILogInResponse } from '@/api/auth/auth.types';
import { IHttpClient } from '@/api/HttpClient';

export class AuthApi implements IAuthApi {
  private getBaseUrl(): string {
    return ApiEndpoints.AUTH_USER;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  logInUser(logInCredentials: ILogInRequest): Promise<ILogInResponse> {
    return this.httpClient.post<ILogInRequest, ILogInResponse>(this.getBaseUrl(), logInCredentials);
  }
}
