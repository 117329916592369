import Zoom from '@mui/material/Zoom';
import { formatToUSD } from '@/helpers/helpers';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import {
  StyledFlexCenter,
  StyledRiskMeasuresGridContainer,
} from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { LossMeasures } from '@/views/LossCalculatorView/components/PortfolioRiskMeasures/LossMeasures';
import { IPortfolioRiskMeasuresProps } from './PortfolioRiskMeasures';

export const PortfolioRiskMeasuresFormContent = ({
  totalExposure,
  resultOfLossCalculation,
}: Pick<IPortfolioRiskMeasuresProps, 'totalExposure' | 'resultOfLossCalculation'>) => {
  return (
    <div>
      <StyledRiskMeasuresGridContainer>
        <StyledFlexCenter>Total Exposure:</StyledFlexCenter>
        <div>
          <BaseTextField disabled value={formatToUSD(totalExposure)} />
        </div>
        <div></div>
        <LossMeasures
          lossName="Expected Loss:"
          tooltipText={
            <div>
              <strong>Expected Loss (EL)</strong> is the average financial loss, expressed as a percentage of the total
              exposure, that results from default related events in the next year. The Expected Loss corresponds to the
              mean of the Loss Distribution Function.
            </div>
          }
          lossUsd={resultOfLossCalculation?.expectedLossUsd}
          lossPct={resultOfLossCalculation?.expectedLossPct}
        />
        <LossMeasures
          lossName="Unexpected Loss:"
          tooltipText={
            <div>
              <strong>Unexpected loss (UL)</strong> measures the deviation from the Expected Loss that results from
              default related events in the next year. The Unexpected Loss corresponds to the standard deviation of the
              Loss Distribution Function.
            </div>
          }
          lossUsd={resultOfLossCalculation?.unexpectedLossUsd}
          lossPct={resultOfLossCalculation?.unexpectedLossPct}
        />
        <StyledFlexCenter>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              <div>
                <strong>Probability of zero defaults</strong> is the frequency at which no defaults are observed in the
                next year in the Monte-Carlo simulations.
              </div>
            }
          >
            <p>Probability of zero defaults:</p>
          </Tooltip>
        </StyledFlexCenter>
        <div>
          <BaseTextField disabled value={resultOfLossCalculation?.zeroDefaults || ''} />
        </div>
        <div></div>
      </StyledRiskMeasuresGridContainer>
    </div>
  );
};
