import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const StyledSpan = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const StyledPaper = styled(Paper)<{ width?: string; height?: string }>`
  background: #101014;
  border: 1px solid #2e2e31;
  padding: 30px;
  color: #fff;
  min-width: 600px;
  flex-basis: 100%;
  height: auto;
`;

export const ChartsContainer = styled.div<{ layout: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => (props.layout === 'row' ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${(props) => (props.layout === 'row' ? 'center' : 'flex-start')};
`;
