import AdminModal from '@/components/Modal/AdminModal';
import { StyledTitle } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityModal/CreateNewEntityModal.styled';
import { CreateNewEntityForm } from '../CreateNewEntityForm/CreateNewEntityForm';

export interface ICreateNewEntityModalProps {
  isOpen: boolean;
  closeModal: () => void;
  refetchListOfEntities: () => void;
}

export const CreateNewEntityModal = ({ isOpen, closeModal, refetchListOfEntities }: ICreateNewEntityModalProps) => {
  return (
    <AdminModal isOpen={isOpen} closeModal={closeModal}>
      <StyledTitle color="white.100">Create new entity</StyledTitle>
      <CreateNewEntityForm closeModal={closeModal} refetchListOfEntities={refetchListOfEntities} />
    </AdminModal>
  );
};
