import { StyledTypography, StyledWrapper } from '@/views/NoPermissionsView/NoPermissionsView.styled';

interface INoPermissionsViewProps {
  isRatingsView?: boolean;
}

export const NoPermissionsView = ({ isRatingsView = false }: INoPermissionsViewProps) => {
  return (
    <StyledWrapper isRatings={isRatingsView}>
      <StyledTypography>
        You do not have a subscription to access this feature. Please contact us at contact@agioratings.io for
        assistance.
      </StyledTypography>
    </StyledWrapper>
  );
};
