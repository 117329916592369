export const variables = [
  {
    name: 'Company Size',
    key: 'companySize',
    hint: 'The number of employees',
  },
  {
    name: 'User Traffic',
    key: 'userTraffic',
    hint: 'The number of platform hits',
  },
  {
    name: 'Trading Leverage',
    key: 'tradingLeverage',
    hint: "The on-chain flow given the exchange's size",
  },
  {
    name: 'Volume Leverage',
    key: 'volumeLeverage',
    hint: "The reported trading volumes given the exchange's size",
  },
  {
    name: 'On-Chain Balance Trend',
    key: 'reservesTrend',
    hint: 'The growth in on-chain balances',
  },
  {
    name: 'On-Chain Flow Trend',
    key: 'tradingTrend',
    hint: 'The growth in on-chain flow',
  },
  {
    name: 'On-Chain Balance Volatility',
    key: 'reservesVolatility',
    hint: 'The volatility of on-chain balances',
  },
  {
    name: 'On-Chain Flow Volatility',
    key: 'tradingVolatility',
    hint: 'The volatility of on-chain flow',
  },
  {
    name: 'Exchange Maturity',
    key: 'exchangeMaturity',
    hint: "The exchange's age",
  },
  {
    name: 'License Score',
    key: 'licenseScore',
    hint: 'The number of higher value regulatory licenses',
  },
];
