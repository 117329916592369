import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { RATINGS_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IGetRatingsListResponse } from '@/api/ratings/ratings.types';

export const useGetRatingsList = () => {
  const { ratingsApi } = useApi();

  const {
    data: ratingsList,
    error,
    isLoading,
  } = useQuery<IGetRatingsListResponse, Error>({
    queryKey: [RATINGS_KEY, 'GET_RATINGS_LIST'],
    queryFn: () => {
      return ratingsApi.getRatingsList().catch((error) => {
        if (error instanceof AxiosError && error.response) {
          const errorMessage = error.response.data.detail || error.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`load the ratings list`));
        }
      });
    },
  });

  return {
    ratingsList,
    error,
    isLoading,
  };
};

export const useGetRatingsFile = () => {
  const { ratingsApi } = useApi();

  const { mutate: downloadRatingsFile } = useMutation<string, Error, void>({
    mutationKey: [RATINGS_KEY, 'GET_RATINGS_FILE'],
    mutationFn: () => {
      return ratingsApi.getRatingsFile().catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`download ratings file`));
        }
      });
    },
  });

  return {
    downloadRatingsFile,
  };
};
