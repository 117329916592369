import { IPersonalPreferencesForm } from '@organims/PersonalPreferencesForm/utils/types';

export const defaultPersonalPreferencesFormValues: IPersonalPreferencesForm = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    accountStatus: '',
  },
  notifications: {
    newsletter: false,
    anomalies: false,
    updates: false,
    statistical_ratings: false,
  },
};
