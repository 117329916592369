import { Checkbox } from '@mui/material';

type CheckboxFieldProps = {
  label: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
};

const CheckboxField: React.FC<CheckboxFieldProps> = ({ label, value, onChange, ...props }) => {
  return <Checkbox aria-label={label} value={value} onChange={onChange} {...props} />;
};

export default CheckboxField;
