import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IAdminRisksApi, IUpdateRisksDecompositionFileResponse } from '@/api/adminRisks/adminRisks.types';

export class AdminRisksApi implements IAdminRisksApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_RISKS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  updateRisksDecompositionFile(fileId = '1') {
    return this.httpClient.get<IUpdateRisksDecompositionFileResponse>(
      `${this.getBaseUrl()}update_risks/decomposition/${fileId}/`,
    );
  }
}
