import styled from '@emotion/styled';
import ReactQuill from 'react-quill';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import Text from '@/components/atoms/Text/Text';
import 'react-quill/dist/quill.snow.css';

interface WrapperInputProps {
  width?: string;
}

export const StyledWrapperInput = styled.div<WrapperInputProps>`
  width: ${(props) => props.width || '253px'};
  margin: 20px 0px;
`;

export const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    color: white;
  }

  .ql-toolbar {
    background-color: white;
  }
`;
export const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

export const StyledInput = styled(BaseTextField)`
  margin-top: 20px;
`;

export const StyledUpdatesContainer = styled.div`
  display: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;
