import { useRef } from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import { Box } from '@mui/material';
import { NumberOfDefaultsChart } from '@/views/LossCalculatorView/components/DistributionOfDefaultsAndLosses/NumberOfDefaultsChart';
import { NonZeroLossesChart } from '@/views/LossCalculatorView/components/DistributionOfDefaultsAndLosses/NonZeroLossesChart';
import { StyledSectionTitle } from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { ExportButton } from '@/components/atoms/Button/ExportButton';
import {
  StyledChartsWrapper,
  StyledSectionWrapper,
} from '@/views/LossCalculatorView/components/DistributionOfDefaultsAndLosses/DistributionOfDefaultsAndLosses.styled';
import { TTransformedDataFrequency } from '../../utils/types';

export interface IDistributionOfDefaultsAndLossesProps {
  defaultsFrequency: TTransformedDataFrequency[];
  lossFrequency: TTransformedDataFrequency[];
}

export const DistributionOfDefaultsAndLosses = ({
  defaultsFrequency,
  lossFrequency,
}: IDistributionOfDefaultsAndLossesProps) => {
  const numberOfDefaultsRef = useRef<HighchartsReactRefObject>(null);
  const nonZeroLossesRef = useRef<HighchartsReactRefObject>(null);

  const exportCSV = () => {
    if (numberOfDefaultsRef.current !== null && numberOfDefaultsRef.current.chart) {
      numberOfDefaultsRef.current.chart.downloadCSV();
    }

    if (nonZeroLossesRef.current !== null && nonZeroLossesRef.current.chart) {
      nonZeroLossesRef.current.chart.downloadCSV();
    }
  };

  const exportPDF = () => {
    if (numberOfDefaultsRef.current !== null && numberOfDefaultsRef.current.chart) {
      numberOfDefaultsRef.current.chart.exportChart(
        {
          type: 'application/pdf',
          filename: 'NUMBER OF DEFAULTS',
        },
        {},
      );
    }

    if (nonZeroLossesRef.current !== null && nonZeroLossesRef.current.chart) {
      nonZeroLossesRef.current.chart.exportChart(
        {
          type: 'application/pdf',
          filename: 'NON-ZERO LOSSES',
        },
        {},
      );
    }
  };
  return (
    <StyledSectionWrapper>
      <StyledSectionTitle>V. DISTRIBUTION OF DEFAULTS AND LOSSES</StyledSectionTitle>

      <StyledChartsWrapper>
        <NumberOfDefaultsChart defaultsFrequency={defaultsFrequency} chartRef={numberOfDefaultsRef} />
        <NonZeroLossesChart lossFrequency={lossFrequency} chartRef={nonZeroLossesRef} />
      </StyledChartsWrapper>

      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2} paddingTop={2}>
        <ExportButton
          variant="text"
          onClick={exportCSV}
          tooltipText="Export Full Distribution CSV"
          text="Download Full Distribution"
        />

        <ExportButton variant="icon" onClick={exportPDF} tooltipText="Export Full Distribution PDF" />
      </Box>
    </StyledSectionWrapper>
  );
};
