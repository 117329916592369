import { variables } from '@/components/RisksDecompositions/constants';

export type RiskDecompositionDataPoint = {
  [key: string]: any;
};

export function prepareChartData(data: RiskDecompositionDataPoint, comparedData?: null | RiskDecompositionDataPoint) {
  const increase = [];
  const decrease = [];

  for (const [index, v] of variables.entries()) {
    if (v.key) {
      if (comparedData) {
        if (data[v.key] > comparedData[v.key]) {
          increase.push({
            name: v.name,
            x: index + 0.15,
            low: data[v.key],
            high: comparedData[v.key],
          });
        } else {
          decrease.push({
            name: v.name,
            x: index - 0.15,
            low: comparedData[v.key],
            high: data[v.key],
          });
        }
      } else {
        increase.push({
          name: v.name,
          x: index + 0.15,
          low: data[v.key],
          high: data[v.key],
        });
      }
    }
  }
  return {
    increase,
    decrease,
  };
}

export function compareRisksDates(selectedDate: string, riskDate: string) {
  return selectedDate === riskDate.split('T')[0];
}

export function mapEntityNameToRisksDecomposition(entityName?: string) {
  switch (entityName) {
    case 'gdax':
      return 'coinbase';
    case 'gate':
      return 'gate_io';
    case 'bybit_spot':
      return 'bybit';
    default:
      return entityName;
  }
}
