import styled from '@emotion/styled';

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #2e2e31;
  justify-content: space-between;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 358px;
`;
