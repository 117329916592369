import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
`;

const Loader = () => (
  <StyledDiv>
    <CircularProgress style={{ color: '#9388E8' }} />
  </StyledDiv>
);

export default Loader;
