export interface IRow {
  entity: string;
  pd: number;
  recovery: number;
  exposure: number;
  percentageExposure: string;
  isCustom: boolean;
}

export interface ILossCalculatorForm {
  disabledExtremes: boolean;
  rows: IRow[];
  correlation: number;
  extremeLossProbability?: number;
  extremeLossAlpha?: number;
}

export type TTransformedDataFrequency = [string, number];

export interface IResultOfLossCalculation {
  expectedLossUsd: string;
  unexpectedLossUsd: string;
  expectedLossPct: string;
  unexpectedLossPct: string;
  zeroDefaults: string;
  probExceedingLoss?: string;
  creditVar?: string;
  defaultsFrequency?: TTransformedDataFrequency[];
  lossFrequency?: TTransformedDataFrequency[];
}

export enum EFileKeys {
  ENTITY = 'Exchange ID',
  PD = '1-YR PD value',
  RECOVERY = 'Recovery (%)',
  EXPOSURE = 'Exposure (USD)',
}
