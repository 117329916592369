import { GridColDef } from '@mui/x-data-grid';

export const FULLY_CENTERED_COLUMNS: Partial<GridColDef> = {
  headerAlign: 'center',
  align: 'center',
};

export const FULLY_RIGHT_COLUMNS: Partial<GridColDef> = {
  headerAlign: 'right',
  align: 'right',
};
