import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IGetRisksDecompositionResponse, IRisksApi } from '@/api/risks/risks.types';

export class RisksApi implements IRisksApi {
  private getBaseUrl(): string {
    return ApiEndpoints.RISKS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getRisksDecomposition() {
    return this.httpClient.get<IGetRisksDecompositionResponse>(`${this.getBaseUrl()}decomposition/`);
  }
}
