import { AlertMetadataType } from '@/types/alert';

export const mapMetadataType = (incomingType: string | null | undefined) => {
  switch (incomingType) {
    case AlertMetadataType.BALANCE:
      return 'Total balance';
    case AlertMetadataType.INFLOW:
      return 'Inflow';
    case AlertMetadataType.OUTFLOW:
      return 'Outflow';
    case AlertMetadataType.TOTAL_FLOW:
      return 'Total flow';
    case AlertMetadataType.NETFLOW:
      return 'Netflow';
    default:
      return '';
  }
};
