import Zoom from '@mui/material/Zoom';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import BaseTextField, {
  ControlledBaseTextField,
  EInputValueFormatter,
} from '@/components/atoms/BaseTextField/BaseTextField';
import {
  StyledExtremeLossGridContainer,
  StyledFlexCenter,
} from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { IExtremeLossThresholdsProps } from '@/views/LossCalculatorView/components/ExtremeLossThresholds/ExtremeLossThresholds';

export const ExtremeLossThresholdsFormContent = ({
  formMethods,
  resultOfLossCalculation,
}: Pick<IExtremeLossThresholdsProps, 'formMethods' | 'resultOfLossCalculation'>) => {
  return (
    <div>
      <StyledExtremeLossGridContainer>
        <StyledFlexCenter>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              <div>
                <strong>Probability of extreme losses</strong> is the probability that the losses incurred in the next
                year due to default events exceed a certain percentage of the total exposure of the portfolio. This
                threshold is selected by the user.
              </div>
            }
          >
            <p>Probability of losing more than</p>
          </Tooltip>
        </StyledFlexCenter>
        <div>
          <ControlledBaseTextField
            valueFormatter={EInputValueFormatter.PERCENTAGE}
            formMethods={formMethods}
            inputName="extremeLossProbability"
            placeholder="Enter %"
          />
        </div>
        <StyledFlexCenter>of total exposure:</StyledFlexCenter>
        <div>
          <BaseTextField disabled value={resultOfLossCalculation?.probExceedingLoss || ''} />
        </div>

        <StyledFlexCenter>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              <div>
                <strong>Credit Value-at-Risk (Credit VaR)</strong> is defined as the loss incurred in the next year if
                the worst α%(alpha % ) event happens, where α is the confidence level selected by the user. For example
                in TradFi, the confidence level is often set at 0.03% since this is the standard for a AA credit rating.
              </div>
            }
          >
            <p>Credit Value-at-Risk at the ɑ =</p>
          </Tooltip>
        </StyledFlexCenter>
        <div>
          <ControlledBaseTextField
            valueFormatter={EInputValueFormatter.PERCENTAGE}
            formMethods={formMethods}
            inputName="extremeLossAlpha"
            placeholder="Enter %"
          />
        </div>
        <StyledFlexCenter>confidence level:</StyledFlexCenter>
        <div>
          <BaseTextField disabled value={resultOfLossCalculation?.creditVar || ''} />
        </div>
      </StyledExtremeLossGridContainer>
    </div>
  );
};
