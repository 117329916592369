import styled from '@emotion/styled';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import Text from '@/components/atoms/Text/Text';

const Container = styled.div`
  padding: 30px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
`;

const WrapperButton = styled.div`
  width: 253px;
  height: 30px;
  margin: 40px 0;
`;

interface ActionButtonsProps {
  handleSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancel: () => void;
  handleResetPassword?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isPublished?: boolean;
}

const ActionsButtons = ({
  handleSave,
  handleDelete,
  handleCancel,
  isPublished,
  handleResetPassword,
}: ActionButtonsProps) => (
  <Container>
    <Text fontSize="20px">Actions</Text>
    <ButtonsContainer>
      <WrapperButton>
        <AdminButton onClick={handleSave}>{isPublished ? 'Save Changes' : 'Publish'}</AdminButton>
      </WrapperButton>
      <WrapperButton>
        <AdminButton onClick={handleDelete}>Delete</AdminButton>
      </WrapperButton>
      <WrapperButton>
        <AdminButton onClick={handleCancel}>Cancel</AdminButton>
      </WrapperButton>
      {handleResetPassword && (
        <WrapperButton>
          <AdminButton onClick={handleResetPassword}>Reset password</AdminButton>
        </WrapperButton>
      )}
    </ButtonsContainer>
  </Container>
);

export default ActionsButtons;
