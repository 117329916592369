import { DirectionColouredText } from '@/components/DirectionColouredText/DirectionColouredText';
import { formatFloatToPercent } from '@/helpers/helpers';

interface IPdChangeComponentProps {
  specificPdChange: number;
}

export const PdChangeComponent = ({ specificPdChange }: IPdChangeComponentProps) => {
  const isNeutral = specificPdChange === 0;
  const isPositiveDirection = specificPdChange <= 0;

  return (
    <DirectionColouredText isPositiveDirection={isPositiveDirection} isNeutral={isNeutral}>
      {!isPositiveDirection && '+'}
      {formatFloatToPercent(specificPdChange)}
    </DirectionColouredText>
  );
};
