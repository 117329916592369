import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  ICalculatorsApi,
  IGetLossCalculationsRequest,
  IGetLossCalculationsResponse,
} from '@/api/calculators/calculators.types';

export class CalculatorsApi implements ICalculatorsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.CALCULATORS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getLossCalculations({ correlation, entities, lossThresholdPct, alphaPct }: IGetLossCalculationsRequest) {
    const params = {
      correlation,
      entities,
      lossThresholdPct,
      alphaPct,
    };

    return this.httpClient.post<Partial<IGetLossCalculationsRequest>, IGetLossCalculationsResponse>(
      this.getBaseUrl(),
      params,
    );
  }
}
