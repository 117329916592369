import { PropsWithChildren } from 'react';
import { StyledDirectionColouredText } from './DirectionColouredText.styled';

export interface IDirectionColouredTextProps {
  isPositiveDirection: boolean;
  isNeutral: boolean;
}

export const DirectionColouredText = ({ children, ...props }: PropsWithChildren<IDirectionColouredTextProps>) => {
  return <StyledDirectionColouredText {...props}>{children}</StyledDirectionColouredText>;
};
