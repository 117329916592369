import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { ADMIN_RISKS_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IUpdateRisksDecompositionFileResponse } from '@/api/adminRisks/adminRisks.types';

export const useProcessRisksDecompositionFile = () => {
  const { adminRisksApi } = useApi();

  const {
    error,
    mutate: processRisksDecompositionFile,
    isPending,
  } = useMutation<IUpdateRisksDecompositionFileResponse, Error, string | undefined>({
    mutationKey: [ADMIN_RISKS_KEY, 'UPDATE_RISKS_DECOMPOSITION_FILE'],
    mutationFn: (fileId) => {
      return adminRisksApi.updateRisksDecompositionFile(fileId).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`upload the risks decomposition file`));
        }
      });
    },
  });

  return {
    error,
    processRisksDecompositionFile,
    isPending,
  };
};
