import { useCallback, useEffect, useState } from 'react';
import ModalAsBanner from '@/components/Modal/ModalAsBanner';
import { ELocalStorage } from '@/types/storage';
import { IModalAsSingleViewedBannerContentProps } from '@/types/modal';

interface IUseModalProps {
  ModalContent: (props: IModalAsSingleViewedBannerContentProps) => JSX.Element;
  localStorageKey: ELocalStorage;
}

export const useModalAsSingleViewedBanner = ({ ModalContent, localStorageKey }: IUseModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    const isUpcomingBannerViewed = localStorage.getItem(localStorageKey);

    isUpcomingBannerViewed === null && setIsModalOpen(true);
  }, []);

  return {
    ModalToRender: () => (
      <ModalAsBanner isOpen={isModalOpen}>
        <ModalContent closeModal={closeModal} localStorageKey={localStorageKey} />
      </ModalAsBanner>
    ),
  };
};
