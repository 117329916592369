import React, { useEffect, useState } from 'react';
import { StyledImg } from '@/components/ExchangeLogo/ExchangeLogo.styled';

interface ExchangeLogoProps {
  companyName: string;
  height?: string;
}

const ExchangeLogo = ({ companyName, height }: ExchangeLogoProps) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  const imagePath = `/images/logos/${companyName.replace('.', '_').replace('-', '_')}.png`;

  useEffect(() => {
    setImageLoaded(true);
  }, [companyName]);

  const handleError = () => {
    console.warn(`Missing logo: ${companyName}`);
    setImageLoaded(false);
  };

  return <>{imageLoaded && <StyledImg src={imagePath} alt={companyName} onError={handleError} height={height} />}</>;
};

export default ExchangeLogo;
