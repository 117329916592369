import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import CheckMarkIcon from '@/assets/icons/check-circle.svg?react';

export const StyledWrapper = styled.div`
  box-sizing: border-box;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 15px 15px;

  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .embla__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
  }

  .embla__button {
    -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 2px rgb(234, 234, 234);
    width: 30px;
    height: 30px;
    z-index: 1;
    border-radius: 50%;
    color: rgb(54, 49, 61);
    align-items: center;
    justify-content: center;
  }

  .embla__button:disabled {
    opacity: 0.3;
    cursor: auto;
  }

  .embla__button__svg {
    width: 13px;
    height: 13px;
  }

  .embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }

  .embla__dot {
    -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .embla__dot:after {
    box-shadow: inset 0 0 0 2px rgb(234, 234, 234);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
  }

  .embla__dot--selected:after {
    box-shadow: inset 0 0 0 2px #e5b302;
  }
`;

export const StyledLogo = styled.img`
  width: 218px;
  height: 70px;
  filter: blur(1px);
  opacity: 0.1;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 0 10px;
  user-drag: none;
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  .embla {
    overflow: hidden;
  }

  .embla__container {
    display: flex;
  }

  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
  }
`;

export const StyledSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-inline: 50px;
`;

export const StyledThirdSlideWrapper = styled(StyledSlideWrapper)`
  gap: 10px;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 500;
  font-size: 35px;
  line-height: 70px;
  text-align: center;
`;

export const StyledNormalText = styled(Typography)<{ $left?: boolean }>`
  text-align: ${({ $left }) => ($left ? 'left' : 'center')};
  font-weight: 300;
  width: 100%;
  letter-spacing: 0.2px;

  span {
    font-weight: 500;
    color: #e5b302;
  }
`;

export const StyledBolderText = styled(StyledNormalText)`
  font-weight: 500;
  font-size: 19px;
`;

export const StyledProgressContainer = styled.div`
  position: relative;
`;

export const StyledProgress = styled.div`
  border-radius: 50px;
  width: 330px;
  height: 16px;
  background-color: #e5b302;
`;

export const StyledCheckMarkIcon = styled(CheckMarkIcon)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(100%, -30%);
`;

export const StyledDotsWrapper = styled.div`
  display: flex;
  gap: 48px;

  div {
    width: 16px;
    height: 16px;
    background-color: #e5b302;
    border-radius: 50%;
  }

  div:first-of-type {
    opacity: 25%;
  }

  div:nth-of-type(2) {
    opacity: 50%;
  }

  div:last-of-type {
    opacity: 75%;
  }
`;

export const StyledList = styled.ul`
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
`;

export const StyledListItem = styled.li`
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  letter-spacing: 0.4px;
`;

export const StyledCloseButton = styled.button`
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  background: #ffffff;
  cursor: pointer;
  transition: opacity 0.3s, color 0.3s;
  font-family: 'General Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #463679;

  :hover {
    opacity: 0.8;
    color: #e5b302;
  }
`;

export const StyledLinkButton = styled.a`
  text-decoration-line: none;
  font: inherit;
  font-weight: 500;
  color: #ffffff;
  transition: color 0.3s;

  :hover {
    color: #e5b302;
  }
`;
