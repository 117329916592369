import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useLossCalculatorForm } from '@/views/LossCalculatorView/utils/useLossCalculatorForm';
import { PortfolioAllocation } from '@/views/LossCalculatorView/components/PortfolioAllocation/PortfolioAllocation';
import { CorrelationMultiplier } from '@/views/LossCalculatorView/components/CorrelationMultiplier/CorrelationMultiplier';
import { PortfolioRiskMeasures } from '@/views/LossCalculatorView/components/PortfolioRiskMeasures/PortfolioRiskMeasures';
import { ExtremeLossThresholds } from '@/views/LossCalculatorView/components/ExtremeLossThresholds/ExtremeLossThresholds';
import { DistributionOfDefaultsAndLosses } from '@/views/LossCalculatorView/components/DistributionOfDefaultsAndLosses/DistributionOfDefaultsAndLosses';
import { IStatisticalRatingEntity } from '@/types/ratings';
import {
  StyledCalculatorBottomRightColumn,
  StyledCalculatorBottomRow,
  StyledCalculatorContainer,
} from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';

interface ILossCalculatorViewProps {
  fetchedRatings: IStatisticalRatingEntity[];
}

export const LossCalculatorView = ({ fetchedRatings }: ILossCalculatorViewProps) => {
  const {
    lossCalculatorMethods,
    handleLossCalculatorFormSubmit,
    resultOfLossCalculation,
    clearResultsOfLossCalculation,
    isCalculationLoading,
  } = useLossCalculatorForm();

  const formValues = useWatch({
    name: 'rows',
    control: lossCalculatorMethods.control,
  });

  const totalExposure = useMemo(() => formValues.reduce((acc, row) => acc + Number(row.exposure), 0), [formValues]);

  const renderDistributionOfDefaultsAndLosses = useMemo(() => {
    return (
      <DistributionOfDefaultsAndLosses
        defaultsFrequency={resultOfLossCalculation?.defaultsFrequency || []}
        lossFrequency={resultOfLossCalculation?.lossFrequency || []}
      />
    );
  }, [resultOfLossCalculation]);

  return (
    <FormProvider {...lossCalculatorMethods}>
      <form onSubmit={handleLossCalculatorFormSubmit}>
        <StyledCalculatorContainer>
          <PortfolioAllocation
            formMethods={lossCalculatorMethods}
            ratings={fetchedRatings}
            totalExposure={totalExposure}
            clearResultsOfLossCalculation={clearResultsOfLossCalculation}
          />
          <StyledCalculatorBottomRow>
            <CorrelationMultiplier formMethods={lossCalculatorMethods} />

            <StyledCalculatorBottomRightColumn>
              <div>
                <PortfolioRiskMeasures
                  totalExposure={totalExposure}
                  resultOfLossCalculation={resultOfLossCalculation}
                  isCalculationLoading={isCalculationLoading}
                />
              </div>
              <div>
                <ExtremeLossThresholds
                  formMethods={lossCalculatorMethods}
                  resultOfLossCalculation={resultOfLossCalculation}
                  isCalculationLoading={isCalculationLoading}
                />
              </div>
            </StyledCalculatorBottomRightColumn>
          </StyledCalculatorBottomRow>

          {renderDistributionOfDefaultsAndLosses}
        </StyledCalculatorContainer>
      </form>
    </FormProvider>
  );
};
