import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { mapGeneratedDataForHeatmap } from '@/helpers/stats';
import { entitiesChanged } from '@/views/LossCalculatorView/utils/helpers';
import { IRow } from '@/views/LossCalculatorView/utils/types';

const initialDataCorrelationMultiplierChart = mapGeneratedDataForHeatmap([], 1);

export const useManageCorrelationData = ({ formValues, correlation }: { formValues: IRow[]; correlation: number }) => {
  const [dataCorrelationMultiplierChart, setDataCorrelationMultiplierChart] = useState(
    initialDataCorrelationMultiplierChart,
  );

  const prevRowRef = useRef<IRow[]>(formValues);

  const entitiesKey = useMemo(() => formValues.map((data) => [data.entity, data.pd].join(',')).join('|'), [formValues]);

  const entitiesForAxes = useMemo(
    () =>
      formValues
        .filter(({ entity }) => !!entity)
        .map(({ entity, pd }) => ({
          entity: entity,
          pd: pd,
        })),
    [entitiesKey],
  );

  const handleCorrelationChangeCommitted = (
    event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[],
  ) => {
    if (typeof newValue === 'number') {
      const dataForChart = mapGeneratedDataForHeatmap(entitiesForAxes, newValue / 10);
      setDataCorrelationMultiplierChart(dataForChart);
    }
  };

  useEffect(() => {
    const dataForChart = mapGeneratedDataForHeatmap(entitiesForAxes, correlation / 10);
    setDataCorrelationMultiplierChart(dataForChart);
  }, [entitiesForAxes]);

  useEffect(() => {
    const areEntitiesChanged = entitiesChanged(prevRowRef.current, formValues);

    if (areEntitiesChanged) {
      prevRowRef.current = formValues;
    }
  }, [formValues]);

  return { dataCorrelationMultiplierChart, entitiesForAxes, handleCorrelationChangeCommitted };
};
