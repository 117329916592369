import { Navigate } from 'react-router-dom';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): React.ReactElement | null => {
  const { isAuthenticated, user } = useNewAuth();
  const { shouldDisplayAdminPanel } = usePermissionsManager();

  if (!user || !isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!shouldDisplayAdminPanel) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
