import { Component, ComponentType, ErrorInfo, ReactNode } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { setExtra } from '@sentry/react';

interface IState {
  hasError: boolean;
  previousLocation: string;
}

interface IProps extends WithRouterProps {
  children: ReactNode;
  fallback: ReactNode;
}

export interface WithRouterProps {
  router: {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
  };
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { location } = props.router;

    this.state = {
      hasError: false,
      previousLocation: location.pathname,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, previousLocation: location.pathname };
  }

  componentDidUpdate() {
    if (this.state.hasError && this.state.previousLocation !== location.pathname) {
      return this.setState({
        hasError: false,
        previousLocation: location.pathname,
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo);
      scope.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

function withRouter<Props extends WithRouterProps>(Component: ComponentType<Props>) {
  function ComponentWithRouterProp(props: Omit<Props, keyof WithRouterProps>) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...(props as Props)} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(ErrorBoundary);
