import { AxiosInstance, AxiosResponse } from 'axios';

type IRequestParams = Record<string, unknown>;
type IRequestHeaders = { headers?: Record<string, string | number | boolean> };
type IRequestData = Record<string, string | number | boolean>;

interface IRequestOptions extends IRequestHeaders {
  params?: IRequestParams;
  data?: IRequestData;
}

export interface IHttpClient {
  get<ReturnType>(url: string, options?: IRequestOptions): Promise<ReturnType>;

  post<RequestDataType, ReturnType = void>(
    url: string,
    requestData?: RequestDataType,
    options?: IRequestHeaders,
  ): Promise<ReturnType>;

  put<RequestDataType, ReturnType = void>(url: string, requestData: RequestDataType): Promise<ReturnType>;

  patch<RequestDataType, ReturnType = void>(url: string, requestData?: RequestDataType): Promise<ReturnType>;

  delete<ReturnType = void>(url: string): Promise<ReturnType>;
}

export class HttpClient implements IHttpClient {
  constructor(protected readonly client: AxiosInstance) {}

  async get<ReturnType>(url: string, options?: IRequestOptions): Promise<ReturnType> {
    return this.client.get<ReturnType>(url, options).then((res) => res.data);
  }

  async post<RequestDataType, ReturnType = void>(
    url: string,
    requestData: RequestDataType,
    options?: IRequestHeaders,
  ): Promise<ReturnType> {
    return this.client
      .post<RequestDataType, AxiosResponse<ReturnType>>(url, requestData, options)
      .then((res) => res.data);
  }

  async put<RequestDataType, ReturnType = void>(url: string, requestData: RequestDataType): Promise<ReturnType> {
    return this.client.put<RequestDataType, AxiosResponse<ReturnType>>(url, requestData).then((res) => res.data);
  }

  async patch<RequestDataType, ReturnType = void>(url: string, requestData: RequestDataType): Promise<ReturnType> {
    return this.client.patch<RequestDataType, AxiosResponse<ReturnType>>(url, requestData).then((res) => res.data);
  }

  async delete<ReturnType = void>(url: string): Promise<ReturnType> {
    return this.client.delete<ReturnType>(url).then((res) => res.data);
  }
}
