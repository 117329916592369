import { createContext, PropsWithChildren } from 'react';
import { INewAuthContext } from '@/providers/AuthProvider/NewAuthProvider.types';
import { useAuthManager } from '@/providers/AuthProvider/NewAuthProvider.hooks';

export const NewAuthContext = createContext<Partial<INewAuthContext>>({});

export const NewAuthProvider = ({ children }: PropsWithChildren) => {
  const { logIn, loginError, logOut, token, user, isAuthenticated, setRememberMe } = useAuthManager();

  if (token === undefined) return null;

  const authUser: INewAuthContext = {
    logIn,
    loginError,
    logOut,
    token,
    user,
    isAuthenticated,
    setRememberMe,
  };

  return <NewAuthContext.Provider value={authUser}>{children}</NewAuthContext.Provider>;
};
