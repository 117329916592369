import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { CALCULATORS_KEY } from '@/api/constants/queryKeys';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IGetLossCalculationsRequest, IGetLossCalculationsResponse } from '@/api/calculators/calculators.types';

export const useGetLossCalculations = () => {
  const { calculatorsApi } = useApi();

  const {
    error,
    mutate: getLossCalculations,
    isPending,
  } = useMutation<IGetLossCalculationsResponse, Error, IGetLossCalculationsRequest>({
    mutationKey: [CALCULATORS_KEY],
    mutationFn: (payload: IGetLossCalculationsRequest) => {
      return calculatorsApi.getLossCalculations(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`handle loss calculations`));
        }
      });
    },
  });

  return {
    error,
    getLossCalculations,
    isPending,
  };
};
