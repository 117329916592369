import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IAdminEntityIdsApi,
  IEditEntityRequest,
  IGetEntitiesListRequest,
  IGetEntitiesListResponse,
} from '@/api/adminEntityIds/adminEntityIds.types';
import { IEntityId } from '@/types/entities';

export class AdminEntityIdsApi implements IAdminEntityIdsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_ENTITY_IDS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getEntitiesList(page: number, { size }: Partial<IGetEntitiesListRequest>) {
    const params = {
      page,
      size,
    };

    return this.httpClient.get<IGetEntitiesListResponse>(this.getBaseUrl(), { params });
  }

  getEntityById(entityId: string) {
    return this.httpClient.get<IEntityId>(`${this.getBaseUrl()}${entityId}/`);
  }

  createEntity(payload: Partial<Omit<IEntityId, 'id'>>) {
    const params = {
      ...payload,
    };
    return this.httpClient.post<Omit<IEditEntityRequest, 'id'>, IEntityId>(this.getBaseUrl(), params);
  }

  editEntity({ id, ...payload }: IEditEntityRequest) {
    const params = {
      ...payload,
    };
    return this.httpClient.patch<Omit<IEditEntityRequest, 'id'>, IEntityId>(`${this.getBaseUrl()}${id}/`, params);
  }
}
