import useEmblaCarousel from 'embla-carousel-react';
import { EmblaCarouselType } from 'embla-carousel';
import AgioLogo from '@/assets/icons/sidebar-logo.svg';
import {
  StyledBolderText,
  StyledCloseButton,
  StyledContentWrapper,
  StyledDotsWrapper,
  StyledListItem,
  StyledList,
  StyledLogo,
  StyledNormalText,
  StyledProgress,
  StyledLinkButton,
  StyledSlideWrapper,
  StyledTitle,
  StyledWrapper,
  StyledProgressContainer,
  StyledCheckMarkIcon,
  StyledThirdSlideWrapper,
} from './V3UpgradeModalContent.styled';
import { IModalAsSingleViewedBannerContentProps } from '@/types/modal';
import { useCallback, useEffect, useState } from 'react';
import { PrevButton } from './components/PrevButton';
import { NextButton } from './components/NextButton';
import { DotButton } from './components/DotButton';

export const V3UpgradeModalContent = ({ closeModal, localStorageKey }: IModalAsSingleViewedBannerContentProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'start',
    slidesToScroll: 'auto',
    containScroll: 'keepSnaps',
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelectIndex = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelectIndex(emblaApi);
    emblaApi.on('reInit', onInit).on('reInit', onSelectIndex).on('select', onSelectIndex);
  }, [emblaApi, onInit, onSelectIndex]);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelectBtn = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelectBtn(emblaApi);
    emblaApi.on('reInit', onSelectBtn).on('select', onSelectBtn);
  }, [emblaApi, onSelectBtn]);

  const handleClick = () => {
    localStorage.setItem(localStorageKey, 'viewed');
    closeModal();
  };

  return (
    <StyledWrapper>
      <StyledLogo src={AgioLogo} alt="Agio logo" draggable={false} />
      <StyledContentWrapper>
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            <div className="embla__slide">
              <StyledTitle color="white.100">Model V3 is live!</StyledTitle>
              <StyledSlideWrapper>
                <StyledNormalText color="white.100">
                  Over the last year, we have observed more default events and included new data sources to improve our
                  Statistical Ratings model.
                </StyledNormalText>

                <StyledProgressContainer>
                  <StyledProgress />
                  <StyledCheckMarkIcon />
                </StyledProgressContainer>

                <StyledBolderText color="white.100">
                  Take a quick tour to see how V3 is better and what is new!
                </StyledBolderText>

                <StyledDotsWrapper>
                  <div />
                  <div />
                  <div />
                </StyledDotsWrapper>
              </StyledSlideWrapper>
            </div>
            <div className="embla__slide">
              <StyledTitle color="white.100">More accurate PDs</StyledTitle>
              <StyledSlideWrapper>
                <StyledList>
                  <StyledListItem>10 new predictive variables</StyledListItem>
                  <StyledListItem>Higher predictive performance than V2 (higher AuC)</StyledListItem>
                  <StyledListItem> High correlation with observable market forecasts</StyledListItem>
                </StyledList>
              </StyledSlideWrapper>
            </div>
            <div className="embla__slide">
              <StyledTitle color="white.100">What changes?</StyledTitle>
              <StyledThirdSlideWrapper>
                <StyledNormalText color="white.100" $left>
                  All current and historical PDs are <span>now calculated from V3</span>. This includes the time series
                  in the 1YR PD chart as well as the 1WK/1M/6M change metrics.
                </StyledNormalText>
                <StyledNormalText color="white.100" $left>
                  New predictive variables give rise to new risk drivers for each exchange. These are visible on an
                  exchange’s individual page.
                </StyledNormalText>
                <StyledNormalText color="white.100" $left>
                  Need to access the V2 Model PDs? Please reach out to our
                  <StyledLinkButton href="mailto:contact@agioratings.io"> team</StyledLinkButton>.
                </StyledNormalText>

                <StyledCloseButton onClick={handleClick}>Got it!</StyledCloseButton>
              </StyledThirdSlideWrapper>
            </div>
          </div>
        </div>
      </StyledContentWrapper>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '')}
            />
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
};
