import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { StyledAdminModal, StyledIconButton, StyledOverlay } from '@/components/Modal/Modal.styled';
import CloseIcon from '@mui/icons-material/Close';

interface IAdminModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AdminModal = ({ isOpen, closeModal, children }: PropsWithChildren<IAdminModalProps>) => {
  if (!isOpen) return null;

  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot) return null;

  return ReactDOM.createPortal(
    <StyledOverlay>
      <StyledAdminModal>
        <StyledIconButton onClick={closeModal}>
          <CloseIcon style={{ color: '#fff' }} />
        </StyledIconButton>
        {children}
      </StyledAdminModal>
    </StyledOverlay>,
    modalRoot,
  );
};

export default AdminModal;
