import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import { ChartPoint } from '@/types/chartPoint';
import mapChainData from '@/helpers/mapChainData';
import { useGetAlertRelatedData } from '@/api/adminAlerts/adminAlerts.hooks';
import { Typography } from '@mui/material';

interface CreateUpdateModalChartsProps {
  alertId: string;
  anomalyChainData: ChartPoint;
}

const CreateUpdateModalCharts = ({ alertId, anomalyChainData }: CreateUpdateModalChartsProps) => {
  const { fetchedData, isLoading, error } = useGetAlertRelatedData({ alertId: alertId });

  if (isLoading) {
    return <Typography color="white.100">Loading...</Typography>;
  }

  if (!fetchedData || error) {
    return (
      <Typography color="white.100">{error?.message || 'There is no related data to the requested alert.'}</Typography>
    );
  }

  const formattedChainData = fetchedData.chainData ? mapChainData(fetchedData.chainData) : [];
  const formattedNetFlowData = mapChainData(fetchedData.netflowData);
  const formattedInFlowData = mapChainData(fetchedData.inflowData);
  const formattedOutFlowData = mapChainData(fetchedData.outflowData).map((o) => {
    const yPoint = o.y !== null ? -o.y : null;

    return { ...o, y: yPoint };
  });
  const formattedTotalFlowData = mapChainData(fetchedData.totalFlowData);

  return (
    <div>
      <WrapperCharts
        alertId={alertId}
        anomalyChainData={anomalyChainData}
        isDataLoaded={!isLoading}
        chartData={formattedChainData}
        netflowData={formattedNetFlowData}
        inflowData={formattedInFlowData}
        outflowData={formattedOutFlowData}
        totalflowData={formattedTotalFlowData}
      />
    </div>
  );
};

export default CreateUpdateModalCharts;
