import styled from '@emotion/styled';
import { Switch } from '@mui/material';

export const StyledCustomSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: #bebebe;
  }

  & .Mui-checked .MuiSwitch-thumb {
    background-color: #5d51b8;
  }

  & .Mui-checked .MuiSwitch-track {
    background-color: #5d51b8;
  }
`;
