import DownloadIcon from '@/assets/icons/download.svg?react';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import Button from '@/components/atoms/Button/Button';
import { StyledExportButton } from '@/components/atoms/Button/Buttons.styled';

interface IExportCommonProps {
  variant: 'icon' | 'text';
  onClick: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | (() => void);
  tooltipText: string;
}

interface IconVariantProps {
  variant: 'icon';
  text?: never;
}

interface TextVariantProps {
  variant: 'text';
  text: string;
}

type TExportButtonProps = IExportCommonProps & (IconVariantProps | TextVariantProps);

export const ExportButton = ({ variant, onClick, tooltipText, text }: TExportButtonProps) => {
  const generateContent = () => {
    if (variant === 'icon') {
      return (
        <StyledExportButton onClick={onClick} variant="contained" color="secondary" skipTypography>
          <DownloadIcon />
        </StyledExportButton>
      );
    }

    return (
      <Button onClick={onClick} variant="contained" color="secondary" fitContentWidth>
        {text}
      </Button>
    );
  };
  return (
    <Tooltip arrow title={tooltipText}>
      <span>{generateContent()}</span>
    </Tooltip>
  );
};
