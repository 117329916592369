import styled from '@emotion/styled';

const HEADER_HEIGHT = '99px';

export const StyledTwoColumnLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT});
  overflow: hidden;
`;

export const StyledLeftContainer = styled.div`
  width: 50%;
  border-right: 1px solid #2e2e31;
  border-bottom: 1px solid #2e2e31;
  overflow: auto;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  padding: 16px 16px 0;
  width: 50%;
  overflow-y: auto;
`;
