import styled from '@emotion/styled';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { format } from 'date-fns';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { ChartData } from '@/types/chartPoint';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const ChartContainer = styled.div`
  min-width: 300px;
  margin: 0 auto;

  .highcharts-scrolling {
    background-color: #101014 !important;
    scrollbar-color: #6969dd #e0e0e0 !important;
    scrollbar-width: thin !important;
    padding-bottom: 10px !important;
  }
`;

const Container = styled.div`
  margin: 1em auto;
  width: 100%;
  overflow: auto;
`;

interface TooltipFormatterContextObject {
  x: number;
  y: number;
  point: {
    series: {
      name: string;
    };
  };
}

interface ColumnChartProps {
  flowData: ChartData;
  isTotalFlow?: boolean;
  title?: string;
  isHourlyChart?: boolean;
}

const ColumnChart = ({
  flowData,
  isTotalFlow = false,
  title = 'Net Asset Flows',
  isHourlyChart = false,
}: ColumnChartProps) => {
  const backgroundColor = '#101014';
  const chartBackground = '#282829';
  flowData?.sort((a, b) => a.x - b.x);
  const timestampOfLastDataPoint = flowData?.length ? flowData[flowData.length - 1].x : null;
  const timestampOfFirstDataPoint = flowData?.length ? flowData[0].x : null;

  let chartSubtitle;

  if (timestampOfLastDataPoint && timestampOfFirstDataPoint) {
    const initialDate = format(new Date(timestampOfFirstDataPoint), 'dd-MM-yyyy');
    const finalDate = format(new Date(timestampOfLastDataPoint), 'dd-MM-yyyy');
    chartSubtitle = `${initialDate} / ${finalDate}`;
  }

  const hourlyChartOptions = {
    subtitle: {
      text: chartSubtitle,
      style: {
        color: '#F7F7F7',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22.584px',
      },
      align: 'right',
    },
    tooltip: {
      xDateFormat: '%d-%m-%Y %H:%M',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f}$</b><br/>',
    },
    xAxis: {
      tickInterval: 3600 * 6000, // six hours,
      labels: {
        style: {
          color: '#F7F7F7',
          fontSize: '14px',
          textOverflow: 'none',
        },
        formatter: function (this: { value: number }) {
          const getProperHours = new Date(this.value).getHours();
          const getProperMinutes = new Date(this.value).getMinutes();
          const hoursPrefix = getProperHours < 10 ? '0' : '';
          const minutesPrefix = getProperMinutes < 10 ? '0' : '';
          return `${hoursPrefix}${getProperHours}:${minutesPrefix}${getProperMinutes}`;
        },
      },
    },
  };

  const dateTimeChartOptions = {
    tooltip: {
      shared: true,
      split: false,
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        const date = format(this.x, 'dd MMM');
        let s = `<span style="color: #fff;">${date}</span>`;
        const value = Highcharts.numberFormat(this.y, 2).replace(/ /g, ',');
        const color = this.y < 0 ? 'red' : 'green';
        s += `<div style="display:inline-block; width:10px; height:10px; background-color:${color}; border-radius:5px; margin:0 5px;"></div> <span style="color: #fff;">${value}</span>`;
        return s;
      },
    },
    xAxis: {
      labels: {
        style: {
          color: '#F7F7F7',
          fontSize: '14px',
        },
      },
      tickPositioner: function (this: { max: number; min: number; dataMin: number; dataMax: number }) {
        if (this.max !== this.min) {
          const positions: any = [];
          const divisions = 5;
          const tickInterval = (this.max - this.min) / divisions;

          for (let i = 0; i <= divisions; i++) {
            positions.push(this.min + i * tickInterval);
          }

          positions.info = {
            unitName: 'day',
            higherRanks: {},
            totalRange: positions[positions.length - 1] - positions[0],
          };
          return positions;
        }
      },
    },
  };

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: backgroundColor,
      height: 243,
      type: 'column',
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
    legend: {
      enabled: false,
    },
    title: {
      text: title,
      style: {
        color: '#F7F7F7',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22.584px',
      },
      align: 'right',
    },
    tooltip: {
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      style: {
        color: '#ffffff',
      },
      ...(isHourlyChart ? hourlyChartOptions.tooltip : dateTimeChartOptions.tooltip),
    },
    xAxis: {
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: chartBackground,
      max: timestampOfLastDataPoint,
      min: timestampOfFirstDataPoint,
      type: 'datetime',
      tickLength: 0,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      ...(isHourlyChart ? hourlyChartOptions.xAxis : dateTimeChartOptions.xAxis),
    },
    yAxis: {
      gridLineColor: chartBackground,
      title: {
        text: null,
      },
      labels: {
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
        format: '{text} $',
      },
      tickInterval: null,
      endOnTick: true,
      startOnTick: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.1,
      },
      series: {
        pointStart: timestampOfFirstDataPoint,
      },
    },
    series: [
      {
        name: title,
        data: flowData,
        pointPlacement: 0,
        zones: [
          {
            value: 0,
            color: 'red',
          },
          {
            value: Number.MAX_VALUE,
            color: isTotalFlow ? '#e5b302' : 'green',
          },
        ],
      },
    ],
    time: {
      useUTC: false,
    },
    ...(isHourlyChart && { subtitle: hourlyChartOptions.subtitle }),
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  return (
    <Container>
      <ChartContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </ChartContainer>
    </Container>
  );
};

export default ColumnChart;
