import styled from '@emotion/styled';

const InsightsPanel = styled.div`
  display: flex;
  width: auto;
  height: auto;
  padding: 30px 50px 30px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #2e2e31;
`;

const InsightsTitle = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;

const InsightsDescription = styled.span`
  color: #f7f7f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const InsightPanel = ({ text }: { text: string }) => {
  return (
    <InsightsPanel>
      <InsightsTitle>insights </InsightsTitle>

      <InsightsDescription>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </InsightsDescription>
    </InsightsPanel>
  );
};

export default InsightPanel;
