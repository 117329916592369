import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

export const StyledPaper = styled(Paper)`
  border: 1px solid #222224;
  padding: 10px 100px 10px 30px;
  width: 50%;
  height: auto;
  background-color: transparent;
`;
