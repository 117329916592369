import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IGetRatingsListResponse, IRatingsApi } from '@/api/ratings/ratings.types';

export class RatingsApi implements IRatingsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.RATINGS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getRatingsList() {
    return this.httpClient.get<IGetRatingsListResponse>(this.getBaseUrl());
  }

  getRatingsFile() {
    return this.httpClient.get<string>(`${this.getBaseUrl()}get_ratings_file/`);
  }
}
