import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { RISKS_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IGetRisksDecompositionResponse } from '@/api/risks/risks.types';

export const useGetRisksDecomposition = () => {
  const { risksApi } = useApi();

  const {
    data: fetchedRisksDecomposition,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IGetRisksDecompositionResponse, Error>({
    queryKey: [RISKS_KEY, 'decomposition'],
    queryFn: () => {
      return risksApi.getRisksDecomposition().catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`fetch risks decomposition`));
        }
      });
    },
  });

  return {
    fetchedRisksDecomposition,
    isLoading,
    isSuccess,
    error,
  };
};
