import { z, ZodType } from 'zod';

export interface IChangePasswordForm {
  currentPass: string;
  newPass: string;
  repeatNewPass: string;
}

export const SettingsSchema: ZodType<IChangePasswordForm> = z
  .object({
    currentPass: z.string().min(8, { message: 'First name must be at least 8 characters.' }),
    newPass: z.string().min(8, { message: 'First name must be at least 8 characters.' }),
    repeatNewPass: z.string(),
  })
  .refine((data) => data.newPass === data.repeatNewPass, {
    message: 'New passwords do not match.',
    path: ['repeatNewPass'],
  });
