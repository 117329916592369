import styled from '@emotion/styled';
import { Pagination as MuiPaginationComponent } from '@mui/material';

const StyledPagination = styled(MuiPaginationComponent)`
  .MuiPaginationItem-root {
    color: rgba(151, 151, 151, 0.87);
  }

  .MuiPaginationItem-root.Mui-selected {
    background-color: rgba(48, 48, 48, 1);
  }
`;

export const Pagination = ({
  currentPage,
  setPage,
  totalNumberOfItems,
  pageSize,
  className,
}: {
  currentPage: number;
  setPage: (page: number) => void;
  totalNumberOfItems: number;
  pageSize: number;
  className?: string;
}) => {
  const onChangeHandler = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const totalNumberOfPages = Math.ceil(totalNumberOfItems / pageSize);

  return (
    <StyledPagination className={className} count={totalNumberOfPages} page={currentPage} onChange={onChangeHandler} />
  );
};
