import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { StyledCustomSwitch } from '@/components/atoms/CustomSwitch/CustomSwitch.styled';

const CUSTOM_SWITCH_SX = {
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#5d51b8 !important',
  },
};

interface ControlledCustomSwitchProps<T extends FieldValues> {
  inputName: Path<T>; // key or chained keys - of the form schema accordingly
  formMethods: UseFormReturn<T>;
}

export const ControlledCustomSwitch = <T extends FieldValues>({
  inputName,
  formMethods,
}: ControlledCustomSwitchProps<T>) => {
  return (
    <Controller
      render={({ field }) => {
        return (
          <StyledCustomSwitch
            id={field.name}
            sx={CUSTOM_SWITCH_SX}
            checked={field.value}
            onChange={field.onChange}
            inputRef={field.ref}
          />
        );
      }}
      name={inputName}
      control={formMethods.control}
    />
  );
};
