import styled from '@emotion/styled';

export const StyledCorrelationInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border: 0;
  border-radius: 4px;
  width: fit-content;
  height: 36px;
  background-color: #1b1b1e;
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
`;
