import CircularProgress from '@mui/material/CircularProgress';
import { useFlowChartsManager } from '@/components/molecules/Charts/useFlowChartsManager';
import AnomalyTimeLineChart from '@/components/molecules/Charts/AnomalyTimeLineChart';
import ColumnChart from '@/components/molecules/Charts/ColumnChart';
import Select from '@/components/molecules/Select/Select';
import { StyledPaper, StyledSpan, ChartsContainer } from '@/components/molecules/Charts/WrapperCharts.styled';
import { ChartData, ChartPoint } from '@/types/chartPoint';

interface WrapperChartsProps {
  alertId: string;
  chartData: ChartData;
  anomalyChainData: ChartPoint;
  layout?: 'row' | 'column';
  isDataLoaded?: boolean;
  netflowData: ChartData;
  inflowData: ChartData;
  outflowData: ChartData;
  totalflowData: ChartData;
  hourlyNetflowData?: ChartData;
  hourlyInflowData?: ChartData;
  hourlyOutflowData?: ChartData;
  hourlyTotalflowData?: ChartData;
}

const WrapperCharts = ({
  alertId,
  anomalyChainData,
  layout = 'column',
  chartData,
  netflowData,
  inflowData,
  outflowData,
  totalflowData,
  isDataLoaded,
  hourlyNetflowData,
  hourlyInflowData,
  hourlyOutflowData,
  hourlyTotalflowData,
}: WrapperChartsProps) => {
  const {
    shouldRenderSelect,
    filteredFlowDataChartOptions,
    handleSelectedFlowDataChange,
    selectedFlowDataChart,
    shouldRenderFlowsChart,
    generatedFlowData,
    generatedTitle,
    isHourlyChart,
  } = useFlowChartsManager({
    netflowData,
    inflowData,
    outflowData,
    totalflowData,
    hourlyNetflowData,
    hourlyInflowData,
    hourlyOutflowData,
    hourlyTotalflowData,
  });

  return (
    <StyledPaper>
      <StyledSpan>Timeseries</StyledSpan>
      <ChartsContainer layout={layout}>
        {anomalyChainData && isDataLoaded ? (
          <>
            <AnomalyTimeLineChart alertId={alertId} anomalyChainData={anomalyChainData} chartData={chartData} />
            <div style={{ width: '100%' }}>
              {shouldRenderSelect && (
                <Select
                  options={filteredFlowDataChartOptions}
                  onChange={handleSelectedFlowDataChange}
                  value={selectedFlowDataChart || ''}
                  clearVisible={false}
                />
              )}
              {shouldRenderFlowsChart && (
                <ColumnChart
                  key={selectedFlowDataChart}
                  flowData={generatedFlowData}
                  isTotalFlow={selectedFlowDataChart === 'totalflow' || selectedFlowDataChart === 'totalflow-hourly'}
                  title={generatedTitle}
                  isHourlyChart={isHourlyChart}
                />
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        )}
      </ChartsContainer>
    </StyledPaper>
  );
};

export default WrapperCharts;
