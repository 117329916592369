import Zoom from '@mui/material/Zoom';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import { StyledFlexCenter } from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { ReactNode } from 'react';

interface ILossMeasuresProps {
  tooltipText: ReactNode;
  lossName: string;
  lossUsd?: string;
  lossPct?: string;
}

export const LossMeasures = ({ tooltipText, lossName, lossUsd = '', lossPct = '' }: ILossMeasuresProps) => {
  return (
    <>
      <StyledFlexCenter>
        <Tooltip arrow TransitionComponent={Zoom} title={tooltipText}>
          <p>{lossName}</p>
        </Tooltip>
      </StyledFlexCenter>
      <div>
        <BaseTextField disabled value={lossUsd} />
      </div>
      <div>
        <BaseTextField disabled value={lossPct} />
      </div>
    </>
  );
};
