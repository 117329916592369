import styled from '@emotion/styled';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const StyledDatePicker = styled(DatePicker)`
  max-height: 56px;
  background: #1b1b1e;

  & input.MuiInputBase-input.MuiOutlinedInput-input,
  button.MuiButtonBase-root.MuiIconButton-root {
    color: #ffffff8a;
  }
`;
