import { AppBar, Toolbar, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { AlertsHeaderPermitted } from '@/components/molecules/Headers/AlertsHeaderPermitted';

const StyledHeader = styled(AppBar)`
  height: 99px;
  width: calc(100vw - 100px);
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const AlertsHeader = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();

  if (!shouldDisplayAlerts) {
    return (
      <StyledHeader position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
                Anomaly Alerts
              </Typography>
            </div>
          </div>
        </Toolbar>
      </StyledHeader>
    );
  }

  return <AlertsHeaderPermitted />;
};

export default AlertsHeader;
