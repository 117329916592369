import styled from '@emotion/styled';
import { AppBar, Link, Toolbar, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { ExportButton } from '@/components/atoms/Button/ExportButton';
import HelpIcon from '@/assets/icons/help.svg';
import { EFileKeys } from '@/views/LossCalculatorView/utils/types';
import { downloadFile } from '@/helpers/file';

const StyledHeader = styled(AppBar)`
  height: 99px;
  width: calc(100vw - 100px);
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const PortfolioLossHeader = () => {
  const exportToCsv = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // Headers for each column
    const headers = [EFileKeys.ENTITY, EFileKeys.PD, EFileKeys.RECOVERY, EFileKeys.EXPOSURE];
    downloadFile({
      data: [...headers].join(';'),
      fileName: 'exposure_template.csv',
      fileType: 'text/csv',
    });
  };

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between', gap: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
            Portfolio Loss Calculator
          </Typography>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title="A portfolio loss simulator is a tool used to model and predict potential financial losses in an investment portfolio under various market conditions. It employs statistical methods and historical data to simulate adverse scenarios, helping investors understand and mitigate risks."
          >
            <img src={HelpIcon} alt="Help icon" />
          </Tooltip>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
          <Tooltip arrow TransitionComponent={Zoom} title="Loss Calculator Documentation - Click to open in a new tab.">
            <Link
              href="https://agio-ratings-ltd.gitbook.io/portfolio-loss-calculator/zkniaMDSXS1fORjQjIJy"
              underline="hover"
              target="_blank"
            >
              Documentation
            </Link>
          </Tooltip>

          <ExportButton
            variant="text"
            onClick={exportToCsv}
            tooltipText="Download exposure template"
            text="Exposure template"
          />
        </div>
      </Toolbar>
    </StyledHeader>
  );
};

export default PortfolioLossHeader;
