import { useMemo } from 'react';
import { EPermissions } from '@/types/permissions';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';

export const usePermissionsManager = () => {
  const { user } = useNewAuth();

  const shouldDisplayRisks = useMemo(() => {
    if (user === null) return true;
    const isRisksSegmentPermitted = user?.permissions.find((permission) => permission === EPermissions.RISKS);
    return Boolean(isRisksSegmentPermitted);
  }, [user]);

  const shouldDisplayAlerts = useMemo(() => {
    if (user === null) return true;
    const isAlertsSegmentPermitted = user?.permissions.find((permission) => permission === EPermissions.ALERTS);
    return Boolean(isAlertsSegmentPermitted);
  }, [user]);

  const shouldDisplayDocumentation = useMemo(() => {
    const isDocumentationSegmentPermitted = user?.permissions.find(
      (permission) => permission === EPermissions.WHITE_PAPER,
    );
    return Boolean(isDocumentationSegmentPermitted);
  }, [user]);

  const shouldDisplayAdminPanel = useMemo(() => {
    const isAdmin = user?.permissions.find((permission) => permission === EPermissions.ADMIN);
    return Boolean(isAdmin);
  }, [user]);

  return {
    shouldDisplayRisks,
    shouldDisplayAlerts,
    shouldDisplayDocumentation,
    shouldDisplayAdminPanel,
  };
};
