import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertsList } from '@/views/AlertsView/components/AlertsList/AlertsList';
import AlertDetails from '@/views/AlertsView/components/AlertDetails';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import { StyledAlertDetailsContainer, StyledPageContainer } from './AlertsView.styled';

export const AlertsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isGridCollapsed, setGridCollapsed] = useState(false);

  const handleSelectedAlert = (rowId: string) => {
    setGridCollapsed(true);
    navigate(`/${rowId}`);
  };

  const handleToggleCollapse = () => {
    setGridCollapsed((prev) => !prev);
    navigate(`/dashboard`);
  };

  useEffect(() => {
    if (id) {
      setGridCollapsed(true);
    }
  }, [id]);

  return (
    <StyledPageContainer>
      <AlertsList
        isGridCollapsed={isGridCollapsed}
        handleToggleCollapse={handleToggleCollapse}
        handleSelectedAlert={handleSelectedAlert}
      />

      {isGridCollapsed && (
        <ErrorBoundary fallback={<CommonError variant="empty" />}>
          <StyledAlertDetailsContainer>
            <AlertDetails />
          </StyledAlertDetailsContainer>
        </ErrorBoundary>
      )}
    </StyledPageContainer>
  );
};
