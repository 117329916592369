import styled from '@emotion/styled';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { RatingsHeaderPermitted } from '@/components/molecules/Headers/RatingsHeaderPermitted';

const StyledHeader = styled(AppBar)`
  height: 99px;
  width: calc(100vw - 100px);
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const RatingsHeader = () => {
  const { shouldDisplayRisks } = usePermissionsManager();

  if (!shouldDisplayRisks) {
    return (
      <StyledHeader position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
                Statistical Ratings
              </Typography>
            </div>
          </div>
        </Toolbar>
      </StyledHeader>
    );
  }

  return <RatingsHeaderPermitted />;
};

export default RatingsHeader;
