import { PersonalPreferencesForm } from '@/components/organims/PersonalPreferencesForm/PersonalPreferencesForm';
import { ChangePasswordForm } from '@/components/organims/ChangePasswordForm/ChangePasswordForm';
import { StyledContainer, StyledTitle } from './SettingsView.styled';

export const SettingsView = () => {
  return (
    <StyledContainer>
      <StyledTitle>Settings</StyledTitle>

      <PersonalPreferencesForm />

      <ChangePasswordForm />
    </StyledContainer>
  );
};
