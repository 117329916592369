import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { LossCalculator } from '@/views/LossCalculatorView/LossCalculator';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { useModalAsSingleViewedBanner } from '@/hooks/useModalAsSingleViewedBanner';
import { ELocalStorage } from '@/types/storage';
import { V3UpgradeModalContent } from '@/components/V3UpgradeModalContent/V3UpgradeModalContent';

const LossCalculatorPage = () => {
  const { shouldDisplayRisks } = usePermissionsManager();

  const { ModalToRender } = useModalAsSingleViewedBanner({
    ModalContent: V3UpgradeModalContent,
    localStorageKey: ELocalStorage.V3_BANNER,
  });

  if (!shouldDisplayRisks) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <LossCalculator />
      <ModalToRender />
    </ErrorBoundary>
  );
};

export default LossCalculatorPage;
