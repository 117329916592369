import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { formatDateToUK, formatFloatToPercent } from '@/helpers/helpers';
import mapChainData from '@/helpers/mapChainData';
import { useGetAlertById } from '@/api/alerts/alerts.hooks';
import AlertDetailsPanel from '@/components/molecules/AlertDetailsPanel/AlertDetailsPanel';
import InsightPanel from '@/components/molecules/InsightPanel/InsightPanel';
import UpdatesPanel from '@/components/molecules/UpdatesPanel/UpatesPanel';
import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import Loader from '@/components/atoms/Loader/Loader';
import { mapHourlyData } from '@/helpers/mapHourlyData';

const AlertDetails = () => {
  const { id } = useParams<{ id: string }>();
  const alertId = id || '';
  const { fetchedAlert, isSuccess, isLoading, error } = useGetAlertById({ alertId });

  if (isLoading) {
    return (
      <div style={{ paddingTop: '20px' }}>
        <Loader />
      </div>
    );
  }

  if (error) {
    throw error;
  }

  if (!fetchedAlert) {
    return <Typography color="white.100">`{"Requested alert doesn't exist."}`</Typography>;
  }

  const formattedData = fetchedAlert.chainData ? mapChainData(fetchedAlert.chainData) : [];

  const formattedNetFlowData = mapChainData(fetchedAlert.netflowData);
  const formattedInFlowData = mapChainData(fetchedAlert.inflowData);
  const formattedOutFlowData = mapChainData(fetchedAlert.outflowData).map((o) => {
    const yPoint = o.y !== null ? -o.y : null;

    return { ...o, y: yPoint };
  });
  const formattedTotalFlowData = mapChainData(fetchedAlert.totalFlowData);

  const formattedHourlyNetflowData = mapHourlyData(fetchedAlert.hourlyNetflowData);
  const formattedHourlyInflowData = mapHourlyData(fetchedAlert.hourlyInflowData);
  const formattedHourlyOutflowData = mapHourlyData(fetchedAlert.hourlyOutflowData).map((o) => {
    const yPoint = o.y !== null ? -o.y : null;

    return { ...o, y: yPoint };
  });
  const formattedHourlyTotalflowData = mapHourlyData(fetchedAlert.hourlyTotalFlowData);

  const anomalyChainData = formattedData.sort((a, b) => a.x - b.x)[formattedData.length - 1];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '20px' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        <AlertDetailsPanel
          companyName={fetchedAlert.metadata?.exchange || ''}
          z_score={fetchedAlert.zScore}
          severity={fetchedAlert.severity}
          timestamp={formatDateToUK(fetchedAlert.timestamp)}
          entityType={fetchedAlert.metadata?.entityType || ''}
          variable={mapMetadataType(fetchedAlert.metadata?.type)}
          change={!fetchedAlert.changePercentage ? '0.00' : formatFloatToPercent(fetchedAlert.changePercentage)}
          amount={`${fetchedAlert.changeAmount?.toFixed(2)}`}
        />
        <WrapperCharts
          key={alertId}
          isDataLoaded={isSuccess}
          chartData={formattedData}
          alertId={alertId}
          anomalyChainData={anomalyChainData}
          netflowData={formattedNetFlowData}
          inflowData={formattedInFlowData}
          outflowData={formattedOutFlowData}
          totalflowData={formattedTotalFlowData}
          hourlyNetflowData={formattedHourlyNetflowData}
          hourlyInflowData={formattedHourlyInflowData}
          hourlyOutflowData={formattedHourlyOutflowData}
          hourlyTotalflowData={formattedHourlyTotalflowData}
        />
      </div>
      <div style={{ marginTop: '16px' }}>
        {fetchedAlert.insight && <InsightPanel text={fetchedAlert.insight} />}
        {fetchedAlert.updates.length > 0 && (
          <div style={{ marginTop: '16px' }}>
            <UpdatesPanel
              updates={fetchedAlert.updates}
              alertTimestamp={fetchedAlert.timestamp}
              alertChangeAmount={fetchedAlert.changeAmount}
              alertId={alertId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertDetails;
