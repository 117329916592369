import { Tooltip as MuiTooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

const Tooltip = ({ title, children, placement = 'right-start', arrow, ...rest }: TooltipProps) => {
  return (
    <MuiTooltip
      title={title}
      placement={placement}
      enterDelay={200}
      leaveDelay={200}
      arrow={arrow}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '513px',
            bgcolor: '#1B1B1E',
            fontSize: '14px',
            padding: '10px 20px',
            lineHeight: '24px',
            span: {
              color: '#1B1B1E',
            },
          },
        },
      }}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
