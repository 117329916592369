import { useMutation, useQuery } from '@tanstack/react-query';
import { IUserDto, IUserId } from '@/types/user';
import { USER_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IEditUserPasswordRequest, IEditUserRequest } from '@/api/user/user.types';
import { UserApi } from '@/api/user/user.api';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { AxiosError } from 'axios';
import { getIdFromToken } from '@/hooks/useUserIdFromToken';

export const useGetUser = (userApi: UserApi) => {
  const {
    mutate: fetchingUser,
    isError,
    isSuccess,
  } = useMutation<IUserDto, Error, string>({
    mutationKey: [USER_KEY, 'GET'],
    mutationFn: (token: string) => {
      const userId = getIdFromToken(token);
      if (!userId) throw new Error('Received invalid token.');

      return userApi
        .getUser(token, userId)
        .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load user data`)));
    },
  });

  return {
    isError,
    isSuccess,
    fetchingUser,
  };
};

export const useGetUserById = ({ id }: IUserId) => {
  const { userApi } = useApi();

  const {
    data: fetchedUser,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IUserId, Error, IUserDto>({
    queryKey: [USER_KEY, 'GET', 'BY_ID', id],
    meta: { userId: id },
    queryFn: ({ meta }) => {
      if (meta && meta.userId && typeof meta.userId === 'string') {
        return userApi
          .getUserById(meta.userId)
          .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load user data`)));
      }
      throw new Error('userId should be a meta string');
    },
  });

  return {
    fetchedUser,
    isLoading,
    isSuccess,
    error,
  };
};

export const useEditUser = () => {
  const { userApi } = useApi();

  const {
    isPending,
    error,
    mutate: editUser,
  } = useMutation<IUserDto, Error, IEditUserRequest>({
    mutationKey: [USER_KEY, 'EDIT'],
    mutationFn: (payload: IEditUserRequest) => {
      return userApi.editUser(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error('An unexpected error occurred.');
        }
      });
    },
  });

  return {
    error,
    editUser,
    isLoading: isPending,
  };
};

export const useEditUserPassword = () => {
  const { userApi } = useApi();

  const {
    isPending,
    error,
    mutate: editUserPassword,
  } = useMutation<IUserDto, Error, IEditUserPasswordRequest>({
    mutationKey: [USER_KEY, 'EDIT_PASSWORD'],
    mutationFn: (payload: IEditUserPasswordRequest) => {
      return userApi.editUserPassword(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error('An unexpected error occurred.');
        }
      });
    },
  });

  return {
    isLoading: isPending,
    error,
    editUserPassword,
  };
};
