import styled from '@emotion/styled';
import HelpIcon from '@/assets/icons/help.svg?react';
import Tooltip from '../atoms/Tooltip/Tooltip';

const VariableValueContainer = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface VariableValueProps {
  value?: number;
  color: string;
  addPercentage?: boolean;
}

const VariableValue = ({ value, color, addPercentage }: VariableValueProps) => {
  if (value === 0) {
    return (
      <VariableValueContainer color={color}>
        {value}
        {addPercentage && '%'}
        <Tooltip title="We were not able to obtain statistically significant data.">
          <div>
            <HelpIcon style={{ width: '15px', height: '15px', marginLeft: '5px ' }} />
          </div>
        </Tooltip>
      </VariableValueContainer>
    );
  }

  return (
    <VariableValueContainer color={color}>
      {value} {value && addPercentage && '%'}
    </VariableValueContainer>
  );
};

export default VariableValue;
