import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import BasePanel from '@/components/atoms/BasePanel/BasePanel';
import { ControlledBaseTextField } from '@/components/atoms/BaseTextField/BaseTextField';
import { IPersonalPreferencesForm } from '@organims/PersonalPreferencesForm/utils/types';
import { StyledWrapper, StyledContainer } from './PersonalDetailsPanel.styled';

interface PersonalDetailsProps {
  formMethods: UseFormReturn<IPersonalPreferencesForm>;
}

const PersonalDetailsPanel: React.FC<PersonalDetailsProps> = ({ formMethods }) => {
  return (
    <BasePanel title={'Personal Details'}>
      <StyledContainer>
        <StyledWrapper>
          <ControlledBaseTextField label="First Name" inputName="user.firstName" formMethods={formMethods} />
        </StyledWrapper>
        <StyledWrapper>
          <ControlledBaseTextField label="Last Name" inputName="user.lastName" formMethods={formMethods} />
        </StyledWrapper>
        <StyledWrapper>
          <ControlledBaseTextField
            label="Account Status"
            inputName="user.accountStatus"
            formMethods={formMethods}
            disabled
          />
        </StyledWrapper>
        <StyledWrapper>
          <ControlledBaseTextField label="E-mail" inputName="user.email" formMethods={formMethods} />
        </StyledWrapper>
      </StyledContainer>
    </BasePanel>
  );
};

export default PersonalDetailsPanel;
