import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useGetRisksDecomposition } from '@/api/risks/risks.hooks';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { variables } from '@/components/RisksDecompositions/constants';
import HelpIcon from '@/assets/icons/help.svg?react';
import RisksDecompositionChart from './RisksDecompositionChart';
import Select from '../molecules/Select/Select';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { IStatisticalRatingEntity } from '@/types/ratings';
import VariableValue from './VariableValue';
import Loader from '../atoms/Loader/Loader';
import VariableName from './VariableName';
import { sortAlphabetically } from '@/helpers/helpers';
import {
  mapEntityNameToRisksDecomposition,
  compareRisksDates,
  prepareChartData,
  RiskDecompositionDataPoint,
} from '@/components/RisksDecompositions/helpers';
import { IDatePickerRange } from '@/types/datePicker';
import {
  StyledDatePicker,
  StyledRow,
  StyledSmallContainer,
  StyledTitle,
  StyledTableRow,
  StyledHeaderName,
  StyledDateContainer,
  StyledVariableTitle,
  StyledTitleRow,
  StyledVariablesTable,
} from './RisksDecompositions.styled';

type RisksDecompositionProps = {
  originalEntityName?: string;
  statisticalRatings: IStatisticalRatingEntity[];
};

const RisksDecomposition = ({ originalEntityName, statisticalRatings }: RisksDecompositionProps) => {
  const [selectedExchange, setSelectedExchange] = useState<string>('');

  const { fetchedRisksDecomposition, error, isLoading } = useGetRisksDecomposition();

  const availableDatesRange: IDatePickerRange = useMemo(() => {
    const placeholder = '2024-06-27';
    const fetchedStart =
      fetchedRisksDecomposition?.risksDecomposition[0].data[
        fetchedRisksDecomposition?.risksDecomposition[0].data.length - 1
      ].date.split('T')[0];
    const fetchedEnd = fetchedRisksDecomposition?.risksDecomposition[0].data[0].date.split('T')[0];

    if (fetchedStart && fetchedEnd) {
      return {
        start: dayjs(fetchedStart),
        end: dayjs(fetchedEnd),
      };
    }

    return {
      start: dayjs(placeholder),
      end: dayjs(placeholder),
    };
  }, [fetchedRisksDecomposition]);

  const [selectedDate, setSelectedDate] = useState<Dayjs>(availableDatesRange.end);

  const handleDatePickerChange = (newValue: unknown) => {
    if (dayjs.isDayjs(newValue)) {
      setSelectedDate(newValue);
    }
  };

  const selectedDateTimestamp = useMemo(() => {
    return selectedDate.format('YYYY-MM-DD');
  }, [selectedDate]);

  const handleOnChangeExchange = (value: string) => {
    setSelectedExchange(value);
  };

  const exchangeList = useMemo(() => {
    const exchanges = statisticalRatings.map((sr) => sr.entity);

    return sortAlphabetically(exchanges);
  }, [statisticalRatings]);

  if (isLoading) {
    return (
      <StyledSmallContainer style={{ height: '561px' }}>
        <Loader />
      </StyledSmallContainer>
    );
  }

  if (error) {
    throw error;
  }

  if (!fetchedRisksDecomposition?.risksDecomposition.length) {
    throw Error('No provided risk decomposition data');
  }

  const mappedEntityName = mapEntityNameToRisksDecomposition(originalEntityName);
  const entityData = fetchedRisksDecomposition.risksDecomposition.find((r) => r.entity === mappedEntityName);
  const riskData = statisticalRatings.find((sr) => sr.entity === originalEntityName);
  const entityPD = riskData?.data.find((sr) => compareRisksDates(selectedDateTimestamp, sr.timestamp));
  const values = entityData?.data
    ? entityData.data.find((d: RiskDecompositionDataPoint) => compareRisksDates(selectedDateTimestamp, d.date))
    : undefined;
  const comparedEntityData = fetchedRisksDecomposition.risksDecomposition.find(
    (r) => r.entity === mapEntityNameToRisksDecomposition(selectedExchange),
  );
  const comparedEntityRisksData = statisticalRatings.find((r) => r.entity === selectedExchange);
  const comparedEntityPD = comparedEntityRisksData?.data.find((sr) =>
    compareRisksDates(selectedDateTimestamp, sr.timestamp),
  );
  const comparedValues = comparedEntityData?.data
    ? comparedEntityData.data.find((d: RiskDecompositionDataPoint) => compareRisksDates(selectedDateTimestamp, d.date))
    : undefined;

  if (!values) {
    return (
      <StyledSmallContainer style={{ height: '561px' }}>
        <Loader />
      </StyledSmallContainer>
    );
  }

  const chartData = prepareChartData(values, comparedValues);

  return (
    <StyledSmallContainer>
      <StyledRow>
        <StyledTitle>KEY RISK DRIVERS</StyledTitle>
        <Tooltip
          arrow
          title={
            <>
              <p>
                The table below describes the 10 key risk drivers of an exchange’s 1-YR PD. More risk drivers are
                included in the Statistical Ratings model; however, only the ones with the highest impact are displayed
                below.{' '}
              </p>
              <p>
                The chart plots the key risk scores (out of 100) for the exchange. The score has been adjusted to
                reflect the performance of the exchange with respect to the wider pool of exchanges. A low score
                indicates that the exchange is doing poorly when compared to the rest of the exchanges analysed by Agio
                Ratings. Conversely, a high score indicates that the exchange is performing well when compared to the
                rest of the exchanges.
              </p>
              <p>
                Additionally, the user can select a different exchange to display a barbell chart. The barbell compares
                the two exchanges’ key risk drivers. It helps visualise where two exchanges have different sources of
                risk.
              </p>
            </>
          }
        >
          <div>
            <HelpIcon />
          </div>
        </Tooltip>
        <StyledDateContainer>
          <StyledDatePicker
            name="select-date"
            format="YYYY-MM-DD"
            minDate={availableDatesRange.start}
            maxDate={availableDatesRange.end}
            value={selectedDate}
            onChange={handleDatePickerChange}
          />
        </StyledDateContainer>
      </StyledRow>
      <StyledRow>
        <StyledVariablesTable>
          <thead>
            <tr>
              <StyledHeaderName>Variable</StyledHeaderName>
              <StyledHeaderName entityCell={true}>{getFullExchangeName(originalEntityName || '')}</StyledHeaderName>
              <StyledHeaderName>
                <Select
                  options={exchangeList.map((e) => ({ label: getFullExchangeName(e), value: e }))}
                  onChange={handleOnChangeExchange}
                  label={'Exchange'}
                  value={selectedExchange}
                  placeholder={'Compare'}
                />
              </StyledHeaderName>
            </tr>
          </thead>
          <tbody>
            {variables.map((v) => (
              <StyledTableRow key={v.name}>
                <td>
                  <VariableName title={v.name} hint={v.hint} />
                </td>
                <td>
                  <VariableValue
                    color="#9388E8"
                    value={
                      //@ts-expect-error this is issue with highcharts
                      values[v.key]
                    }
                  />
                </td>
                <td>
                  <VariableValue
                    color="#F7F7F7"
                    value={
                      //@ts-expect-error this is issue with highcharts
                      v.key && comparedValues && comparedValues[v.key]
                    }
                  />
                </td>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <td>
                <StyledVariableTitle color="#F7F7F7">1-Year Probability of Default</StyledVariableTitle>
              </td>
              <td>
                <VariableValue color="#9388E8" value={entityPD?.value || undefined} addPercentage />
              </td>
              <td>
                <VariableValue color="#F7F7F7" value={comparedEntityPD?.value || undefined} addPercentage />
              </td>
            </StyledTableRow>
          </tbody>
        </StyledVariablesTable>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <StyledTitleRow>
            <div style={{ color: '#B51C1C', fontWeight: 600 }}>Lowest Score</div>
            <div style={{ color: '#E5B302', fontWeight: 600 }}>Average Score</div>
            <div style={{ color: '#4DBA32', fontWeight: 600 }}>Healthiest Score</div>
          </StyledTitleRow>
          <RisksDecompositionChart data={chartData} />
        </div>
      </StyledRow>
    </StyledSmallContainer>
  );
};

export default RisksDecomposition;
