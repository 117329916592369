import { useCallback, useState } from 'react';
import { permissionsInitialState } from '@/constants/InitialState/permissions';
import { EPermissions } from '@/types/permissions';

export const usePermissionsPreferences = () => {
  const [permissions, setPermissions] = useState(permissionsInitialState);

  const getEnabledPermissions = useCallback(
    () => permissions.filter((p) => p.enabled).map((p) => p.value),
    [permissions],
  );

  const updatePermissionsPreferences = useCallback((newPermissions: EPermissions[]) => {
    setPermissions((prev) =>
      prev.map((prevPermission) => {
        if (newPermissions.indexOf(prevPermission.value) > -1) {
          return { ...prevPermission, enabled: true };
        }
        return { ...prevPermission, enabled: false };
      }),
    );
  }, []);

  const clearPermissionsPreferences = useCallback(() => {
    setPermissions(permissionsInitialState);
  }, []);

  const handlePermissionPreferenceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setPermissions(permissions.map((p) => (p.name === name ? { ...p, enabled: checked } : p)));
    },
    [permissions],
  );
  return {
    permissions,
    getEnabledPermissions,
    updatePermissionsPreferences,
    clearPermissionsPreferences,
    handlePermissionPreferenceChange,
  };
};
