import styled from '@emotion/styled';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: 28px;
  font-size: 12px;
  letter-spacing: 0.4em;
  color: #ffffff;
  opacity: 0.5;
`;

export const StyledWrapper = styled.div`
  padding-top: 25px;
  padding-right: 25px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
  height: 50%;
  overflow-y: auto;
`;
