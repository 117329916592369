import { createContext, PropsWithChildren } from 'react';
import { IAlertsFiltersContext } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContext.types';
import { useAlertsFiltersManager } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContext.hooks';

export const AlertsFiltersContext = createContext<Partial<IAlertsFiltersContext>>({});

export const AlertsFiltersProvider = ({ children }: PropsWithChildren) => {
  const {
    page,
    exchangeFilter,
    timeRangeFilter,
    customStartDateFilter,
    customEndDateFilter,
    handlePage,
    setFilterAlertByExchange,
    setFilterAlertByTimeRange,
    setFilterAlertByCustomStartDate,
    setFilterAlertByCustomEndDate,
  } = useAlertsFiltersManager();

  const ALERTS_FILTERS: IAlertsFiltersContext = {
    page,
    exchangeFilter,
    timeRangeFilter,
    customStartDateFilter,
    customEndDateFilter,
    handlePage,
    setFilterAlertByExchange,
    setFilterAlertByTimeRange,
    setFilterAlertByCustomStartDate,
    setFilterAlertByCustomEndDate,
  };

  return <AlertsFiltersContext.Provider value={ALERTS_FILTERS}>{children}</AlertsFiltersContext.Provider>;
};
