import styled from '@emotion/styled';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useAlertsFilters } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContext.hooks';

const WrapperDatePicker = styled.div`
  color: #fff;
  background-color: #222;
  z-index: 2;

  .MuiFormLabel-root,
  .MuiInputBase-input,
  .MuiSvgIcon-root {
    color: #fff;
    z-index: 2;
  }

  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid #5d51b8;
  }
`;

const CustomDatePicker = () => {
  const { customStartDateFilter, customEndDateFilter, setFilterAlertByCustomStartDate, setFilterAlertByCustomEndDate } =
    useAlertsFilters();

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 20;

  const handleStartDateChange = (date: Date | null) => {
    if (!date) return;
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    setFilterAlertByCustomStartDate(formattedDate);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!date) return;
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    setFilterAlertByCustomEndDate(formattedDate);
  };

  const startDateValue = customStartDateFilter ? new Date(customStartDateFilter) : null;
  const endDateValue = customEndDateFilter ? new Date(customEndDateFilter) : null;

  //TO-DO: Find a way to style the date picker not using the sx prop. In order to style it just one.
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <WrapperDatePicker style={{ display: 'flex' }}>
        <DatePicker
          className="date-picker"
          slotProps={{
            field: { clearable: true, onClear: () => setFilterAlertByCustomStartDate('') },
            layout: {
              sx: {
                backgroundColor: '#222',
                color: '#fff',
                '.MuiFormLabel-root.MuiInputLabel-root,.MuiPickersDay-root,.MuiSvgIcon-root,.MuiTypography-root': {
                  color: '#fff',
                },
                '.MuiPickersDay-root:hover, .MuiPickersDay-root.Mui-selected, .MuiPickersYear-yearButton.Mui-selected':
                  {
                    backgroundColor: '#5d51b8',
                  },
                '.MuiPickersDay-dayWithMargin.MuiPickersDay-today ': {
                  border: '1px solid #5d51b8',
                },
              },
            },
          }}
          minDate={new Date(minYear, 0, 1)}
          label="Start Date"
          value={startDateValue}
          onChange={handleStartDateChange}
        />
        <DatePicker
          slotProps={{
            field: { clearable: true, onClear: () => setFilterAlertByCustomEndDate('') },
            layout: {
              sx: {
                backgroundColor: '#222',
                color: '#fff',
                '.MuiPickersDay-root,.MuiSvgIcon-root, .MuiTypography-root': {
                  color: '#fff',
                },
                '.MuiPickersDay-root:hover, .MuiPickersDay-root.Mui-selected, .MuiPickersYear-yearButton.Mui-selected':
                  {
                    backgroundColor: '#5d51b8',
                  },
                '.MuiPickersDay-today': {
                  border: '1px solid #5d51b8',
                },
              },
            },
          }}
          minDate={new Date(minYear, 0, 1)}
          label="End Date"
          value={endDateValue}
          onChange={handleEndDateChange}
        />
      </WrapperDatePicker>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
