import React from 'react';

export const Detail: React.FC<{ label: string; value: string | React.ReactElement }> = ({ label, value }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0',
      alignItems: 'center',
      margin: '16px 0',
    }}
  >
    <span style={{ color: '#FFF', fontSize: '14px', fontWeight: 400, opacity: 0.5 }}>{label}</span>
    <span style={{ color: '#FFF', fontSize: '14px', fontWeight: 400 }}>{value}</span>
  </div>
);
