import React, { useMemo, useState } from 'react';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { useGetEntitiesListByAdmin } from '@/api/adminEntityIds/adminEntityIds.hooks';
import { EntitiesListTable } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/EntitiesListTable/EntitiesListTable';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import { StyledButtonWrapper } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/AdminPanelEntitiesList.styled';
import { CreateNewEntityModal } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityModal/CreateNewEntityModal';

export const AdminPanelEntitiesList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const availablePageSizes = [5, 10, 25];

  const { isLoading, entitiesList, error, fetchNextPage, isFetching, refetch } = useGetEntitiesListByAdmin({ size });

  const totalEntities = useMemo(() => {
    return entitiesList?.pages[0].total || 0;
  }, [entitiesList]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
    fetchNextPage();
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value);
    setPage(0);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (isLoading) return <div style={{ color: 'white' }}>Loading entities list...</div>;
  if (error) return <div style={{ color: 'white' }}>Error getting entities: {error.message}</div>;
  if (!entitiesList) return <div style={{ color: 'white' }}>There is no entities to display</div>;

  return (
    <>
      <EntitiesListTable title="Entities List" entities={entitiesList.pages[page]?.items} />
      <TablePagination
        pageSize={size}
        availablePageSizes={availablePageSizes}
        handlePageSize={handlePageSize}
        currentPage={page}
        handlePageChange={handlePageChange}
        totalDataNumber={totalEntities}
        isFetching={isFetching}
      />
      <StyledButtonWrapper>
        <AdminButton onClick={handleOpenModal}>Create new entity</AdminButton>
      </StyledButtonWrapper>

      <CreateNewEntityModal isOpen={isModalOpen} closeModal={handleCloseModal} refetchListOfEntities={refetch} />
    </>
  );
};
