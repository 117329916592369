import { z, ZodType } from 'zod';
import { ILossCalculatorForm } from '@/views/LossCalculatorView/utils/types';

const rowsSchema = z
  .array(
    z
      .discriminatedUnion('isCustom', [
        z.object({
          entity: z.string().min(1, 'Exchange must be chosen.'),
          pd: z.number(),
          recovery: z.coerce.number().min(1, 'Recovery can have at least 1%.').max(100, 'Recovery can have max 100%.'),
          exposure: z.coerce.number().min(1, 'Exposure can have at least 1USD.'),
          percentageExposure: z.string(),
          isCustom: z.literal(false),
        }),
        z.object({
          entity: z.string().min(3, 'Exchange must have at least 3 characters.'),
          pd: z.number().max(100, 'PD can have max 100%.'),
          recovery: z.coerce.number().min(1, 'Recovery can have at least 1%.').max(100, 'Recovery can have max 100%.'),
          exposure: z.coerce.number().min(1, 'Exposure can have at least 1USD.'),
          percentageExposure: z.string(),
          isCustom: z.literal(true),
        }),
      ])
      .refine(
        (data) => {
          if (data.isCustom) {
            return data.pd > 0;
          }

          return true;
        },
        {
          path: ['pd'],
          message: 'PD must be bigger than 0%.',
        },
      ),
  )
  .min(2, { message: 'At least two exchanges should be added.' });

const correlationSchema = z.number();

const extremeLossProbabilitySchema = z.coerce
  .number({ invalid_type_error: 'Required' })
  .min(1, 'Probability can have at least 1%.')
  .max(100, 'Probability can have max 100%.');

const extremeLossAlphaSchema = z.coerce
  .number({ invalid_type_error: 'Required' })
  .min(1, 'Credit value can have at least 1%.')
  .max(100, 'Credit value can have max 100%.');

export const LossCalculatorSchema: ZodType<ILossCalculatorForm> = z.discriminatedUnion('disabledExtremes', [
  z.object({
    disabledExtremes: z.literal(true),
    rows: rowsSchema,
    correlation: correlationSchema,
  }),
  z.object({
    disabledExtremes: z.literal(false),
    rows: rowsSchema,
    correlation: correlationSchema,
    extremeLossProbability: extremeLossProbabilitySchema,
    extremeLossAlpha: extremeLossAlphaSchema,
  }),
]);
