import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import { EFileKeys, IRow } from '@/views/LossCalculatorView/utils/types';
import { getKeyByLabel } from '@/helpers/exchangeNames';
import { checkRowsAreExcelRowsType } from '@/views/LossCalculatorView/utils/helpers';
import { IStatisticalRatingEntity } from '@/types/ratings';

interface IUseUploadCustomCSVProps {
  ratings: IStatisticalRatingEntity[];
  exchangeList: string[];
  timestamp: string;
  replace: (value: IRow[]) => void;
}

type ExcelRows = { [key in EFileKeys]: string };

export const useUploadCustomCSV = ({ ratings, exchangeList, timestamp, replace }: IUseUploadCustomCSVProps) => {
  const [file, setFile] = useState<File>();
  const [excelRows, setExcelRows] = useState<ExcelRows[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileReader = new FileReader();

  const handleUploadExposureButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      setFile(e.target.files[0]);
    }
  };

  const clearFileInput = useCallback(() => {
    setExcelRows([]);
    setFile(undefined);
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  }, []);

  const parseCurrency = (currencyStr: string): number => {
    const numericPart = currencyStr.replace(/[^0-9.-]+/g, '');
    return parseFloat(numericPart);
  };

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        if (event.target !== null) {
          const csvOutput = event.target.result;
          if (typeof csvOutput === 'string') {
            const parsedCSV = Papa.parse(csvOutput, { header: true });
            const rows = parsedCSV.data;
            if (checkRowsAreExcelRowsType(rows)) {
              return setExcelRows(rows as ExcelRows[]);
            }

            console.error('Excel Headers are incorrect');
            clearFileInput();
          }
        }
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  useEffect(() => {
    if (excelRows.length > 0) {
      const clearedExcelRows = [...excelRows].filter((obj) => {
        const values = Object.values(obj);
        return values.some((val) => val);
      });

      const mappedRows: IRow[] = clearedExcelRows.map((obj) => {
        const entity = getKeyByLabel(obj[EFileKeys.ENTITY])
          ? getKeyByLabel(obj[EFileKeys.ENTITY])
          : obj[EFileKeys.ENTITY];

        const pd = obj[EFileKeys.PD].replace(/,/g, '.').replace('%', '');
        const recovery = obj[EFileKeys.RECOVERY].replace(/,/g, '.').replace('%', '');
        const exposure = parseCurrency(obj[EFileKeys.EXPOSURE]);

        const isExistingExchange = exchangeList.find((exchange) => exchange === entity);

        if (isExistingExchange) {
          const rating = ratings.find((rating) => rating.entity === entity);

          if (rating) {
            const findPD = rating.data.find((d) => timestamp === d.timestamp.split('T')[0]);

            return {
              entity,
              pd: findPD?.value || +pd,
              recovery: +recovery,
              exposure: +exposure,
              isCustom: false,
              percentageExposure: '',
            };
          }
        }

        return {
          entity,
          pd: +pd,
          recovery: +recovery,
          exposure: +exposure,
          isCustom: true,
          percentageExposure: '',
        };
      });

      replace(mappedRows);
      clearFileInput();
    }
  }, [excelRows]);

  return {
    fileInputRef,
    handleFileInputChange,
    handleUploadExposureButtonClick,
  };
};
