import styled from '@emotion/styled';
import { useAnomaliesManager } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/useAnomaliesManager';
import { useAlertsManager } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/useAlertsManager';
import MultiSelect from '@/components/molecules/MultiSelect/MultiSelect';
import { AnomaliesList } from '@/components/molecules/AdminPanelWrapperAlertsList/AnomaliesList';
import { AlertsList } from '@/components/molecules/AdminPanelWrapperAlertsList/AlertsList';

const WrapperSelect = styled.div`
  width: 350px;
  height: 56px;
  margin-left: 10px;
`;

const AdminPanelWrapperAlertsList = () => {
  const {
    exchangeList,
    exchangeFilter,
    pageInReview,
    inReviewAnomaliesSize,
    anomaliesList,
    isLoadingAnomaliesList,
    anomaliesListError,
    totalInReviewAnomalies,
    handleInReviewAnomaliesPageChange,
    handleInReviewAnomaliesPageSize,
    setFilterAnomalyByExchange,
  } = useAnomaliesManager();

  const {
    pagePublished,
    publishedAlertsSize,
    alertsList,
    isLoadingAlertsList,
    alertsListError,
    totalPublishedAlerts,
    handlePublishedAlertsPageChange,
    handlePublishedAlertsPageSize,
  } = useAlertsManager();

  return (
    <>
      {exchangeList.length > 0 && (
        <WrapperSelect>
          <MultiSelect
            options={exchangeList.map((e) => e.label)}
            onChange={setFilterAnomalyByExchange}
            label="Exchange"
            value={exchangeFilter}
          />
        </WrapperSelect>
      )}
      <AnomaliesList
        anomaliesList={anomaliesList?.items || []}
        inReviewAnomaliesSize={inReviewAnomaliesSize}
        handleInReviewAnomaliesPageSize={handleInReviewAnomaliesPageSize}
        pageInReview={pageInReview}
        handleInReviewAnomaliesPageChange={handleInReviewAnomaliesPageChange}
        totalInReviewAnomalies={totalInReviewAnomalies}
        isLoadingAnomaliesList={isLoadingAnomaliesList}
        anomaliesListError={anomaliesListError}
      />

      <AlertsList
        alertsList={alertsList?.items || []}
        publishedAlertsSize={publishedAlertsSize}
        handlePublishedAlertsPageSize={handlePublishedAlertsPageSize}
        pagePublished={pagePublished}
        handlePublishedAlertsPageChange={handlePublishedAlertsPageChange}
        totalPublishedAlerts={totalPublishedAlerts}
        alertsListError={alertsListError}
        isLoadingAlertsList={isLoadingAlertsList}
      />
    </>
  );
};

export default AdminPanelWrapperAlertsList;
