import styled from '@emotion/styled';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import Text from '@/components/atoms/Text/Text';

const Container = styled.div`
  padding: 30px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
`;

const WrapperButton = styled.div`
  width: 253px;
  height: 30px;
  margin: 40px 0;
`;

interface IActionAnomalyButtonsProps {
  handleSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancel: () => void;
  isReviewed: boolean;
}

const ActionAnomalyButtons = ({ handleSave, handleCancel, isReviewed }: IActionAnomalyButtonsProps) => (
  <Container>
    <Text fontSize="20px">Actions</Text>
    <ButtonsContainer>
      <WrapperButton>
        <AdminButton onClick={handleSave} disabled={isReviewed}>
          Publish Alert
        </AdminButton>
      </WrapperButton>
      <WrapperButton>
        <AdminButton onClick={handleCancel}>Cancel</AdminButton>
      </WrapperButton>
    </ButtonsContainer>
  </Container>
);

export default ActionAnomalyButtons;
