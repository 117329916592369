import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import img from '@/assets/png/noPermission.png';

const ratingsBackground = css`
  background-image: url(${img});
  background-repeat: no-repeat;
`;

const plainBackground = css`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px);
  border: 1px solid #000000;
`;

export const StyledWrapper = styled.div<{ isRatings: boolean }>`
  box-sizing: border-box; // temporary -> added globally destroy the whole layout;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  height: 100%;

  ${({ isRatings }) => (isRatings ? ratingsBackground : plainBackground)}
`;

export const StyledTypography = styled(Typography)`
  max-width: 652px;
  font-family: 'Spline Sans', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;
