import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { DOCUMENTATION_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IGetDocumentationResponse } from '@/api/documentation/documentation.types';

export const useGetDocumentation = () => {
  const { documentationApi } = useApi();

  const { mutate: fetchDocumentation } = useMutation<IGetDocumentationResponse, Error, void>({
    mutationKey: [DOCUMENTATION_KEY],
    mutationFn: () => {
      return documentationApi.getDocumentation().catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`fetch documentation`));
        }
      });
    },
  });

  return {
    fetchDocumentation,
  };
};
