import { AuthApi } from '@/api/auth/auth.api';
import { ILogInRequest, ILogInResponse, TUseLogInUserOptions } from '@/api/auth/auth.types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AUTH } from '@/api/constants/queryKeys';

export const useLogInUser = (authApi: AuthApi, options?: TUseLogInUserOptions) => {
  const { error, mutate: logInUser } = useMutation<ILogInResponse, AxiosError, ILogInRequest>({
    mutationKey: [AUTH],
    mutationFn: (logInCredentials: ILogInRequest) => {
      return authApi.logInUser(logInCredentials).catch((error) => {
        if (error.response?.status === 401) {
          const { detail } = error.response?.data as Record<string, string>;
          throw new Error(detail);
        }
        throw new Error(
          'There was an unexpected issue. Our engineering team is already notified but you can also contact us at contact@agioratings.io.',
        );
      });
    },
    onSuccess: async (...params) => {
      options?.onSuccess?.(...params);
    },
    onError: async (...params) => {
      options?.onError?.(...params);
    },
  });

  return {
    error,
    logInUser,
  };
};
