import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IAlertsApi, IGetAlertsListRequest, IGetAlertsListResponse } from '@/api/alerts/alerts.types';
import { IExtendedAlertDto } from '@/types/alert';
import { IEntity } from '@/types/entities';

export class AlertsApi implements IAlertsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ALERTS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getAlertById(alertId: string) {
    return this.httpClient.get<IExtendedAlertDto>(`${this.getBaseUrl()}${alertId}/`);
  }

  getAlertsList({ page, size, sort, direction, exchange, endDatetime, startDatetime }: Partial<IGetAlertsListRequest>) {
    const params = {
      page,
      size,
      sort,
      direction,
      exchange,
      endDatetime,
      startDatetime,
    };

    return this.httpClient.get<IGetAlertsListResponse>(this.getBaseUrl(), { params });
  }

  getUniqueEntitiesList() {
    return this.httpClient.get<IEntity[]>(`${this.getBaseUrl()}entity`);
  }
}
