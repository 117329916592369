import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { StyledModalAsBanner, StyledOverlay } from '@/components/Modal/Modal.styled';

interface IModalAsBannerProps {
  isOpen: boolean;
}

const ModalAsBanner = ({ isOpen, children }: PropsWithChildren<IModalAsBannerProps>) => {
  if (!isOpen) return null;

  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot) return null;

  return ReactDOM.createPortal(
    <StyledOverlay>
      <StyledModalAsBanner>{children}</StyledModalAsBanner>
    </StyledOverlay>,
    modalRoot,
  );
};

export default ModalAsBanner;
