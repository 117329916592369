import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ControlledBaseTextField } from '@/components/atoms/BaseTextField/BaseTextField';
import BasePanel from '@/components/atoms/BasePanel/BasePanel';
import { IChangePasswordForm } from '@/components/organims/ChangePasswordForm/utils/types';
import { StyledContainer, StyledWrapper } from '@/components/molecules/ChangePasswordPanel/ChangePasswordPanel.styled';

interface ChangePasswordPanelProps {
  formMethods: UseFormReturn<IChangePasswordForm>;
}

const ChangePasswordPanel: React.FC<ChangePasswordPanelProps> = ({ formMethods }) => {
  return (
    <BasePanel title="Change Password">
      <StyledContainer>
        <StyledWrapper>
          <ControlledBaseTextField
            label="Current Password"
            inputName="currentPass"
            formMethods={formMethods}
            type="password"
          />
        </StyledWrapper>
        <StyledWrapper>
          <ControlledBaseTextField label="New Password" inputName="newPass" formMethods={formMethods} type="password" />
        </StyledWrapper>
        <StyledWrapper>
          <ControlledBaseTextField
            label="Repeat New Password"
            inputName="repeatNewPass"
            formMethods={formMethods}
            type="password"
          />
        </StyledWrapper>
      </StyledContainer>
    </BasePanel>
  );
};

export default ChangePasswordPanel;
