import { useMemo, useState } from 'react';
import { getKeyByLabel } from '@/helpers/exchangeNames';
import { PAGE_SIZE } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/constants';
import { useGetAnomaliesList } from '@/api/adminAnomalies/adminAnomalies.hooks';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { IGetAnomaliesListRequest } from '@/api/adminAnomalies/adminAnomalies.types';

export const useAnomaliesManager = () => {
  const [pageInReview, setPageInReview] = useState(1);
  const [exchangeFilter, setExchangeFilter] = useState<string[]>([]);
  const [inReviewAnomaliesSize, setInReviewAnomaliesSize] = useState(PAGE_SIZE);
  const { exchangesDisplayedToAdmin } = useEntitiesList();

  const adminAnomaliesListRequestPayload: Partial<IGetAnomaliesListRequest> = useMemo(() => {
    return {
      page: pageInReview,
      size: inReviewAnomaliesSize,
      ...(exchangeFilter.length > 0 ? { exchange: exchangeFilter.join(',') } : {}),
    };
  }, [exchangeFilter, inReviewAnomaliesSize, pageInReview]);

  const {
    anomaliesList,
    isLoading: isLoadingAnomaliesList,
    error: anomaliesListError,
  } = useGetAnomaliesList(adminAnomaliesListRequestPayload);

  const totalInReviewAnomalies = useMemo(() => {
    return anomaliesList?.total || 0;
  }, [anomaliesList]);

  const handleInReviewAnomaliesPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPageInReview(newPage + 1);
  };

  const handleInReviewAnomaliesPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInReviewAnomaliesSize(+event.target.value);
    setPageInReview(1);
  };

  const setFilterAnomalyByExchange = (newExchanges: string[]) => {
    if (pageInReview !== 1) {
      setPageInReview(1);
    }

    newExchanges = newExchanges.map((e) => getKeyByLabel(e));

    setExchangeFilter(newExchanges);
  };

  return {
    exchangeList: exchangesDisplayedToAdmin,
    exchangeFilter,
    pageInReview,
    inReviewAnomaliesSize,
    anomaliesList,
    isLoadingAnomaliesList,
    anomaliesListError,
    totalInReviewAnomalies,
    handleInReviewAnomaliesPageChange,
    handleInReviewAnomaliesPageSize,
    setFilterAnomalyByExchange,
  };
};
