import { AxiosError } from 'axios';
import { useInfiniteQuery, useQuery, useMutation } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { ADMIN_ENTITY_IDS_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import {
  IEditEntityRequest,
  IGetEntitiesListRequest,
  IGetEntitiesListResponse,
} from '@/api/adminEntityIds/adminEntityIds.types';
import { IEntityId } from '@/types/entities';
import { handleCatch } from '@/api/helpers';

export const useGetEntitiesListByAdmin = ({ size }: Partial<IGetEntitiesListRequest>) => {
  const { adminEntityIds } = useApi();

  const {
    data: entitiesList,
    error,
    isLoading,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery<IGetEntitiesListResponse, Error>({
    queryKey: [ADMIN_ENTITY_IDS_KEY, 'GET_ENTITIES_LIST', `size_${size}`],
    queryFn: ({ pageParam }) => {
      if (typeof pageParam === 'number') {
        return adminEntityIds.getEntitiesList(pageParam, { size: size }).catch((error) => {
          if (error instanceof AxiosError && error.response) {
            const errorMessage = error.response.data.detail || error.response.data.detail[0].msg;
            throw new Error(errorMessage);
          } else {
            return Promise.reject(new UnknownErrorAndWeCouldNot(`load the entities list`));
          }
        });
      } else {
        throw new Error('pageParam must be a number.');
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (typeof lastPageParam === 'number' && lastPage.pages && lastPage.page) {
        return lastPageParam >= lastPage.pages ? undefined : lastPage.page + 1;
      }
      return undefined;
    },
  });

  return {
    entitiesList,
    error,
    isLoading,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetching,
  };
};

export const useGetEntityById = (entityId: string) => {
  const { adminEntityIds } = useApi();

  const {
    data: fetchedEntity,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IEntityId, Error>({
    queryKey: [ADMIN_ENTITY_IDS_KEY, 'GET_ENTITY', entityId],
    meta: { entityId: entityId },
    queryFn: ({ meta }) => {
      if (meta && meta.entityId && typeof meta.entityId === 'string') {
        return adminEntityIds.getEntityById(meta.entityId).catch((reason) => {
          if (reason instanceof AxiosError && reason.response) {
            const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
            throw new Error(errorMessage);
          } else {
            return Promise.reject(new UnknownErrorAndWeCouldNot(`load the entity details`));
          }
        });
      }
      throw new Error('entityId should be a meta string');
    },
  });
  return {
    fetchedEntity,
    isLoading,
    isSuccess,
    error,
  };
};

export const useCreateEntity = () => {
  const { adminEntityIds } = useApi();

  const { error, mutate: createEntity } = useMutation<IEntityId, Error, Partial<Omit<IEntityId, 'id'>>>({
    mutationKey: [ADMIN_ENTITY_IDS_KEY, 'CREATE_ENTITY'],
    mutationFn: (payload: Partial<Omit<IEntityId, 'id'>>) => {
      return adminEntityIds.createEntity(payload).catch((reason) => {
        return handleCatch(reason, `create new entity`);
      });
    },
  });

  return {
    error,
    createEntity,
  };
};

export const useEditEntity = () => {
  const { adminEntityIds } = useApi();

  const { error, mutate: editEntity } = useMutation<IEntityId, Error, IEditEntityRequest>({
    mutationKey: [ADMIN_ENTITY_IDS_KEY, 'EDIT_ENTITY'],
    mutationFn: (payload: IEditEntityRequest) => {
      return adminEntityIds.editEntity(payload).catch((reason) => {
        return handleCatch(reason, `edit requested entity`);
      });
    },
  });

  return {
    error,
    editEntity,
  };
};
