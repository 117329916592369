import { Typography } from '@mui/material';
import HelpIcon from '@/assets/icons/help.svg';
import mapChainData from '@/helpers/mapChainData';
import TimeLineChart from '@/components/molecules/Charts/TimeLineChart';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
  StyledHeader,
  StyledWrapper,
} from '@/views/RatingsView/components/IndustryAverageTimeseries/IndustryAverageTimeseries.styled';
import { IRatingsViewProps } from '@/views/RatingsView/RatingsView';

export const IndustryAverageTimeseries = ({ industryAverage }: Pick<IRatingsViewProps, 'industryAverage'>) => {
  if (!industryAverage.length) {
    return (
      <Typography color="white.100" align="center">
        No data to display the industry average time series chart
      </Typography>
    );
  }

  const timeSeriesData = mapChainData(industryAverage);

  return (
    <StyledWrapper>
      <StyledHeader>
        INDUSTRY AVERAGE TIMESERIES
        <Tooltip
          arrow
          title="Time series of the mean 1-YR PD. The data is averaged over all the centralized exchanges that Agio Ratings publishes a Statistical Ratings for."
        >
          <img src={HelpIcon} alt="Help icon" />
        </Tooltip>
      </StyledHeader>
      <TimeLineChart timeSeriesData={timeSeriesData} />
    </StyledWrapper>
  );
};
