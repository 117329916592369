import styled from '@emotion/styled';
import { GridRowParams, GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import ChevronRight from '@mui/icons-material/ChevronRight';
import AlertIcon from '@/assets/icons/alert.svg';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { formatDateToUK, formatToUSD } from '@/helpers/helpers';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { getZScoreColors } from '@/helpers/zScoreMethods';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { StyledDataGrid, StyledWrapper, StyledZScore } from './StyledComponents';
import { IAlertDto } from '@/types/alert';

interface DataGridProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
  onRowSelected: (id: string) => void;
  data: IAlertDto[];
  customFooter?: () => React.ReactNode;
}

interface MyGridCellParams extends GridCellParams {
  row: IAlertDto;
  value: number;
}

const Container = styled.div`
  color: #fff;
  margin: 20px;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const CollapsibleDataGrid = ({ isCollapsed, toggleCollapse, onRowSelected, data }: DataGridProps) => {
  const handleRowClick = (params: GridRowParams) => {
    onRowSelected(params.row.id);
  };

  const getRowClassName = (params: GridRowParams) => {
    return `Mui-DataGrid-row ${params.row.id === '1' ? 'first-row' : ''}`;
  };

  const ExpandButtonHeader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#242427',
        borderRadius: '50%',
        padding: '3px',
      }}
    >
      <ChevronRight onClick={toggleCollapse} style={{ cursor: 'pointer' }} />
    </div>
  );

  const columnsWithExpand: any = !isCollapsed
    ? [
        {
          field: 'exchange',
          headerName: 'Company',
          width: 430,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div style={{ display: 'flex', gap: '16px', marginLeft: '20px' }}>
                <ExchangeLogo companyName={params.row.metadata.exchange} height="20px" />
                {getFullExchangeName(params.row.metadata.exchange)}
              </div>
            );
          },
        },
        {
          field: 'timestamp',
          headerName: 'Date',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{formatDateToUK(params.row.timestamp)}</div>;
          },
        },
        {
          field: 'zScore',
          headerName: 'Z-Score',
          width: 150,
          sortable: false,
          renderCell: (params: MyGridCellParams) => {
            const generatedColor = getZScoreColors(params.row.severity);

            return <StyledZScore color={generatedColor.color}>{params.value?.toFixed(2)}</StyledZScore>;
          },
        },
        {
          field: 'severity',
          headerName: 'Severity',
          width: 150,
          sortable: false,
          renderCell: (params: MyGridCellParams) => {
            const severity = params.value?.toString();
            const isHighSeverity = severity === 'high';

            const severityColorClass = isHighSeverity ? 'high-severity' : 'low-severity';
            return (
              <StyledWrapper className={severityColorClass}>
                {severity}
                {isHighSeverity && <img src={AlertIcon} alt="Alert icon" />}
              </StyledWrapper>
            );
          },
        },
        {
          field: 'metadata',
          headerName: 'Entity type',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{params.value.entityType?.toUpperCase()}</div>;
          },
        },
        {
          field: 'type',
          headerName: 'Variable',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{mapMetadataType(params.row.metadata.type)}</div>;
          },
        },
        {
          field: 'changePercentage',
          headerName: '%Change',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const changePercentage = params.row.changePercentage ? `${params.row.changePercentage.toFixed(2)}%` : '';
            return <div>{changePercentage}</div>;
          },
        },
        {
          field: 'changeAmount',
          headerName: 'Change Amount',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const changeAmount = params.row.changeAmount ? formatToUSD(params.row.changeAmount) : '';
            return <div>{changeAmount}</div>;
          },
        },
      ]
    : [
        {
          field: 'exchange',
          headerName: 'Company',
          width: 150,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div style={{ display: 'flex', gap: '16px', marginLeft: '20px' }}>
                <ExchangeLogo companyName={params.row.metadata.exchange} height="20px" />
                {getFullExchangeName(params.row.metadata.exchange)}
              </div>
            );
          },
        },
        {
          field: 'timestamp',
          headerName: 'Date',
          width: 100,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{formatDateToUK(params.row.timestamp)}</div>;
          },
        },
        {
          field: 'zScore',
          headerName: 'Z-Score',
          width: 100,
          sortable: false,
          renderCell: (params: MyGridCellParams) => {
            const generatedColor = getZScoreColors(params.row.severity);

            return <StyledZScore color={generatedColor.color}>{params.value?.toFixed(2)}</StyledZScore>;
          },
        },
        {
          field: 'expand',
          headerName: 'Expand',
          width: 100,
          sortable: false,
          headerClassName: 'custom-header',
          renderHeader: () => (isCollapsed ? <ExpandButtonHeader /> : null),
        },
      ];

  const getRowId = (row: any) => row.id;

  return (
    <>
      {data?.length > 0 ? (
        <StyledDataGrid
          style={{ color: '#fff' }}
          rows={data}
          getRowId={getRowId}
          columns={columnsWithExpand}
          onRowClick={handleRowClick}
          disableColumnMenu
          isCollapsed={isCollapsed}
          getRowClassName={getRowClassName}
          hideFooter
        />
      ) : (
        <Container>No alerts matching the filters</Container>
      )}
    </>
  );
};

export default CollapsibleDataGrid;
