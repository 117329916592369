import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import ActionsButtons from '@/components/molecules/ActionsButtons/ActionsButtons';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import Loader from '@/components/atoms/Loader/Loader';
import Select from '@/components/molecules/Select/Select';
import Text from '@/components/atoms/Text/Text';
import { EPermissions } from '@/types/permissions';
import {
  useDeleteUserAsAdmin,
  useEditUserAsAdmin,
  useGetUserById,
  useResetUserPasswordAsAdmin,
} from '@/api/adminUser/adminUser.hooks';
import { useNotificationsPreferences } from '@/hooks/useNotificationsPreferences';
import { usePermissionsPreferences } from '@/hooks/usePermissionsPreferences';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 47px;
  margin-top: 20px;
  margin-bottom: 45px;
`;

const WrapperInput = styled.div`
  width: 253px;
  margin-top: 15px;
  border: 1px solid #fff;
`;

const StyledSelect = styled(Select)`
  width: 253px;
  margin-top: 15px;
`;

const DetailsContainer = styled.div`
  display: wid;
`;

const WrapperButton = styled.div`
  margin: 40px 0px;
`;

const AdminPanelUserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [uiError, setUiError] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState('');
  const { notifications, updateNotificationsPreferences, handleNotificationPreferenceChange } =
    useNotificationsPreferences();
  const { permissions, getEnabledPermissions, updatePermissionsPreferences, handlePermissionPreferenceChange } =
    usePermissionsPreferences();

  const navigate = useNavigate();
  const { editUser } = useEditUserAsAdmin();
  const { fetchedUser, error, isLoading, isSuccess } = useGetUserById({ id: id || '' });
  const { deleteUser } = useDeleteUserAsAdmin();
  const { resetUserPassword } = useResetUserPasswordAsAdmin();

  useEffect(() => {
    if (!fetchedUser || !isSuccess) return;

    setName(fetchedUser.firstName);
    setLastname(fetchedUser.secondName);
    setEmail(fetchedUser.emailAddress);
    setDisabled(fetchedUser.disabled ? 'Suspended' : 'Active');
    const initialNotifications = notifications;
    for (const notification of initialNotifications) {
      fetchedUser.notifications.forEach((n: { name: string; enabled: boolean }) => {
        if (n.name === notification.name) {
          notification.enabled = n.enabled;
        }
      });
    }

    updateNotificationsPreferences(initialNotifications);

    const fetchedPermissions: EPermissions[] = fetchedUser.permissions;
    updatePermissionsPreferences(fetchedPermissions);
  }, [fetchedUser, isSuccess]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Typography color="white.100">{error.message}</Typography>;
  }

  if (!fetchedUser) {
    return <Typography color="white.100">No found user data.</Typography>;
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const payloadToSend = {
      id: id || '',
      firstName: name,
      secondName: lastname,
      emailAddress: email,
      disabled: disabled !== 'Active' ? true : false,
      notifications: notifications.map((n) => ({ name: n.name, enabled: n.enabled })),
      permissions: getEnabledPermissions(),
    };

    editUser(payloadToSend, {
      onSuccess: () => navigate('/admin/users'),
      onError: (error) => {
        setUiError(error.message);
      },
    });
  };

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteUser(
      { id: id || '' },
      {
        onSuccess: () => {
          navigate('/admin/users');
        },
        onError: (error) => {
          setUiError(error.message);
          console.error('Error occurred: ', error.message);
        },
      },
    );
  };

  const handleCancel = () => navigate(`/admin/users`);

  const handleResetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resetUserPassword(
      { id: id || '' },
      {
        onSuccess: () => {
          navigate('/admin/users');
        },
        onError: (error) => {
          setUiError(error.message);
          console.error('Error occurred: ', error.message);
        },
      },
    );
  };

  const selectOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Suspended', label: 'Suspended' },
  ];

  return (
    <DetailsContainer>
      <Text fontSize="20px">User Details</Text>
      <form>
        <Container>
          <div>
            <Text fontSize="14px">Name</Text>
            <WrapperInput>
              <BaseTextField borderColor="#fff" value={name} onChange={(e) => setName(e.target.value as string)} />
            </WrapperInput>
          </div>
          <div>
            <Text fontSize="14px">LastName</Text>
            <WrapperInput>
              <BaseTextField borderColor="#fff" value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </WrapperInput>
          </div>
          <div>
            <Text fontSize="14px">Email</Text>
            <WrapperInput>
              <BaseTextField borderColor="#fff" value={email} onChange={(e) => setEmail(e.target.value)} />
            </WrapperInput>
          </div>
          <div>
            <Text fontSize="14px">Status</Text>
            <WrapperInput>
              <StyledSelect options={selectOptions} onChange={(value: string) => setDisabled(value)} value={disabled} />
            </WrapperInput>
          </div>
          <div>
            {notifications.map((notification) => (
              <div key={notification.name}>
                <FormControlLabel
                  sx={{ color: '#fff', height: '20px', display: 'flex', alignItems: 'inherit', margin: '10px 0 0 0' }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#fff',
                        '&.Mui-checked': {
                          color: '#5d51b8',
                        },
                      }}
                      checked={notification.enabled}
                      onChange={handleNotificationPreferenceChange}
                      name={notification.name}
                    />
                  }
                  label={`Subscribe to ${notification.name}`}
                />
              </div>
            ))}
          </div>
          <div>
            {permissions.map((permission) => (
              <FormControlLabel
                key={permission.name}
                sx={{ color: '#fff', height: '20px', display: 'flex', alignItems: 'inherit', margin: '10px 0 0 0' }}
                control={
                  <Checkbox
                    sx={{
                      color: '#fff',
                      '&.Mui-checked': {
                        color: '#5d51b8',
                      },
                    }}
                    checked={permission.enabled}
                    onChange={handlePermissionPreferenceChange}
                    name={permission.name}
                  />
                }
                label={`Permission to ${permission.name}`}
              />
            ))}
          </div>
          <div>
            <Text fontSize="14px">Last login: </Text>
            <Text fontSize="14px">
              {fetchedUser.lastLogin ? format(parseISO(fetchedUser.lastLogin), 'yyyy-MM-dd HH:MM:ss') : 'No data'}
            </Text>
          </div>
        </Container>
        {!!uiError && <div style={{ color: 'red' }}>{uiError}</div>}
        <WrapperButton>
          <ActionsButtons
            handleSave={handleSubmit}
            handleDelete={handleDelete}
            handleCancel={handleCancel}
            handleResetPassword={handleResetPassword}
          />
        </WrapperButton>
      </form>
    </DetailsContainer>
  );
};

export default AdminPanelUserDetails;
