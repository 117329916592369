import React from 'react';
import { EntityRatingsView } from '@/views/EntityRatingsView/EntityRatingsView';
import Loader from '@/components/atoms/Loader/Loader';
import { Container } from './EntityRatings.styled';
import { useGetRatingsList } from '@/api/ratings/ratings.hooks';

export const EntityRatings = () => {
  const { ratingsList, isLoading, error } = useGetRatingsList();

  if (isLoading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (error) {
    throw error;
  }

  if (!ratingsList || (ratingsList && !ratingsList.data.length)) {
    throw Error('Not found ratings data');
  }

  return <EntityRatingsView fetchedRatings={ratingsList.data} />;
};
