import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import CreateUpdateModal from '@/components/organims/CreateUpdateModal/CreateUpdateModal';

import { ChartPoint } from '@/types/chartPoint';
import { IExtendedAlertUpdateDto } from '@/types/alert';

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  min-height: 250px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Table = styled.table`
  width: 100%;
  min-height: 50px;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
  }
`;

const TableHeaderCell = styled.th`
  background-color: #58585b;
  color: white;
`;

const TableBodyCell = styled.td`
  background-color: #242428;
  color: white;
  text-align: center;
`;

const TableBody = styled.tbody`
  border-color: black;
`;

const WrapperButton = styled.div`
  width: 253px;
  height: 30px;
  margin: 40px 0px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface UserListProps {
  updates: IExtendedAlertUpdateDto[];
  alertID: string | undefined;
  anomalyChainData: ChartPoint;
}

const AdminPanelUpdatesList: React.FC<UserListProps> = ({ alertID, updates, anomalyChainData }) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const navigate = useNavigate();
  const id = alertID;

  const handleManageClick = (updateID: string) => {
    navigate(`/admin/alerts/${id}/updates/${updateID}`);
  };

  const toggleCreateUpdateModal = (e: any) => {
    e.preventDefault();
    setCreateModalOpen((prev) => !prev);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>Updates</Title>
      </HeaderContainer>
      <WrapperButton>
        <AdminButton onClick={toggleCreateUpdateModal}>Add Update</AdminButton>
      </WrapperButton>
      <Table>
        <thead>
          <tr>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Title</TableHeaderCell>
            <TableHeaderCell>Chart Data Published</TableHeaderCell>
            <TableHeaderCell>Action</TableHeaderCell>
          </tr>
        </thead>
        <TableBody>
          {updates.map((update, index) => (
            <tr key={index}>
              <TableBodyCell>{update.id}</TableBodyCell>
              <TableBodyCell>{update.title}</TableBodyCell>
              {update.showChart ? (
                <TableBodyCell>Not Published</TableBodyCell>
              ) : (
                <TableBodyCell>Published</TableBodyCell>
              )}
              <TableBodyCell style={{ cursor: 'pointer' }} onClick={() => handleManageClick(update.id || '')}>
                {'Manage'}
              </TableBodyCell>
            </tr>
          ))}
        </TableBody>
      </Table>
      <CreateUpdateModal
        isOpen={isCreateModalOpen}
        onClose={toggleCreateUpdateModal}
        alertId={id}
        anomalyChainData={anomalyChainData}
      />
    </Container>
  );
};

export default AdminPanelUpdatesList;
