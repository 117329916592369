import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { AlertsView } from '@/views/AlertsView/AlertsView';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';

const AlertsPage = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();

  if (!shouldDisplayAlerts) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <AlertsView />
    </ErrorBoundary>
  );
};

export default AlertsPage;
