import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

const trackUsers = import.meta.env.PROD;

if (trackUsers) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECTID);
  posthog.init(import.meta.env.VITE_POSTHOG_PROJECTID, { api_host: 'https://us.i.posthog.com' });
}

export const useMixpanelTracking = () => {
  const mixpanelReset = () => {
    if (trackUsers) {
      mixpanel.track('User Logout', {
        Time: new Date().toISOString(),
      });
      posthog.capture('User Logout', {
        Time: new Date().toISOString(),
      });
      mixpanel.reset();
      posthog.reset();
    }
  };

  const identifyUser = (user: Record<string, any>) => {
    if (trackUsers) {
      const { id, ...rest } = user;
      mixpanel.identify(id);
      mixpanel.people.set(rest);
      posthog.identify(id);
      posthog.setPersonProperties(rest);
    }
  };

  const trackEvent = (eventName: string, eventProperties: Record<string, any>) => {
    if (trackUsers) {
      mixpanel.track(eventName, eventProperties);
      posthog.capture(eventName, eventProperties);
    }
  };

  const trackLogin = (userEmail: string, userPermissions: string) => {
    if (trackUsers) {
      mixpanel.track('User Login Info', {
        Time: new Date().toISOString(),
        UserEmail: userEmail,
        Permissions: userPermissions,
      });
      posthog.capture('User Login Info', {
        Time: new Date().toISOString(),
        UserEmail: userEmail,
        Permissions: userPermissions,
      });
    }
  };

  const trackPageView = (page: string) => {
    if (trackUsers) {
      mixpanel.track(`Page Viewed: ${page}`, {
        page,
      });
      posthog.capture(`Page Viewed: ${page}`, {
        page,
      });
    }
  };

  const trackFilterUsage = (filterType: string, filterValue: string | string[]) => {
    if (import.meta.env.PROD) {
      mixpanel.track(`Filter Applied: ${filterType}`, {
        'Filter Type': filterType,
        'Filter Value': filterValue,
      });
      posthog.capture(`Filter Applied: ${filterType}`, {
        'Filter Type': filterType,
        'Filter Value': filterValue,
      });
    }
  };

  return { trackLogin, trackPageView, trackFilterUsage, identifyUser, mixpanelReset, trackEvent };
};
