import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Typography } from '@mui/material';
import Button from '@/components/atoms/Button/Button';
import { useChangePasswordForm } from '@/components/organims/ChangePasswordForm/utils/useChangePasswordForm';
import ChangePasswordPanel from '@/components/molecules/ChangePasswordPanel/ChangePasswordPanel';
import {
  StyledButtonContainer,
  StyledForm,
  StyledPanelsContainer,
  StyledPanelWrapper,
} from '@/components/organims/ChangePasswordForm/ChangePasswordForm.styled';

export const ChangePasswordForm = () => {
  const {
    changePasswordMethods,
    changePasswordNotificationMessage,
    handleChangePasswordFormSubmit,
    isEditUserPasswordLoading,
  } = useChangePasswordForm();

  return (
    <FormProvider {...changePasswordMethods}>
      <StyledForm onSubmit={handleChangePasswordFormSubmit}>
        <StyledPanelsContainer>
          <StyledPanelWrapper>
            <ChangePasswordPanel formMethods={changePasswordMethods} />
          </StyledPanelWrapper>
        </StyledPanelsContainer>

        {changePasswordNotificationMessage && (
          <Typography color={changePasswordNotificationMessage.type === 'success' ? 'green' : 'red'}>
            {changePasswordNotificationMessage.text}
          </Typography>
        )}

        <StyledButtonContainer>
          <Button
            type="submit"
            variant="contained"
            fitContentWidth
            sx={{ minWidth: '142px' }}
            disabled={isEditUserPasswordLoading}
          >
            Save password
          </Button>
        </StyledButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};
