export enum ApiEndpoints {
  ADMIN_USER = '/admin/user/',
  ADMIN_ALERTS = '/admin/alerts/',
  ADMIN_ANOMALIES = '/admin/anomalies/',
  ADMIN_ENTITY_IDS = '/admin/entity_ids/',
  ADMIN_RATINGS = '/admin/ratings/',
  ADMIN_RISKS = '/admin/risks/',
  AUTH_USER = '/auth/token/',
  USER_BASE_URL = '/user',
  ALERTS = '/alerts/',
  CALCULATORS = '/loss_calculator',
  RISKS = '/risks/',
  RATINGS = '/ratings/',
  DOCUMENTATION = '/documentation/',
}
