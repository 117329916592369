import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };
  return (
    <Container>
      {['alerts', 'users', 'entities', 'analytics', 'settings'].map((navItem) => (
        <Button key={navItem} variant="admin" onClick={() => navigateTo(`/admin/${navItem}`)}>
          {navItem}
        </Button>
      ))}
    </Container>
  );
};

export default Navbar;
