import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledNotification = styled(Typography)<{ isSuccess: boolean }>`
  margin-top: 15px;
  color: ${({ isSuccess }) => (isSuccess ? 'green' : 'red')};
`;
