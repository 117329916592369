import React from 'react';
import styled from '@emotion/styled';
import { Paper, Box } from '@mui/material';

interface BasePanelProps {
  children: React.ReactNode;
  title: string;
  width?: string;
  height?: string;
  className?: string;
}

const StyledSpan = styled.span`
  font-size: 1.5rem;
`;

const StyledPaper = styled(Paper)<{ width?: string; height?: string }>`
  background: #101014;
  border: 1px solid #2e2e31;
  padding: 16px;
  color: #fff;
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || '100%'};
`;

const BasePanel: React.FC<BasePanelProps> = ({ title, children, width, height }) => {
  return (
    <StyledPaper width={width} height={height}>
      <StyledSpan>{title}</StyledSpan>
      <Box marginY={2}>{children}</Box>
    </StyledPaper>
  );
};

export default BasePanel;
