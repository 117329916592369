import styled from '@emotion/styled';

export const StyledSmallContainer = styled.div<{ marginBottom: boolean; width?: number }>`
  padding: 25px;
  border: 1px solid #2e2e31;
  height: 370px;
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => !props.width && `flex: 1;`}
  overflow-y: auto;
  ${(props) => props.marginBottom && 'margin-bottom: 30px'};
  ${(props) => props.width && `margin-right: 30px;`};
`;

export const StyledHeader = styled.div`
  opacity: 0.5;
  color: #ffffff;
  letter-spacing: 6px;
  margin-left: 10px;
  font-size: 12px;
`;

export const StyledSubNav = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
`;

interface SubNavItemProps {
  active: boolean;
}

export const StyledSubNavItem = styled.div<SubNavItemProps>`
  color: #ffffff;
  ${(props) => props.active && 'color: #5D51B8;'}
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  ${(props) => props.active && 'border-bottom: #5d51b8 1px solid;'}
  width: 100%;

  &:hover {
    opacity: 1;
  }
`;

export const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
`;
