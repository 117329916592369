import { ControlledBaseTextField, EInputValueFormatter } from '@/components/atoms/BaseTextField/BaseTextField';
import { ControlledSelect } from '@/components/molecules/Select/Select';
import { AllocationRowProps } from '@/components/LossCalculator/AllocationRow';

interface IExchangeInputProps extends Pick<AllocationRowProps, 'formMethods' | 'idx' | 'isCustom'> {
  selectOptions: { label: string; value: string }[];
}

export const ExchangeInput = ({ formMethods, idx, isCustom, selectOptions }: IExchangeInputProps) => {
  if (isCustom) {
    return (
      <ControlledBaseTextField
        valueFormatter={EInputValueFormatter.NO_FORMAT}
        formMethods={formMethods}
        inputName={`rows.${idx}.entity`}
        placeholder="Custom Exchange"
      />
    );
  }

  return (
    <ControlledSelect
      formMethods={formMethods}
      options={selectOptions}
      inputName={`rows.${idx}.entity`}
      placeholder="Select Exchange"
    />
  );
};
