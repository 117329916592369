import { ILossCalculatorForm, IResultOfLossCalculation, IRow } from '@/views/LossCalculatorView/utils/types';

export const emptyRow: IRow = {
  entity: '',
  exposure: 0,
  pd: 0,
  percentageExposure: '',
  recovery: 0,
  isCustom: false,
};

export const defaultLossCalculatorFormValues: ILossCalculatorForm = {
  disabledExtremes: false,
  rows: [emptyRow],
  correlation: 10,
  extremeLossProbability: undefined,
  extremeLossAlpha: undefined,
};

export const defaultResultOfLossCalculation: IResultOfLossCalculation = {
  expectedLossUsd: '',
  unexpectedLossUsd: '',
  expectedLossPct: '',
  unexpectedLossPct: '',
  zeroDefaults: '',
  probExceedingLoss: '',
  creditVar: '',
  defaultsFrequency: [],
  lossFrequency: [],
};
