import styled from '@emotion/styled';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactProps } from 'highcharts-react-official';
import { format } from 'date-fns';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { ChartData } from '@/types/chartPoint';

const Figure = styled.figure`
  width: 100%;
  margin: 1em auto;
`;

interface TooltipFormatterContextObject {
  x: number;
  y: number;
  point: {
    series: {
      name: string;
    };
  };
}

interface TimeLineChartProps {
  timeSeriesData: ChartData;
  options?: HighchartsReactProps;
}

const TimeLineChart = ({ timeSeriesData, options = {} }: TimeLineChartProps) => {
  const backgroundColor = '#101014';
  const chartBackground = '#282829';
  timeSeriesData.sort((a, b) => a.x - b.x);
  const timestampOfLastDataPoint = timeSeriesData?.length ? timeSeriesData[timeSeriesData?.length - 1].x : null;
  const timestampOfFirstDataPoint = timeSeriesData?.length ? timeSeriesData[0].x : null;

  const chartOptions = {
    credits: {
      enabled: false,
    },
    title: null,
    chart: {
      backgroundColor: backgroundColor,
      height: 243,
      zoomType: 'x',
    },
    tooltip: {
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        const date = format(this.x, 'dd MMM');
        const value = Highcharts.numberFormat(this.y, 2).replace(/ /g, ',');
        return `${date} <div style="display:inline-block; width:10px; height:10px; background-color:#5D51B8; border-radius:5px; margin:0 5px;"></div> ${value}%`;
      },
    },
    xAxis: {
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: chartBackground,
      max: timestampOfLastDataPoint,
      min: timestampOfFirstDataPoint,
      type: 'datetime',
      tickLength: 0,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      tickPositioner: function (this: { max: number; min: number; dataMin: number; dataMax: number }) {
        const positions: any = [];
        const divisions = 5;
        const tickInterval = (this.max - this.min) / divisions;

        for (let i = 0; i <= divisions; i++) {
          positions.push(this.min + i * tickInterval);
        }
        positions.info = {
          unitName: 'day',
          higherRanks: {},
          totalRange: positions[positions.length - 1] - positions[0],
        };
        return positions;
      },
      labels: {
        style: {
          color: '#F7F7F7',
        },
      },
    },
    yAxis: {
      gridLineColor: chartBackground,
      title: {
        text: '1-YR Probability of Default',
      },
      labels: {
        formatter: function (this: { value: number }): string {
          return `${this.value.toFixed(0)}%`;
        },
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPlacement: 'on',
      },
      area: {
        color: '#5D51B8',
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, 'rgba(93, 81, 184, 0.28)'],
            [0.8956, 'rgba(93, 81, 184, 0)'],
          ],
        },
        marker: {
          radius: 1,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
      line: {
        color: '#E54545',
        dashStyle: 'ShortDot',
      },
    },
    series: [
      {
        type: 'area',
        name: 'Total Balance',
        legend: {
          enabled: false,
        },
        data: timeSeriesData,
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
    ],
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  const mergedOptions = { ...chartOptions, ...options };

  return (
    <Figure>
      <HighchartsReact highcharts={Highcharts} options={mergedOptions} />
    </Figure>
  );
};

export default TimeLineChart;
