import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { BaseTextFieldProps } from './BaseTextField';

export const StyledTextField = styled(TextField)<BaseTextFieldProps>`
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: #1b1b1e;
  gap: 16px;
  border: ${({ borderColor }) => `1px solid ${borderColor}` || 'none'};

  & .MuiInputBase-root {
    width: 100%;
    height: 100%;

    & .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart,
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
      -webkit-text-fill-color: #fff;
    }

    & .MuiTypography-root {
      font-family: 'General Sans', sans-serif;
      color: #fff;
    }
  }

  & label.Mui-focused {
    color: #5d51b8;
  }

  & .MuiInput-underline:after {
    border-bottom-color: #5d51b8;
  }

  & .MuiFilledInput-underline:after {
    border-bottom-color: #5d51b8;
  }

  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${({ borderColor }) => borderColor || '#5d51b8'};
  }

  & .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #fff;
  }

  & .MuiFormHelperText-root {
    margin: 0;
    padding: 3px 14px;
    background-color: ${({ helperTextBackgroundColor }) => helperTextBackgroundColor || '#101014'};
    color: #ffffff;
  }
`;
