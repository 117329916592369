import styled from '@emotion/styled';

const CounterBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #242428;
  color: white;
  padding: 20px;
  margin: 10px;
  text-align: center;
  justify-content: center;
  width: 254px;
  height: 137px;
`;

const Count = styled.div`
  font-size: 2em;
  margin-bottom: 0.5em;
`;

const Label = styled.div`
  font-size: 1em;
`;

interface UserCounterProps {
  count: number;
  label: string;
}

const AdminPanelUserCounter = ({ count, label }: UserCounterProps) => {
  return (
    <CounterBox>
      <Count>{count}</Count>
      <Label>{label}</Label>
    </CounterBox>
  );
};

export default AdminPanelUserCounter;
