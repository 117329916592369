import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { ADMIN_RATINGS_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IUpdateRatingsFileResponse } from '@/api/adminRatings/adminRatings.types';

export const useProcessRatingsFile = () => {
  const { adminRatingsApi } = useApi();

  const {
    error,
    mutate: processRatingsFile,
    isPending,
  } = useMutation<IUpdateRatingsFileResponse, Error, string | undefined>({
    mutationKey: [ADMIN_RATINGS_KEY, 'UPDATE_RATINGS_FILE'],
    mutationFn: (fileId) => {
      return adminRatingsApi.updateRatingsFile(fileId).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          return Promise.reject(new UnknownErrorAndWeCouldNot(`upload the ratings file`));
        }
      });
    },
  });

  return {
    error,
    processRatingsFile,
    isPending,
  };
};
