import { Typography } from '@mui/material';
import HelpIcon from '@/assets/icons/help.svg';
import { TopMoversTable } from '@/views/RatingsView/components/TopMovers/components/TopMoversTable/TopMoversTable';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledHeader, StyledWrapper } from './TopMovers.styled';
import { IRatingsViewProps } from '@/views/RatingsView/RatingsView';

export const TopMovers = ({ topMovers }: Pick<IRatingsViewProps, 'topMovers'>) => {
  if (!topMovers.length) {
    return (
      <Typography color="white.100" align="center">
        There is no top movers to display
      </Typography>
    );
  }

  return (
    <StyledWrapper>
      <StyledHeader>
        TOP MOVERS
        <Tooltip
          arrow
          title="List of exchanges whose average Probability of Default has changed the most between this current calendar month and the last. "
        >
          <img src={HelpIcon} alt="Help icon" />
        </Tooltip>
      </StyledHeader>

      <TopMoversTable topMovers={topMovers} />
    </StyledWrapper>
  );
};
