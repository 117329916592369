import styled from '@emotion/styled';

const AdminButton = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #555;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #777;
  }

  &:disabled {
    opacity: 0.5;
    background-color: #555;
    cursor: auto;
  }
`;

export default AdminButton;
