import { useNavigate } from 'react-router-dom';
import { IEntityId } from '@/types/entities';
import {
  StyledEntitiesContainer,
  StyledEntityTitle,
  StyledEntitiesTable,
  StyledTableBody,
  StyledTableHeaderCell,
  StyledTableBodyCell,
  StyledInteractiveTableBodyCell,
  StyledTableHead,
  StyledTableWrapper,
} from './EntitiesListTable.styled';

interface EntitiesListTableProps {
  title: string;
  entities?: IEntityId[];
}

export const EntitiesListTable = ({ title, entities = [] }: EntitiesListTableProps) => {
  const navigate = useNavigate();

  const handleManageClick = (entityId: string | null) => {
    if (entityId === null) return;

    navigate(`/admin/entities/${entityId}`);
  };

  return (
    <StyledEntitiesContainer>
      <StyledEntityTitle>{title}</StyledEntityTitle>
      <StyledTableWrapper>
        <StyledEntitiesTable>
          <StyledTableHead>
            <tr>
              <StyledTableHeaderCell>Entity id</StyledTableHeaderCell>
              <StyledTableHeaderCell>Agio id</StyledTableHeaderCell>
              <StyledTableHeaderCell>Type</StyledTableHeaderCell>
              <StyledTableHeaderCell>Status</StyledTableHeaderCell>
              <StyledTableHeaderCell>Action</StyledTableHeaderCell>
            </tr>
          </StyledTableHead>
          <StyledTableBody>
            {entities.map((entity, index) => (
              <tr key={index}>
                <StyledTableBodyCell>{entity.id}</StyledTableBodyCell>
                <StyledTableBodyCell>{entity.agioId}</StyledTableBodyCell>
                <StyledTableBodyCell>{entity.type}</StyledTableBodyCell>
                <StyledTableBodyCell>{entity.status}</StyledTableBodyCell>
                <StyledInteractiveTableBodyCell onClick={() => handleManageClick(entity.id)}>
                  Manage
                </StyledInteractiveTableBodyCell>
              </tr>
            ))}
          </StyledTableBody>
        </StyledEntitiesTable>
      </StyledTableWrapper>
    </StyledEntitiesContainer>
  );
};
