import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IAdminAnomaliesApi,
  IGetAnomaliesListRequest,
  IGetAnomaliesListResponse,
} from '@/api/adminAnomalies/adminAnomalies.types';
import { IExtendedAlertDto } from '@/types/alert';

export class AdminAnomaliesApi implements IAdminAnomaliesApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_ANOMALIES;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getAnomaliesList({ page, size, exchange }: Partial<IGetAnomaliesListRequest>) {
    const params = {
      page,
      size,
      exchange,
    };

    return this.httpClient.get<IGetAnomaliesListResponse>(this.getBaseUrl(), { params });
  }

  getAnomalyById(anomalyId: string) {
    return this.httpClient.get<IExtendedAlertDto>(`${this.getBaseUrl()}${anomalyId}/`);
  }
}
