import React from 'react';
import { formatToUSD, amountToNumber } from '@/helpers/helpers';
import { useTrackTimeSpentOnMount } from '@/hooks/useTrackTimeSpent';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import AlertIcon from '@/assets/icons/alert.svg';
import { getZScoreColors } from '@/helpers/zScoreMethods';

interface AlertDetailsPanelProps {
  companyName: string;
  timestamp: string;
  variable: string;
  change: string;
  amount: string;
  z_score: number;
  severity: string | null;
  entityType: string;
}

interface DetailProps {
  label: string;
  value: string | JSX.Element;
}

const Detail: React.FC<DetailProps> = ({ label, value }) => (
  <div
    style={{
      display: 'flex',
      width: '211px',
      justifyContent: 'space-between',
      margin: '0',
      padding: '0',
      alignItems: 'center',
    }}
  >
    <span style={{ color: '#FFF', fontSize: '14px', fontWeight: 400, opacity: 0.5 }}>{label}</span>
    <span style={{ color: '#FFF', fontSize: '14px', fontWeight: 400 }}>{value}</span>
  </div>
);

const AlertDetailsPanel: React.FC<AlertDetailsPanelProps> = ({
  companyName,
  timestamp,
  variable,
  change,
  amount,
  z_score,
  severity,
  entityType,
}) => {
  useTrackTimeSpentOnMount('Alert details panel', {
    companyName,
    timestamp,
    variable,
    change,
    amount,
    z_score,
    severity,
  });

  const severityLabel = () => {
    if (severity === 'high')
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <span style={{ color: '#ff0b17' }}>{severity.toUpperCase()}</span> <img src={AlertIcon} alt="Alert icon" />
        </div>
      );
    if (severity === 'low') return <span style={{ color: '#e5b302' }}>{severity.toUpperCase()}</span>;
    return '';
  };

  const generatedZScoreColors = getZScoreColors(severity);

  return (
    <div
      style={{
        display: 'flex',
        width: '213px',
        height: 'auto',
        padding: '15px 25px 30px 25px',
        alignItems: 'center',
        gap: '16px',
        flexShrink: 0,
        borderRadius: '4px 4px 0px 0px',
        border: '1px solid #2E2E31',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          height: '57px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
          borderBottom: '1px solid #2e2e31',
          width: '263px',
          paddingBottom: '15px',
        }}
      >
        <ExchangeLogo companyName={companyName} />
        <h3 style={{ color: '#FFF', fontSize: '24px', fontWeight: 400 }}>{getFullExchangeName(companyName)}</h3>
      </div>
      <div
        style={{
          display: 'inline-flex',
          height: 'auto',
          alignItems: 'flex-start',
          gap: '32px',
          flexShrink: 0,
          flexDirection: 'column',
        }}
      >
        <h4
          style={{
            color: '#FFF',
            fontSize: '14px',
            fontWeight: 400,
            opacity: 0.5,
            letterSpacing: '4.8px',
          }}
        >
          ALERT DETAILS
        </h4>
        <Detail
          label="Z-Score"
          value={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px 8px',
                borderRadius: '4px',
                backgroundColor: generatedZScoreColors.backgroundColor,
              }}
            >
              <span style={{ fontSize: '24px', fontWeight: 600, color: generatedZScoreColors.color }}>
                {z_score.toFixed(2)}
              </span>
            </div>
          }
        />
        <Detail label="Severity" value={severityLabel()} />
        <Detail label="Date" value={timestamp} />
        <Detail label="Entity type" value={entityType.toUpperCase()} />
        <Detail label="Variable" value={variable} />
        <Detail label="Change" value={change} />
        <Detail label="Amount" value={formatToUSD(amountToNumber(amount))} />
      </div>
    </div>
  );
};

export default AlertDetailsPanel;
