import React from 'react';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { StandardTextFieldProps } from '@mui/material';
import { StyledTextField } from './BaseTextField.styled';

export interface BaseTextFieldProps extends StandardTextFieldProps {
  label?: string;
  value?: string;
  borderColor?: string;
  helperTextBackgroundColor?: string;
}

const BaseTextField: React.FC<BaseTextFieldProps> = ({
  label,
  value,
  borderColor,
  helperTextBackgroundColor,
  onChange,
  InputLabelProps,
  InputProps,
  ...props
}) => {
  return (
    <StyledTextField
      fullWidth
      label={label}
      value={value}
      borderColor={borderColor}
      helperTextBackgroundColor={helperTextBackgroundColor}
      onChange={onChange}
      InputLabelProps={{
        style: { color: '#fff' },
        ...InputLabelProps,
      }}
      InputProps={{
        inputProps: {
          style: { color: '#fff' },
        },
        ...InputProps,
      }}
      {...props}
    />
  );
};

interface ControlledBaseTextFieldProps<T extends FieldValues> extends BaseTextFieldProps {
  label?: string;
  inputName: Path<T>; // key or chained keys - of the form schema accordingly
  formMethods: UseFormReturn<T>;
  disabled?: boolean;
  valueFormatter?: EInputValueFormatter;
}

export enum EInputValueFormatter {
  'NO_FORMAT' = 'no-format',
  'DOLLAR' = 'dollar-formatter',
  'PERCENTAGE' = 'percentage-formatter',
}

export const ControlledBaseTextField = <T extends FieldValues>({
  label,
  inputName,
  formMethods,
  disabled = false,
  valueFormatter = EInputValueFormatter.NO_FORMAT,
  InputProps,
  ...props
}: ControlledBaseTextFieldProps<T>) => {
  if (valueFormatter === EInputValueFormatter.PERCENTAGE) {
    return (
      <Controller
        render={({ field, fieldState: { error }, ...props }) => (
          <NumericFormat
            isAllowed={(values) => {
              const { floatValue } = values;
              if (floatValue !== undefined) {
                return floatValue >= 0 && floatValue <= 100;
              }

              return true;
            }}
            customInput={BaseTextField}
            allowNegative={false}
            allowLeadingZeros={false}
            suffix="%"
            onValueChange={(v) => {
              field.onChange(v.floatValue);
            }}
            label={label}
            name={field.name}
            value={field.value}
            inputRef={field.ref}
            onBlur={field.onBlur}
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            InputProps={InputProps}
            {...props}
          />
        )}
        name={inputName}
        control={formMethods.control}
      />
    );
  }

  if (valueFormatter === EInputValueFormatter.DOLLAR) {
    return (
      <Controller
        render={({ field, fieldState: { error }, ...props }) => (
          <NumericFormat
            customInput={BaseTextField}
            thousandSeparator={true}
            allowNegative={false}
            allowLeadingZeros={false}
            prefix="$"
            onValueChange={(v) => {
              field.onChange(v.floatValue);
            }}
            label={label}
            name={field.name}
            value={field.value}
            inputRef={field.ref}
            onBlur={field.onBlur}
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            InputProps={InputProps}
            {...props}
          />
        )}
        name={inputName}
        control={formMethods.control}
      />
    );
  }

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <BaseTextField
          label={label}
          name={field.name}
          value={field.value || ''}
          onChange={field.onChange}
          onBlur={field.onBlur}
          inputRef={field.ref}
          disabled={disabled}
          error={!!error}
          helperText={error?.message}
          InputProps={InputProps}
          {...props}
        />
      )}
      name={inputName}
      control={formMethods.control}
    />
  );
};

export default BaseTextField;
