import { z, ZodType } from 'zod';

export enum ESettingsNotifications {
  NEWSLETTER = 'newsletter',
  ANOMALIES = 'anomalies',
  UPDATES = 'updates',
  STATISTICAL_RATINGS = 'statistical_ratings',
}

export interface IPersonalPreferencesForm {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    accountStatus: EAccountStatus | '';
  };
  notifications: {
    newsletter: boolean;
    anomalies: boolean;
    updates: boolean;
    statistical_ratings: boolean;
  };
}

export enum EAccountStatus {
  SUSPENDED = 'Suspended',
  ACTIVE = 'Active',
}

const accountStatusValues = [EAccountStatus.SUSPENDED, EAccountStatus.ACTIVE] as const;

export const PersonalPreferencesSchema: ZodType<IPersonalPreferencesForm> = z.object({
  user: z.object({
    firstName: z.string().min(3, { message: 'First name must be at least 3 characters.' }),
    lastName: z.string().min(3, { message: 'First name must be at least 3 characters.' }),
    email: z.string().email(),
    accountStatus: z.enum(accountStatusValues),
  }),
  notifications: z.object({
    newsletter: z.boolean(),
    anomalies: z.boolean(),
    updates: z.boolean(),
    statistical_ratings: z.boolean(),
  }),
});
