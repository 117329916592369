import { Typography } from '@mui/material';
import { RatingsTable } from '@/views/RatingsView/components/RatingsRanking/components/RatingsTable/RatingsTable';
import { IRatingsViewProps } from '@/views/RatingsView/RatingsView';

export const RatingsRanking = ({ fetchedRatings }: Pick<IRatingsViewProps, 'fetchedRatings'>) => {
  if (!fetchedRatings.length) {
    return (
      <Typography color="white.100" align="center" pt="10px">
        Currently there is no ranking to display.
      </Typography>
    );
  }

  return <RatingsTable ratings={fetchedRatings} />;
};
