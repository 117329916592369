import { useEffect, useMemo } from 'react';
import { UseFieldArrayRemove, useWatch } from 'react-hook-form';
import DeleteIcon from '@/assets/icons/delete.svg?react';
import { emptyRow } from '@/views/LossCalculatorView/utils/constants';
import { formatFloatToPercent } from '@/helpers/helpers';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import BaseTextField, {
  ControlledBaseTextField,
  EInputValueFormatter,
} from '@/components/atoms/BaseTextField/BaseTextField';
import { ExchangeInput } from '@/components/LossCalculator/ExchangeInput';
import { PdInput } from '@/components/LossCalculator/PdInput';
import { IPortfolioAllocationProps } from '@/views/LossCalculatorView/components/PortfolioAllocation/PortfolioAllocation';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { StyledDeleteButton, StyledIconWrapper } from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';

export interface AllocationRowProps extends Pick<IPortfolioAllocationProps, 'formMethods'> {
  exchangeList: string[];
  ratings: IStatisticalRatingEntity[];
  idx: number;
  totalExposure: number;
  timestamp: string;
  isCustom: boolean;
  removeRow: UseFieldArrayRemove;
  shouldDisplayDeleteBtn: boolean;
}

const AllocationRow = ({
  formMethods,
  exchangeList,
  ratings,
  idx,
  totalExposure,
  timestamp,
  isCustom,
  removeRow,
  shouldDisplayDeleteBtn,
}: AllocationRowProps) => {
  const formValues = useWatch({
    name: 'rows',
    control: formMethods.control,
  });

  const { setValue } = formMethods;

  const formattedPercentageExposure = useMemo(() => {
    if (formValues[idx].exposure) {
      const value = (formValues[idx].exposure / totalExposure) * 100;
      return formatFloatToPercent(value);
    }

    return emptyRow.percentageExposure;
  }, [formValues, idx, totalExposure]);

  const selectOptions = exchangeList.map((e) => ({ label: getFullExchangeName(e), value: e }));

  const handleRemoveClick = () => {
    removeRow(idx);
  };

  useEffect(() => {
    if (formValues[idx].entity) {
      const rating = ratings.find((r) => r.entity === formValues[idx].entity);
      if (rating) {
        const findPD = rating.data.find((d) => timestamp === d.timestamp.split('T')[0]);
        return setValue(`rows.${idx}.pd`, findPD?.value || emptyRow.pd);
      }
    }

    if (!formValues[idx].entity && !formValues[idx].isCustom) {
      setValue(`rows.${idx}.pd`, emptyRow.pd);
    }
  }, [formValues[idx].entity, ratings, setValue, timestamp]);

  return (
    <>
      {idx !== 0 && <div></div>}
      <div>
        <ExchangeInput formMethods={formMethods} isCustom={isCustom} idx={idx} selectOptions={selectOptions} />
      </div>
      <div>
        <PdInput formMethods={formMethods} idx={idx} isCustom={isCustom} />
      </div>
      <div>
        <ControlledBaseTextField
          valueFormatter={EInputValueFormatter.PERCENTAGE}
          formMethods={formMethods}
          inputName={`rows.${idx}.recovery`}
          placeholder="Enter %"
        />
      </div>
      <div>
        <ControlledBaseTextField
          valueFormatter={EInputValueFormatter.DOLLAR}
          formMethods={formMethods}
          inputName={`rows.${idx}.exposure`}
          placeholder="Enter exposure"
        />
      </div>
      <div>
        <BaseTextField disabled value={formattedPercentageExposure} />
      </div>
      {shouldDisplayDeleteBtn && (
        <StyledIconWrapper>
          <StyledDeleteButton disableRipple skipTypography blockHoverBehavior onClick={handleRemoveClick}>
            <DeleteIcon />
          </StyledDeleteButton>
        </StyledIconWrapper>
      )}
    </>
  );
};

export default AllocationRow;
