import styled from '@emotion/styled';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const StyledInputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StyledInputWrapper = styled.div`
  flex: 1 1 250px;
`;

export const StyledWrapperButton = styled.div`
  width: 253px;
`;
