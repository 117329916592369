import React from 'react';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import {
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { IOption } from '@/types/select';

const StyledMuiSelect = styled(MuiSelect)`
  border-radius: 4px;
  background: #1b1b1e;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.54);
  border-color: transparent;

  svg {
    fill: rgba(255, 255, 255, 0.54);
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  height: 100%;
  max-height: 56px;
  display: flex;
  flex-direction: column;
`;

const StyledMenuItem = styled(MenuItem)`
  background: #1b1b1e;
  color: rgba(255, 255, 255, 0.54);

  &:hover {
    background: #222;
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  color: red;
`;

interface SelectProps {
  options: IOption[];
  label?: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  clearVisible?: boolean;
  name?: string;
}

const Select = ({ options, label, onChange, value, placeholder, clearVisible = true, name, ...rest }: SelectProps) => {
  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    onChange(value);
  };
  const clearButtonStyle = value ? { display: 'flex' } : { display: 'none' };

  const clearState = () => {
    onChange('');
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={clearState} style={clearButtonStyle}>
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <StyledFormControl {...rest}>
      <StyledMuiSelect
        name={name}
        displayEmpty
        value={value}
        onChange={handleSelectChange}
        label={label}
        endAdornment={clearVisible ? endAdornment : null}
        inputProps={{
          endAdornment: endAdornment,
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: '#1b1b1e',
              },
            },
          },
        }}
        sx={{
          '& .MuiSelect-select .notranslate::after': placeholder
            ? {
                content: `"${placeholder}"`,
                color: 'rgba(255, 255, 255, 0.54)',
              }
            : {},
        }}
      >
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledMuiSelect>
    </StyledFormControl>
  );
};

export default Select;

interface ControlledSelectProps<T extends FieldValues> {
  inputName: Path<T>; // key or chained keys - of the form schema accordingly
  formMethods: UseFormReturn<T>;
  options: IOption[];
  label?: string;
  placeholder?: string;
  clearVisible?: boolean;
}

export const ControlledSelect = <T extends FieldValues>({
  inputName,
  formMethods,
  options,
  label,
  placeholder,
  clearVisible = true,
}: ControlledSelectProps<T>) => {
  const { register } = formMethods;

  return (
    <StyledFormControl>
      <Controller
        name={inputName}
        control={formMethods.control}
        render={({ field, fieldState: { error } }) => {
          const clearButtonStyle = field.value ? { display: 'flex' } : { display: 'none' };
          const clearSelect = () => field.onChange('');

          const endAdornment = (
            <InputAdornment position="end">
              <IconButton onClick={clearSelect} style={clearButtonStyle}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          );

          const renderValue = (p: unknown) => {
            if (typeof p === 'string') {
              return <>{p.length > 0 ? getFullExchangeName(p) : placeholder}</>;
            }
          };

          return (
            <>
              <StyledMuiSelect
                displayEmpty
                label={label}
                endAdornment={clearVisible ? endAdornment : null}
                inputProps={{
                  register: register(inputName),
                  endAdornment: endAdornment,
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: '#1b1b1e',
                      },
                    },
                  },
                }}
                renderValue={renderValue}
                {...field}
                onChange={({ target: { value } }) => field.onChange(value)}
                error={!!error}
              >
                {options.map((option) => (
                  <StyledMenuItem key={option.value} value={option.value}>
                    {option.label}
                  </StyledMenuItem>
                ))}
              </StyledMuiSelect>
              {!!error && <StyledFormHelperText>{error.message}</StyledFormHelperText>}
            </>
          );
        }}
      />
    </StyledFormControl>
  );
};
