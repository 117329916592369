import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import Button from '@/components/atoms/Button/Button';
import Text from '@/components/atoms/Text/Text';
import { ModalOverlay, ModalContainer, ModalHeader, ModalBody, ModalFooter } from './StyledComponents';
import { useAlertStore } from '@/store/globalState';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CreateUpdateModalCharts from './CreateUpdateModalCharts';
import CheckboxField from '@/components/atoms/Checkbox/Checkbox';
import { ChartPoint } from '@/types/chartPoint';
import { useCreateAlertUpdate } from '@/api/adminAlerts/adminAlerts.hooks';

interface WrapperInputProps {
  width?: string;
}

const WrapperInput = styled.div<WrapperInputProps>`
  width: ${(props) => props.width || '253px'};
  margin: 20px 0px;
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

const StyledInput = styled(BaseTextField)`
  margin-top: 20px;
`;

const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    color: white;
  }

  .ql-toolbar {
    background-color: white;
  }
`;

interface ModalProps {
  isOpen: boolean;
  onClose: (e: any) => void;
  alertId: string | undefined;
  anomalyChainData: ChartPoint;
}

const initialFormValues = {
  title: '',
  details: '',
  timestamp: '',
  showChart: false,
};

const CreateUpdateModal: React.FC<ModalProps> = ({ isOpen, onClose, alertId, anomalyChainData }) => {
  const navigate = useNavigate();
  const { updates, setUpdates } = useAlertStore();
  const [formValues, setFormValues] = useState(initialFormValues);

  const { createAlertUpdate } = useCreateAlertUpdate();

  const handleOnClose = (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    onClose(event);
    setFormValues(initialFormValues);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const payload = { ...formValues, alertId: alertId || '' };

    createAlertUpdate(payload, {
      onSuccess: (newUpdate) => {
        setUpdates([...updates, newUpdate]);
        setFormValues(initialFormValues);
        navigate(`/admin/alerts/${alertId}`);
        onClose(event);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: { zIndex: 1000, backgroundColor: 'rgba(16, 16, 20, 0.7)' },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50vw',
          height: '80vh',
          background: '#242428',
          border: 'none',
        },
      }}
    >
      <ModalOverlay>
        <ModalContainer>
          <ModalHeader>
            <Text>Create Update</Text>
            <IconButton
              onClick={(event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) =>
                handleOnClose(event)
              }
            >
              <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
          </ModalHeader>
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <WrapperInput>
                <StyledText fontSize="14px">Date of update</StyledText>
                <StyledInput
                  borderColor="#fff"
                  type="date"
                  required
                  value={formValues.timestamp}
                  onChange={(e) => setFormValues({ ...formValues, timestamp: e.target.value })}
                />
              </WrapperInput>
              <WrapperInput>
                <StyledText fontSize="20px">Title</StyledText>
                <StyledInput
                  borderColor="#fff"
                  value={formValues.title}
                  required
                  onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                />
              </WrapperInput>
              <WrapperInput>
                <StyledText fontSize="20px">Show charts</StyledText>
                <CheckboxField
                  label={'Show charts'}
                  value={formValues.showChart}
                  onChange={(e) => {
                    setFormValues({ ...formValues, showChart: e.target.checked });
                  }}
                />
              </WrapperInput>
              {isOpen && alertId && formValues.showChart && (
                <CreateUpdateModalCharts alertId={alertId} anomalyChainData={anomalyChainData} />
              )}
              <WrapperInput width="100%">
                <StyledText fontSize="20px">Details</StyledText>
                <StyledQuill
                  theme="snow"
                  value={formValues.details}
                  onChange={(e: any) => setFormValues({ ...formValues, details: e })}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link'],
                      ['clean'],
                      ['code-block'],
                    ],
                  }}
                />
              </WrapperInput>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" variant="contained" fitContentWidth sx={{ minWidth: '200px' }}>
                Add Update
              </Button>
            </ModalFooter>
          </form>
        </ModalContainer>
      </ModalOverlay>
    </Modal>
  );
};

export default CreateUpdateModal;
