export const downloadFile = ({ data, fileName, fileType }: { data: string; fileName: string; fileType: string }) => {
  const blob = new Blob([data], { type: fileType });

  const templateAnchor = document.createElement('a');
  templateAnchor.download = fileName;
  templateAnchor.href = window.URL.createObjectURL(blob);

  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  templateAnchor.dispatchEvent(clickEvt);

  templateAnchor.remove();
};
