export enum EPermissions {
  ALERTS = 'user:alerts',
  RISKS = 'user:risks', // ratings and calculator
  WHITE_PAPER = 'user:whitepaper', // documentation
  ADMIN = 'admin:all',
  OWNER = 'owner:all',
}

export interface IPermissionPreference {
  name: string;
  value: EPermissions;
  enabled: boolean;
}
