import styled from '@emotion/styled';
import { FormHelperText, Slider, Typography } from '@mui/material';
import Select from '@/components/molecules/Select/Select';
import Button from '@/components/atoms/Button/Button';

export const StyledCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 25px 60px;
`;

export const StyledCalculatorTopRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #222224;
`;

export const StyledCalculatorBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #222224;
  padding-bottom: 30px;
`;

export const StyledCalculatorBottomLeftColumn = styled.div`
  width: 50%;
  padding-right: 30px;
`;

export const StyledCalculatorBottomRightColumn = styled.div`
  width: 50%;
  padding: 0 23px;
  border-left: 1px solid #222224;

  & > div {
    margin-bottom: 30px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > img {
    opacity: 0.5;
  }
`;

export const StyledSectionTitle = styled(Typography)`
  font-family: 'General Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4em;
  color: #ffffff80;
`;

export const StyledGridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr) 40px;
  grid-template-rows: repeat(2, 1fr);
  color: #ffffff;
`;

export const StyledGridHeader = styled.div<{ active?: boolean }>`
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  color: #f7f7f7;
  display: flex;
  height: 30px;
  align-self: end;
  opacity: ${({ active }) => !active && 0.6};
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: red;
`;

export const StyledRiskMeasuresGridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  color: #ffffff;
  margin-top: 20px;
`;

export const StyledExtremeLossGridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  color: #ffffff;
  margin-top: 20px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-top: 20px;
`;

export const StyledFlexCenter = styled.div`
  display: flex;
  align-self: center;
`;

export const StyledLoaderWrapper = styled.div<{ height: number; marginTop?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};
  min-height: ${({ height }) => height}px;
`;

export const StyledCorrelationSliderContainer = styled.div`
  padding-block: 20px;
  color: #f7f7f7;
`;

export const StyledSlider = styled(Slider)`
  & .MuiSlider-track {
    color: #5d51b8;
  }

  & .MuiSlider-rail {
    color: #595959;
  }

  & .MuiSlider-thumb {
    color: #f7f7f7;
  }

  & .MuiSlider-mark {
    color: transparent;
    background-color: transparent;
  }

  & .MuiSlider-markLabel {
    font-family: 'General Sans', sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #f7f7f7;
    left: 100%;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
`;

export const StyledDeleteButton = styled(Button)`
  padding: 0;
  transition: 0.3s;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;
