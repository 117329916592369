import { useContext, useEffect, useState } from 'react';
import { assertIsDefined } from '@/helpers/assertions';
import { EntitiesListContext } from '@/providers/EntitiesListContextProvider/EntitiesListContextProvider';
import { useGetUniqueEntitiesList } from '@/api/alerts/alerts.hooks';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';
import { useGetUniqueEntitiesListByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';

export const useEntitiesListManager = () => {
  const { entitiesList } = useGetUniqueEntitiesList();
  const { entitiesListDisplayedToAdmin } = useGetUniqueEntitiesListByAdmin();
  const [exchanges, setExchanges] = useState<IExchange[]>([]);
  const [exchangesDisplayedToAdmin, setExchangesDisplayedToAdmin] = useState<IExchange[]>([]);

  useEffect(() => {
    if (!entitiesList) return;

    const exchangesList = entitiesList.map(({ agioId, displayName }) => ({ value: agioId, label: displayName }));

    setExchanges(exchangesList);
  }, [entitiesList]);

  useEffect(() => {
    if (!entitiesListDisplayedToAdmin) return;

    const exchangesListDisplayedToAdmin = entitiesListDisplayedToAdmin.map(({ agioId, displayName }) => ({
      value: agioId,
      label: displayName,
    }));

    setExchangesDisplayedToAdmin(exchangesListDisplayedToAdmin);
  }, [entitiesListDisplayedToAdmin]);

  return { exchanges, exchangesDisplayedToAdmin };
};

export const useEntitiesList = () => {
  const { exchanges, exchangesDisplayedToAdmin } = useContext(EntitiesListContext);

  assertIsDefined(exchanges, '"IEntitiesListContext.exchanges has to be defined!"');
  assertIsDefined(exchangesDisplayedToAdmin, '"IEntitiesListContext.exchangesDisplayedToAdmin has to be defined!"');

  return {
    exchanges,
    exchangesDisplayedToAdmin,
  };
};
