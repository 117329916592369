import { UseFormReturn } from 'react-hook-form';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import Loader from '@/components/atoms/Loader/Loader';
import Button from '@/components/atoms/Button/Button';
import { ExtremeLossThresholdsFormContent } from '@/views/LossCalculatorView/components/ExtremeLossThresholds/ExtremeLossThresholdsFormContent';
import {
  StyledHeader,
  StyledLoaderWrapper,
  StyledSectionTitle,
} from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { ILossCalculatorForm, IResultOfLossCalculation } from '@/views/LossCalculatorView/utils/types';

export interface IExtremeLossThresholdsProps {
  formMethods: UseFormReturn<ILossCalculatorForm>;
  resultOfLossCalculation: IResultOfLossCalculation | null;
  isCalculationLoading: boolean;
}

export const ExtremeLossThresholds = ({
  formMethods,
  resultOfLossCalculation,
  isCalculationLoading,
}: IExtremeLossThresholdsProps) => {
  const { setValue } = formMethods;

  const handleClick = () => {
    setValue('disabledExtremes', false);
  };
  return (
    <>
      <StyledHeader>
        <StyledSectionTitle variant="h5">IV. COMPUTE EXTREME LOSS THRESHOLDS</StyledSectionTitle>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title="Extreme loss thresholds in a portfolio loss simulator are predefined limits that represent unusually large losses, beyond typical market fluctuations. These thresholds help in identifying and preparing for outlier events, ensuring that strategies are in place to manage potential impacts on the portfolio's overall health."
        >
          <img src={HelpIcon} alt="Help icon" />
        </Tooltip>
      </StyledHeader>
      {isCalculationLoading ? (
        <StyledLoaderWrapper height={194}>
          <Loader />
        </StyledLoaderWrapper>
      ) : (
        <ExtremeLossThresholdsFormContent formMethods={formMethods} resultOfLossCalculation={resultOfLossCalculation} />
      )}
      <Button type="submit" variant="contained" fitContentWidth onClick={handleClick}>
        Run
      </Button>
    </>
  );
};
