import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@mui/material';
import BasePanel from '@/components/atoms/BasePanel/BasePanel';
import { ControlledCustomSwitch } from '@/components/atoms/CustomSwitch/ControlledCustomSwitch';
import { IPersonalPreferencesForm } from '@organims/PersonalPreferencesForm/utils/types';
import { StyledFlexContainer, StyledWrapper, StyledContainer } from './NotificationPanel.styled';

const typographyStyles = { color: '#4A4A52' };

interface NotificationPanelProps {
  formMethods: UseFormReturn<IPersonalPreferencesForm>;
}

const NotificationPanel: React.FC<NotificationPanelProps> = ({ formMethods }) => {
  return (
    <BasePanel title={'Notifications'}>
      <Typography style={{ marginBottom: '20px', lineHeight: '15.266px', fontSize: '16px' }}>
        Select the kind of notification you get about your activities.
      </Typography>

      <StyledFlexContainer>
        <StyledContainer>
          <StyledWrapper>
            <Typography>Newsletter</Typography>
            <ControlledCustomSwitch inputName="notifications.newsletter" formMethods={formMethods} />
          </StyledWrapper>
          <Typography style={typographyStyles}>
            Get emails to find out what&apos;s happening and be up to date.
          </Typography>
        </StyledContainer>

        <StyledContainer>
          <StyledWrapper>
            <Typography>Product Updates</Typography>
            <ControlledCustomSwitch inputName="notifications.updates" formMethods={formMethods} />
          </StyledWrapper>
          <Typography style={typographyStyles}>
            Get emails to find out what&apos;s happening and be up to date.
          </Typography>
        </StyledContainer>

        <StyledContainer>
          <StyledWrapper>
            <Typography>Anomaly Alerts</Typography>
            <ControlledCustomSwitch inputName="notifications.anomalies" formMethods={formMethods} />
          </StyledWrapper>
          <Typography style={typographyStyles}>
            Get emails to find out what&apos;s happening and be up to date.
          </Typography>
        </StyledContainer>

        <StyledContainer>
          <StyledWrapper>
            <Typography>Statistical Ratings alerts</Typography>
            <ControlledCustomSwitch inputName="notifications.statistical_ratings" formMethods={formMethods} />
          </StyledWrapper>
          <Typography style={typographyStyles}>Get emails whenever new ratings file is processed.</Typography>
        </StyledContainer>
      </StyledFlexContainer>
    </BasePanel>
  );
};

export default NotificationPanel;
