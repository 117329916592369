import ArrowIcon from '@/assets/icons/arrow.svg?react';
import styled from '@emotion/styled';

export type TDirection = 'up' | 'down';

interface IPositionIconProps {
  direction: TDirection;
}

const StyledIcon = styled.div<IPositionIconProps>`
  --stroke-color: ${({ direction }) => (direction === 'up' ? '#4DBA32' : '#B51C1C')};
  ${({ direction }) => direction === 'down' && 'transform: rotate(180deg)'};
  display: flex;
  align-items: center;
`;

const PositionIcon = ({ direction }: IPositionIconProps) => {
  return (
    <StyledIcon direction={direction}>
      <ArrowIcon />
    </StyledIcon>
  );
};

export default PositionIcon;
