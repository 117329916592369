import { jwtDecode } from 'jwt-decode';

interface Payload {
  id: string;
  sub: string;
  role: string;
  exp: number;
}

export const useUserIdFromStorage = () => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  try {
    if (!token) {
      console.warn('No token found in storage.');
      return null;
    }
    const decodedToken = jwtDecode<Payload>(token);
    return decodedToken.id || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getIdFromToken = (token: string) => {
  const decodedToken = jwtDecode<Payload>(token);
  return decodedToken.id || null;
};

export const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode<Payload>(token || '');
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
};
