import { useContext, useState } from 'react';
import { AlertsFiltersContext } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContextProvider';
import { assertIsDefined } from '@/helpers/assertions';

const initialPage = 1;
const initialCustomDate = '';

export const useAlertsFiltersManager = () => {
  const [page, setPage] = useState(initialPage);
  const [exchangeFilter, setExchangeFilter] = useState<string[]>([]);
  const [timeRangeFilter, setTimeRangeFilter] = useState<string | undefined>(undefined);
  const [customStartDateFilter, setCustomStartDateFilter] = useState<string>(initialCustomDate);
  const [customEndDateFilter, setCustomEndDateFilter] = useState<string>(initialCustomDate);

  const handlePage = (newPage: number) => setPage(newPage);

  const setFilterAlertByExchange = (newExchanges: string[]) => {
    if (page !== initialPage) {
      handlePage(initialPage);
    }
    setExchangeFilter(newExchanges);
  };

  const setFilterAlertByTimeRange = (newTimeRange: string | undefined) => {
    if (page !== initialPage && newTimeRange !== 'custom') {
      handlePage(initialPage);
    }
    setTimeRangeFilter(newTimeRange);
  };

  const setFilterAlertByCustomStartDate = (newCustomStartDate: string) => {
    if (customEndDateFilter !== initialCustomDate && page !== initialPage) {
      handlePage(initialPage);
    }
    setCustomStartDateFilter(newCustomStartDate);
  };

  const setFilterAlertByCustomEndDate = (newCustomEndDate: string) => {
    if (customStartDateFilter !== initialCustomDate && page !== initialPage) {
      handlePage(initialPage);
    }
    setCustomEndDateFilter(newCustomEndDate);
  };

  return {
    page,
    exchangeFilter,
    timeRangeFilter,
    customStartDateFilter,
    customEndDateFilter,
    handlePage,
    setFilterAlertByExchange,
    setFilterAlertByTimeRange,
    setFilterAlertByCustomStartDate,
    setFilterAlertByCustomEndDate,
  };
};

export const useAlertsFilters = () => {
  const {
    page,
    exchangeFilter,
    timeRangeFilter,
    customStartDateFilter,
    customEndDateFilter,
    handlePage,
    setFilterAlertByExchange,
    setFilterAlertByTimeRange,
    setFilterAlertByCustomStartDate,
    setFilterAlertByCustomEndDate,
  } = useContext(AlertsFiltersContext);

  assertIsDefined(page, '"IAlertsFiltersContext.page has to be defined!"');
  assertIsDefined(exchangeFilter, '"IAlertsFiltersContext.exchangeFilter has to be defined!"');
  assertIsDefined(customStartDateFilter, '"IAlertsFiltersContext.customStartDateFilter has to be defined!"');
  assertIsDefined(customEndDateFilter, '"IAlertsFiltersContext.customEndDateFilter has to be defined!"');
  assertIsDefined(handlePage, '"IAlertsFiltersContext.handlePage has to be defined!"');
  assertIsDefined(setFilterAlertByExchange, '"IAlertsFiltersContext.setFilterAlertByExchange has to be defined!"');
  assertIsDefined(setFilterAlertByTimeRange, '"IAlertsFiltersContext.setFilterAlertByTimeRange has to be defined!"');
  assertIsDefined(
    setFilterAlertByCustomStartDate,
    '"IAlertsFiltersContext.setFilterAlertByCustomStartDate has to be defined!"',
  );
  assertIsDefined(
    setFilterAlertByCustomEndDate,
    '"IAlertsFiltersContext.setFilterAlertByCustomEndDate has to be defined!"',
  );

  return {
    page,
    exchangeFilter,
    timeRangeFilter,
    customStartDateFilter,
    customEndDateFilter,
    handlePage,
    setFilterAlertByExchange,
    setFilterAlertByTimeRange,
    setFilterAlertByCustomStartDate,
    setFilterAlertByCustomEndDate,
  };
};
