import React from 'react';
import { createRoot } from 'react-dom/client';
import '../index.css';

import '@/i18n';
import reportWebVitals from '@/report-web-vitals';
import App from '@/App';
import * as Sentry from '@sentry/react';

if (!import.meta.env.DEV) {
  Sentry.init({
    // @TODO make this env dependant
    dsn: 'https://ddd5ba0317d6f673015cb6b80bb91ef0@o4506577005314048.ingest.sentry.io/4506577449779200',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/api\.agioratings\.io\//],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
      new Sentry.Feedback({
        buttonLabel: 'Send Feedback',
        formTitle: 'Feedback',
        submitButtonLabel: 'Send',
      }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
