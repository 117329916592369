import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import AnomalyTimeLineChart from '@/components/molecules/Charts/AnomalyTimeLineChart';
import ColumnChart from '@/components/molecules/Charts/ColumnChart';
import { ChartData, ChartPoint } from '@/types/chartPoint';
import { TChartType } from '@/types/alert';

const ChartSelectionContainer = styled.div`
  display: column;
  justify-content: center;
  margin: 20px 0px;
  gap: 10px;
`;

const WrapperChart = styled.div<{ isSelected: boolean }>`
  border: ${({ isSelected }) => (isSelected ? '3px solid #5d51b8' : 'none')};
  cursor: pointer;
  transition: border 0.3s ease;
  color: #fff;

  &:hover {
    border: 3px solid #5d51b8;
  }

  min-height: 300px;
`;

const ChartsContainer = styled.div<{ layout: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => (props.layout === 'row' ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${(props) => (props.layout === 'row' ? 'center' : 'flex-start')};
`;

interface ChartSelection {
  showChart: boolean;
  chartType: TChartType;
}

interface WrapperChartsProps {
  alertId: string | undefined;
  chartData: ChartData;
  anomalyChainData: ChartPoint;
  layout?: 'row' | 'column';
  onSelectionChange: (chartSelection: ChartSelection) => void;
  isDataLoaded?: boolean;
  netFlowData: ChartData;
}

const ChartSelector = ({
  alertId,
  anomalyChainData,
  layout = 'column',
  chartData,
  onSelectionChange,
  isDataLoaded,
  netFlowData,
}: WrapperChartsProps) => {
  const [selectedCharts, setSelectedCharts] = useState({
    totalBalance: true,
    netAssetsFlow: false,
  });
  const chartContainerRef: any = useRef(null);

  const handleSelectChart = (chartId: keyof typeof selectedCharts) => {
    setSelectedCharts((prevSelections) => {
      const newSelections = { ...prevSelections, [chartId]: !prevSelections[chartId] };
      const showChart = newSelections.totalBalance || newSelections.netAssetsFlow;
      let chartType: TChartType = null;

      if (newSelections.totalBalance && newSelections.netAssetsFlow) {
        chartType = 'all';
      } else if (newSelections.totalBalance) {
        chartType = 'totalbalance';
      }
      if (onSelectionChange && showChart) {
        onSelectionChange({ showChart: showChart, chartType: chartType });
      }
      return newSelections;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (chartContainerRef.current && !chartContainerRef.current.contains(event.target)) {
        setSelectedCharts({
          totalBalance: false,
          netAssetsFlow: false,
        });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ChartSelectionContainer ref={chartContainerRef}>
      <div>
        <ChartsContainer layout={layout}>
          <WrapperChart isSelected={selectedCharts.totalBalance} onClick={() => handleSelectChart('totalBalance')}>
            <AnomalyTimeLineChart alertId={alertId} anomalyChainData={anomalyChainData} chartData={chartData} />
          </WrapperChart>
          <WrapperChart isSelected={selectedCharts.netAssetsFlow} onClick={() => handleSelectChart('netAssetsFlow')}>
            <ColumnChart flowData={netFlowData} />
          </WrapperChart>
        </ChartsContainer>
      </div>
    </ChartSelectionContainer>
  );
};

export default ChartSelector;
