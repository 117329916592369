import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { ControlledBaseTextField } from '@/components/atoms/BaseTextField/BaseTextField';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import { useEditEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityDetails/components/EditEntityDetailsForm/utils/useEditEntityForm';
import { IEntityId } from '@/types/entities';
import {
  StyledForm,
  StyledInputWrapper,
  StyledButtonsContainer,
  StyledWrapperButton,
  StyledInputsContainer,
} from './EditEntityDetailsForm.styled';

export interface IEditEntityDetailsFormProps {
  entity: IEntityId;
}

export const EditEntityDetailsForm = ({ entity }: IEditEntityDetailsFormProps) => {
  const navigate = useNavigate();

  const { editEntityMethods, handleEditEntityFormSubmit } = useEditEntityForm({ entity });

  const handleCancel = () => {
    navigate(`/admin/entities`);
  };

  return (
    <FormProvider {...editEntityMethods}>
      <StyledForm onSubmit={handleEditEntityFormSubmit}>
        <StyledInputsContainer>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Agio id" inputName="agioId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Arkham id" inputName="arkhamId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Coingecko id" inputName="coingeckoId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare id"
              inputName="cryptoCompareId"
              formMethods={editEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare name"
              inputName="cryptoCompareName"
              formMethods={editEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Glassnode id" inputName="glassnodeId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Type" inputName="type" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Subtype" inputName="subtype" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Status" inputName="status" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Similarweb domain"
              inputName="similarwebDomain"
              formMethods={editEntityMethods}
              helperText="Separate domains using semicolon and space: '; '"
            />
          </StyledInputWrapper>
        </StyledInputsContainer>

        <StyledButtonsContainer>
          <StyledWrapperButton>
            <AdminButton type="submit">Save Changes</AdminButton>
          </StyledWrapperButton>
          <StyledWrapperButton>
            <AdminButton type="button" onClick={handleCancel}>
              Cancel
            </AdminButton>
          </StyledWrapperButton>
        </StyledButtonsContainer>
      </StyledForm>
    </FormProvider>
  );
};
