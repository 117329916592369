import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { ICreateUserRequest, IEditUserAsAdminRequest, IGetUserListResponse } from '@/api/adminUser/adminUser.types';
import { ADMIN_USER_KEY, USER_LIST_KEY } from '@/api/constants/queryKeys';
import { IUserDto, IUserId } from '@/types/user';
import { AxiosError } from 'axios';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';

export const useGetUserList = ({ size }: { size: number }) => {
  const { adminUserApi } = useApi();

  const {
    data: userList,
    error,
    isLoading,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery<IGetUserListResponse, Error>({
    queryKey: [USER_LIST_KEY, size],
    queryFn: ({ pageParam }) => {
      if (typeof pageParam === 'number') {
        return adminUserApi.getUserList(pageParam, size).catch((reason) => {
          return Promise.reject(reason);
        });
      } else {
        throw new Error('pageParam must be a number.');
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (typeof lastPageParam === 'number' && lastPage.pages && lastPage.page) {
        return lastPageParam >= lastPage.pages ? undefined : lastPage.page + 1;
      }
      return undefined;
    },
  });

  return {
    userList,
    error,
    isLoading,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetching,
  };
};

export const useGetUserById = ({ id }: IUserId) => {
  const { adminUserApi } = useApi();

  const {
    data: fetchedUser,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IUserId, Error, IUserDto>({
    queryKey: [ADMIN_USER_KEY, 'GET', 'BY_ID', id],
    meta: { userId: id },
    queryFn: ({ meta }) => {
      if (meta && meta.userId && typeof meta.userId === 'string') {
        return adminUserApi
          .getUserById(meta.userId)
          .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load user data`)));
      }
      throw new Error('userId should be a meta string');
    },
  });

  return {
    fetchedUser,
    isLoading,
    isSuccess,
    error,
  };
};

export const useCreateUser = () => {
  const { adminUserApi } = useApi();

  const {
    isPending,
    error,
    mutate: createUser,
  } = useMutation<IUserDto, Error, ICreateUserRequest>({
    mutationKey: [ADMIN_USER_KEY, 'CREATE'],
    mutationFn: (payload: ICreateUserRequest) => {
      return adminUserApi.createUser(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error('An unexpected error occurred.');
        }
      });
    },
  });

  return {
    isLoading: isPending,
    error,
    createUser,
  };
};

export const useEditUserAsAdmin = () => {
  const { adminUserApi } = useApi();

  const { error, mutate: editUser } = useMutation<IUserDto, Error, IEditUserAsAdminRequest>({
    mutationKey: [ADMIN_USER_KEY, 'EDIT'],
    mutationFn: (payload: IEditUserAsAdminRequest) => {
      return adminUserApi.editUser(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error('An unexpected error occurred. Make sure that email has been used is unique and try again.');
        }
      });
    },
  });

  return {
    error,
    editUser,
  };
};

export const useDeleteUserAsAdmin = () => {
  const { adminUserApi } = useApi();

  const { error, mutate: deleteUser } = useMutation<void, Error, IUserId>({
    mutationKey: [ADMIN_USER_KEY, 'DELETE'],
    mutationFn: ({ id }: IUserId) => {
      return adminUserApi.deleteUser(id).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error("An unexpected error occurred and we couldn't delete the user.");
        }
      });
    },
  });

  return {
    error,
    deleteUser,
  };
};

export const useResetUserPasswordAsAdmin = () => {
  const { adminUserApi } = useApi();

  const { error, mutate: resetUserPassword } = useMutation<IUserDto, Error, IUserId>({
    mutationKey: [ADMIN_USER_KEY, 'RESET_USER_PASSWORD'],
    mutationFn: ({ id }: IUserId) => {
      return adminUserApi.resetUserPassword(id).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error("An unexpected error occurred and we couldn't reset password");
        }
      });
    },
  });

  return {
    error,
    resetUserPassword,
  };
};
