import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserIdFromStorage } from '@/hooks/useUserIdFromToken';
import { defaultPersonalPreferencesFormValues } from '@/components/organims/PersonalPreferencesForm/utils/constants';
import {
  EAccountStatus,
  ESettingsNotifications,
  IPersonalPreferencesForm,
  PersonalPreferencesSchema,
} from '@/components/organims/PersonalPreferencesForm/utils/types';
import { useEditUser, useGetUserById } from '@/api/user/user.hooks';
import { ICustomNotification } from '@/types/notifications';
import { initialCustomNotification } from '@/constants/InitialState/notifications';

export const usePersonalPreferencesForm = () => {
  const userId = useUserIdFromStorage();
  const personalPreferencesMethods = useForm<IPersonalPreferencesForm>({
    defaultValues: defaultPersonalPreferencesFormValues,
    resolver: zodResolver(PersonalPreferencesSchema),
  });

  const { handleSubmit, setValue } = personalPreferencesMethods;

  const { editUser, isLoading: isEditUserLoading } = useEditUser();

  const [editUserNotificationMessage, setEditUserNotificationMessage] =
    useState<ICustomNotification>(initialCustomNotification);

  const updateEditUserNotificationMessage = useCallback((props: ICustomNotification) => {
    setEditUserNotificationMessage(props);
  }, []);

  const clearEditUserNotificationMessage = useCallback(() => {
    setEditUserNotificationMessage(initialCustomNotification);
  }, []);

  const onPersonalPreferencesSubmit = useCallback(
    async (data: IPersonalPreferencesForm) => {
      clearEditUserNotificationMessage();

      const payload = {
        id: userId || '',
        firstName: data.user.firstName,
        secondName: data.user.lastName,
        emailAddress: data.user.email,
        notifications: [
          { name: ESettingsNotifications.NEWSLETTER, enabled: data.notifications.newsletter },
          { name: ESettingsNotifications.ANOMALIES, enabled: data.notifications.anomalies },
          { name: ESettingsNotifications.UPDATES, enabled: data.notifications.updates },
          { name: ESettingsNotifications.STATISTICAL_RATINGS, enabled: data.notifications.statistical_ratings },
        ],
      };

      editUser(payload, {
        onSuccess: () => {
          updateEditUserNotificationMessage({ text: 'Personal data updated successfully.', type: 'success' });
        },
        onError: (error) => {
          updateEditUserNotificationMessage({
            text: error.message,
            type: 'error',
          });
        },
      });
    },
    [userId],
  );

  const handlePersonalPreferencesFormSubmit = handleSubmit(onPersonalPreferencesSubmit);

  const { fetchedUser, isSuccess, error, isLoading } = useGetUserById({ id: userId || '' });

  useEffect(() => {
    if (!fetchedUser || !isSuccess) return;

    setValue('user.firstName', fetchedUser.firstName);
    setValue('user.lastName', fetchedUser.secondName);
    setValue('user.email', fetchedUser.emailAddress);
    setValue('user.accountStatus', fetchedUser.disabled ? EAccountStatus.SUSPENDED : EAccountStatus.ACTIVE);

    fetchedUser.notifications.forEach((notification: { name: string; enabled: boolean }) => {
      if (!notification.enabled) return;

      if (notification.name === ESettingsNotifications.NEWSLETTER) {
        setValue('notifications.newsletter', notification.enabled);
      }

      if (notification.name === ESettingsNotifications.ANOMALIES) {
        setValue('notifications.anomalies', notification.enabled);
      }

      if (notification.name === ESettingsNotifications.UPDATES) {
        setValue('notifications.updates', notification.enabled);
      }

      if (notification.name === ESettingsNotifications.STATISTICAL_RATINGS) {
        setValue('notifications.statistical_ratings', notification.enabled);
      }
    });
  }, [fetchedUser, isSuccess]);

  return {
    personalPreferencesMethods,
    handlePersonalPreferencesFormSubmit,
    isEditUserLoading,
    editUserNotificationMessage,
    fetchUserDataError: error,
    isFetchUserDataLoading: isLoading,
  };
};
