import axios from 'axios';
import { HttpClient } from '@/api/HttpClient';
import { UserApi } from '@/api/user/user.api';
import { AuthApi } from '@/api/auth/auth.api';
import applyCaseMiddleware from 'axios-case-converter';

export const createAuthApiClient = () => {
  const axiosClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  const httpClient = new HttpClient(axiosClient);
  const authApi = new AuthApi(httpClient);

  return { authApi };
};

export const createAuthUserApiClient = () => {
  const axiosClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  // middleware converts camelCase to snake_case on request (outgoing),
  // and snake_case to camelCase on response (incoming)
  const axiosInstanceWithApplyCaseMiddleware = applyCaseMiddleware(axiosClient, {
    preservedKeys: [], // example if we want to skip conversion for particular keys
  });

  const httpClient = new HttpClient(axiosInstanceWithApplyCaseMiddleware);
  const userApi = new UserApi(httpClient);

  return { userApi };
};
