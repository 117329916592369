import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import TimeLineChart from '@/components/molecules/Charts/TimeLineChart';
import RisksDecomposition from '@/components/RisksDecompositions/RisksDecompositions';
import { Detail } from './components/Detail/Detail';
import { MovementDetail } from './components/MovementDetail/MovementDetail';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import mapChainData from '@/helpers/mapChainData';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { StyledHeader, StyledRowContainer, StyledSmallContainer } from './EntityRatingsView.styled';

interface IEntityRatingsViewProps {
  fetchedRatings: IStatisticalRatingEntity[];
}

export const EntityRatingsView = ({ fetchedRatings }: IEntityRatingsViewProps) => {
  const { entityName, action } = useParams();
  const navigate = useNavigate();

  const getActiveTab = () => {
    if (action === 'alerts') {
      return 'alerts';
    }
    return 'details';
  };
  const handleSubNavClick = (action: string) => {
    navigate(`/ratings/${entityName}/${action}`);
  };

  const entityData = fetchedRatings.map((i, idx) => ({ ...i, position: idx + 1 })).find((i) => i.entity === entityName);

  const chartData = mapChainData(entityData?.data || []);

  return (
    <>
      {/*<StyledSubNav>*/}
      {/*  <StyledSubNavItem active={getActiveTab() === 'details'} onClick={() => handleSubNavClick('details')}>*/}
      {/*    Statistical Ratings*/}
      {/*  </StyledSubNavItem>*/}
      {/*  <StyledSubNavItem active={getActiveTab() === 'alerts'} onClick={() => handleSubNavClick('alerts')}>*/}
      {/*    Anomaly Alerts*/}
      {/*  </StyledSubNavItem>*/}
      {/*</StyledSubNav>*/}
      <StyledRowContainer>
        <StyledSmallContainer marginBottom={true} width={280}>
          <StyledHeader>RANKING DETAILS</StyledHeader>
          {fetchedRatings.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'center',
                justifySelf: 'center',
                paddingTop: '40px',
                padding: '40px 10px 30px 10px',
              }}
            >
              <Detail
                label="PD"
                value={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '16px 8px',
                      borderRadius: '4px',
                      backgroundColor: 'rgba(255, 255, 255, 0.16)',
                    }}
                  >
                    <span style={{ fontSize: '24px', fontWeight: 600, color: '#FFFFFF' }}>
                      {entityData?.data[0].value?.toFixed(2)}%
                    </span>
                  </div>
                }
              />
              <MovementDetail label="1W Change" value={entityData?.pdChanges?.oneWeek || 0} />
              <MovementDetail label="1M Change" value={entityData?.pdChanges?.oneMonth || 0} />
              <MovementDetail label="6M Change" value={entityData?.pdChanges?.sixMonths || 0} />
              <Detail label="Position" value={`#${entityData?.position}`} />
            </div>
          ) : (
            <Typography color="white.100" align="center" pt="10px">
              No ranking details to display
            </Typography>
          )}
        </StyledSmallContainer>
        <StyledSmallContainer marginBottom={true}>
          <StyledHeader>1 YR PD TIMESERIES</StyledHeader>
          {chartData.length > 0 ? (
            <div style={{ height: '305px', width: '100%', paddingTop: '30px' }}>
              <TimeLineChart timeSeriesData={chartData} />
            </div>
          ) : (
            <Typography color="white.100" align="center" pt="10px">
              No timeline data
            </Typography>
          )}
        </StyledSmallContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <ErrorBoundary fallback={<CommonError variant="empty" />}>
          <RisksDecomposition originalEntityName={entityName} statisticalRatings={fetchedRatings} />
        </ErrorBoundary>
      </StyledRowContainer>
    </>
  );
};
