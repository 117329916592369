import { EntityRatings } from '@/views/EntityRatingsView/EntityRatings';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';

export const EntityRatingsPage = () => {
  const { shouldDisplayRisks } = usePermissionsManager();

  if (!shouldDisplayRisks) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <EntityRatings />
    </ErrorBoundary>
  );
};
