import LoginForm from '@/components/organims/AuthForms/LoginForm';
import { Container } from './LoginView.styled';

export const LoginView = () => {
  return (
    <Container>
      <LoginForm />
    </Container>
  );
};
