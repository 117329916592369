import { Button as MuiButton, ButtonProps, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends ButtonProps {
  noWrap?: boolean;
  fitContentWidth?: boolean;
  skipTypography?: boolean;
  blockHoverBehavior?: boolean;
}

const FIT_CONTENT_WIDTH = {
  width: 'fit-content',
};

const BLOCKED_HOVER = {
  '&.MuiButton-root:hover': {
    'background-color': 'transparent',
  },
};

const Button = ({
  children,
  noWrap = true,
  fitContentWidth = false,
  skipTypography = false,
  blockHoverBehavior = false,
  sx,
  ...rest
}: PropsWithChildren<Props>) => {
  let sxStyles = { ...sx };

  if (fitContentWidth) {
    sxStyles = { ...sxStyles, ...FIT_CONTENT_WIDTH };
  }

  if (blockHoverBehavior) {
    sxStyles = { ...sxStyles, ...BLOCKED_HOVER };
  }

  if (skipTypography) {
    return (
      <MuiButton sx={sxStyles} {...rest}>
        {children}
      </MuiButton>
    );
  }

  return (
    <MuiButton sx={sxStyles} {...rest}>
      <Typography noWrap={noWrap} variant="inherit">
        {children}
      </Typography>
    </MuiButton>
  );
};

export default Button;
