import { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, IconButton, Checkbox } from '@mui/material';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import Button from '@/components/atoms/Button/Button';
import Text from '@/components/atoms/Text/Text';
import { ModalOverlay, ModalContainer, ModalHeader, ModalBody, ModalFooter, Container } from './StyledComponents';
import { useCreateUser } from '@/api/adminUser/adminUser.hooks';
import { useNotificationsPreferences } from '@/hooks/useNotificationsPreferences';
import { usePermissionsPreferences } from '@/hooks/usePermissionsPreferences';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetchUsersList: () => void;
}

interface UserFormState {
  firstName: string;
  secondName: string;
  emailAddress: string;
  accountStatus: string;
}

const formDataInitialState = {
  firstName: '',
  secondName: '',
  emailAddress: '',
  accountStatus: 'active',
};

const CreateUserModal: React.FC<ModalProps> = ({ isOpen, onClose, refetchUsersList }) => {
  const { notifications, clearNotificationsPreferences, handleNotificationPreferenceChange } =
    useNotificationsPreferences();
  const { permissions, getEnabledPermissions, clearPermissionsPreferences, handlePermissionPreferenceChange } =
    usePermissionsPreferences();

  const [formData, setFormData] = useState<UserFormState>(formDataInitialState);
  const [uiError, setUiError] = useState('');

  const { isLoading, createUser } = useCreateUser();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    uiError && setUiError('');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      notifications,
      permissions: getEnabledPermissions(),
    };

    createUser(dataToSend, {
      onSuccess: () => {
        handleOnClose();
        refetchUsersList();
      },
      onError: (error) => {
        setUiError(error.message);
      },
    });
  };

  const handleOnClose = () => {
    onClose();
    setUiError('');
    setFormData(formDataInitialState);
    clearPermissionsPreferences();
    clearNotificationsPreferences();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { zIndex: 1000, backgroundColor: 'rgba(16, 16, 20, 0.7)' },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '40vw',
          height: '60vh',
          background: '#242428',
          border: 'none',
        },
      }}
    >
      <ModalOverlay>
        <ModalContainer>
          <ModalHeader>
            <Text>Create User</Text>
            <IconButton onClick={handleOnClose}>
              <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
          </ModalHeader>
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <BaseTextField
                name="firstName"
                placeholder="First name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <BaseTextField
                name="secondName"
                placeholder="Last name"
                value={formData.secondName}
                onChange={handleChange}
                required
              />
              <BaseTextField
                name="emailAddress"
                type="email"
                placeholder="Email"
                value={formData.emailAddress}
                onChange={handleChange}
                required
              />
              {!!uiError && <div style={{ color: 'red' }}>{uiError}</div>}
              <Container>
                {notifications.map((notification) => (
                  <FormControlLabel
                    key={notification.name}
                    sx={{ color: '#fff', height: '20px', display: 'flex', alignItems: 'inherit' }}
                    control={
                      <Checkbox
                        sx={{
                          color: '#fff',
                          '&.Mui-checked': {
                            color: '#5d51b8',
                          },
                        }}
                        checked={notification.enabled}
                        onChange={handleNotificationPreferenceChange}
                        name={notification.name}
                      />
                    }
                    label={`Subscribe to ${notification.name}`}
                  />
                ))}
              </Container>

              <Container>
                {permissions.map((permission) => (
                  <FormControlLabel
                    key={permission.name}
                    sx={{ color: '#fff', height: '20px', display: 'flex', alignItems: 'inherit' }}
                    control={
                      <Checkbox
                        sx={{
                          color: '#fff',
                          '&.Mui-checked': {
                            color: '#5d51b8',
                          },
                        }}
                        checked={permission.enabled}
                        onChange={handlePermissionPreferenceChange}
                        name={permission.name}
                      />
                    }
                    label={`Permission to ${permission.name}`}
                  />
                ))}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" variant="contained" fitContentWidth sx={{ minWidth: '200px' }} disabled={isLoading}>
                Create User
              </Button>
            </ModalFooter>
          </form>
        </ModalContainer>
      </ModalOverlay>
    </Modal>
  );
};

export default CreateUserModal;
