import styled from '@emotion/styled';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { useParams } from 'react-router-dom';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/atoms/Button/Button';

const StyledHeader = styled(AppBar)`
  height: 99px;
  width: calc(100vw - 100px);
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const EntityRatingsHeader = () => {
  const { entityName } = useParams();
  const navigate = useNavigate();

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <Button
              onClick={() => navigate(-1)}
              skipTypography
              disableRipple
              blockHoverBehavior
              sx={{ paddingInline: '5px' }}
            >
              <ArrowLeft />
            </Button>
            <ExchangeLogo companyName={entityName || ''} height="30px" />
            <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
              {getFullExchangeName(entityName || '')}
            </Typography>
          </div>
        </div>
      </Toolbar>
    </StyledHeader>
  );
};

export default EntityRatingsHeader;
