import { TablePagination as MuiTablePagination } from '@mui/material';

interface ITablePaginationProps {
  pageSize: number;
  availablePageSizes: number[];
  currentPage: number;
  totalDataNumber: number;
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handlePageSize: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isFetching: boolean;
}

export const TablePagination = ({
  pageSize,
  availablePageSizes,
  currentPage,
  totalDataNumber,
  handlePageChange,
  handlePageSize,
  isFetching,
}: ITablePaginationProps) => {
  return (
    <MuiTablePagination
      rowsPerPageOptions={availablePageSizes}
      component="div"
      count={totalDataNumber}
      page={currentPage}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handlePageSize}
      disabled={isFetching}
    />
  );
};
