import { useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { flowDataChartOptions, flowOptions } from '@/constants/flows';
import { isOfType } from '@/helpers/typeGuards';
import { TFlowChart } from '@/types/flows';
import { ChartData } from '@/types/chartPoint';

interface IUseFlowChartsManagerProps {
  netflowData: ChartData;
  inflowData: ChartData;
  outflowData: ChartData;
  totalflowData: ChartData;
  hourlyNetflowData?: ChartData;
  hourlyInflowData?: ChartData;
  hourlyOutflowData?: ChartData;
  hourlyTotalflowData?: ChartData;
}

export const useFlowChartsManager = ({
  netflowData,
  inflowData,
  outflowData,
  totalflowData,
  hourlyNetflowData,
  hourlyInflowData,
  hourlyOutflowData,
  hourlyTotalflowData,
}: IUseFlowChartsManagerProps) => {
  const clonedNetflowData: ChartData = cloneDeep(netflowData);
  const clonedInflowData: ChartData = cloneDeep(inflowData);
  const clonedOutflowData: ChartData = cloneDeep(outflowData);
  const clonedTotalflowData: ChartData = cloneDeep(totalflowData);
  const clonedHourlyNetflowData: ChartData = hourlyNetflowData
    ? cloneDeep(hourlyNetflowData).map((data) => ({ ...data }))
    : [];

  const clonedHourlyInflowData: ChartData = hourlyInflowData
    ? cloneDeep(hourlyInflowData).map((data) => ({ ...data }))
    : [];

  const clonedHourlyOutflowData: ChartData = hourlyOutflowData
    ? cloneDeep(hourlyOutflowData).map((data) => ({ ...data }))
    : [];

  const clonedHourlyTotalflowData: ChartData = hourlyTotalflowData
    ? cloneDeep(hourlyTotalflowData).map((data) => ({ ...data }))
    : [];

  const filteredFlowDataChartOptions = useMemo(() => {
    const isNetflowAvailable = !!clonedNetflowData.length;
    const isInflowAvailable = !!clonedInflowData.length;
    const isOutflowAvailable = !!clonedOutflowData.length;
    const isTotalflowAvailable = !!clonedTotalflowData.length;
    const iHourlyNetflowAvailable = !!clonedHourlyNetflowData.length;
    const isHourlyInflowAvailable = !!clonedHourlyInflowData.length;
    const isHourlyOutflowAvailable = !!clonedHourlyOutflowData.length;
    const isHourlyTotalflowAvailable = !!clonedHourlyTotalflowData.length;

    return flowDataChartOptions.filter((flowOption) => {
      return (
        (flowOption.value === 'netflow' && isNetflowAvailable) ||
        (flowOption.value === 'inflow' && isInflowAvailable) ||
        (flowOption.value === 'outflow' && isOutflowAvailable) ||
        (flowOption.value === 'totalflow' && isTotalflowAvailable) ||
        (flowOption.value === 'netflow-hourly' && iHourlyNetflowAvailable) ||
        (flowOption.value === 'inflow-hourly' && isHourlyInflowAvailable) ||
        (flowOption.value === 'outflow-hourly' && isHourlyOutflowAvailable) ||
        (flowOption.value === 'totalflow-hourly' && isHourlyTotalflowAvailable)
      );
    });
  }, [
    clonedInflowData.length,
    clonedNetflowData.length,
    clonedOutflowData.length,
    clonedTotalflowData.length,
    clonedHourlyNetflowData.length,
    clonedHourlyInflowData.length,
    clonedHourlyOutflowData.length,
    clonedHourlyTotalflowData.length,
  ]);

  const isMoreThanOneFlowDataOption = useMemo(
    () => filteredFlowDataChartOptions.length > 1,
    [filteredFlowDataChartOptions],
  );

  const areFlowDataOptionsAvailable = useMemo(
    () => filteredFlowDataChartOptions.length !== 0,
    [filteredFlowDataChartOptions],
  );

  const getInitialSelection = () => {
    return areFlowDataOptionsAvailable
      ? isOfType<TFlowChart>(filteredFlowDataChartOptions[0].value, flowOptions)
        ? filteredFlowDataChartOptions[0].value
        : undefined
      : undefined;
  };

  const [selectedFlowDataChart, setSelectedFlowDataChart] = useState<TFlowChart | undefined>(getInitialSelection());

  const handleSelectedFlowDataChange = (value: string) => {
    if (isOfType<TFlowChart>(value, flowOptions)) {
      setSelectedFlowDataChart(value);
    } else {
      console.error("The value doesn't below to the flow-data chart options.");
    }
  };

  const getFlowData = (selectedFlowDataChart: TFlowChart | undefined) => {
    switch (selectedFlowDataChart) {
      case 'netflow':
        return clonedNetflowData;
      case 'inflow':
        return clonedInflowData;
      case 'outflow':
        return clonedOutflowData;
      case 'totalflow':
        return clonedTotalflowData;
      case 'netflow-hourly':
        return clonedHourlyNetflowData;
      case 'inflow-hourly':
        return clonedHourlyInflowData;
      case 'outflow-hourly':
        return clonedHourlyOutflowData;
      case 'totalflow-hourly':
        return clonedHourlyTotalflowData;
      default:
        return [];
    }
  };

  const getTitle = (flowType: TFlowChart | undefined) => {
    switch (flowType) {
      case 'netflow':
        return 'Net Asset Flows';
      case 'inflow':
        return 'Inflows';
      case 'outflow':
        return 'Outflows';
      case 'totalflow':
        return 'Total Flows';
      case 'netflow-hourly':
        return 'Net Asset Flows Hourly';
      case 'inflow-hourly':
        return 'Inflows Hourly';
      case 'outflow-hourly':
        return 'Outflows Hourly';
      case 'totalflow-hourly':
        return 'Total Flows Hourly';
      default:
        return '';
    }
  };

  const shouldRenderSelect = !!selectedFlowDataChart && isMoreThanOneFlowDataOption;
  const shouldRenderFlowsChart = !!selectedFlowDataChart && areFlowDataOptionsAvailable;
  const isHourlyChart =
    !!selectedFlowDataChart &&
    (selectedFlowDataChart === 'netflow-hourly' ||
      selectedFlowDataChart === 'inflow-hourly' ||
      selectedFlowDataChart === 'outflow-hourly' ||
      selectedFlowDataChart === 'totalflow-hourly');

  const generatedFlowData = useMemo(() => getFlowData(selectedFlowDataChart), [selectedFlowDataChart]);
  const generatedTitle = useMemo(() => getTitle(selectedFlowDataChart), [selectedFlowDataChart]);

  return {
    shouldRenderSelect,
    filteredFlowDataChartOptions,
    handleSelectedFlowDataChange,
    selectedFlowDataChart,
    shouldRenderFlowsChart,
    generatedFlowData,
    generatedTitle,
    isHourlyChart,
  };
};
