import React from 'react';
import { SettingsView } from '@/views/SettingsView/SettingsView';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';

const SettingsPage: React.FC = () => {
  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <SettingsView />
    </ErrorBoundary>
  );
};

export default SettingsPage;
