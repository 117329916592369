import { ChartData } from '@/types/chartPoint';

interface DataItem {
  timestamp: string;
  value: number | null;
}

export const mapHourlyData = (data: Array<DataItem>): ChartData => {
  let mapped: ChartData = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: item.value,
      };
    });

  return mapped;
};
