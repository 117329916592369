import { useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { Stack } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { emptyRow } from '@/views/LossCalculatorView/utils/constants';
import { useUploadCustomCSV } from '@/views/LossCalculatorView/utils/useUploadCustomCSV';
import Button from '@/components/atoms/Button/Button';
import HelpIcon from '@/assets/icons/help.svg';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import AllocationRow from '@/components/LossCalculator/AllocationRow';
import { sortAlphabetically } from '@/helpers/helpers';
import { ILossCalculatorForm } from '@/views/LossCalculatorView/utils/types';
import { IStatisticalRatingEntity } from '@/types/ratings';
import {
  StyledCalculatorTopRow,
  StyledFormHelperText,
  StyledGridContainer,
  StyledGridHeader,
  StyledHeader,
  StyledSectionTitle,
} from '@/views/LossCalculatorView/PortfolioLossCalculator.styled';
import { StyledDatePicker } from '@/views/LossCalculatorView/components/PortfolioAllocation/PortfolioAllocation.styled';
import { IDatePickerRange } from '@/types/datePicker';

export interface IPortfolioAllocationProps {
  formMethods: UseFormReturn<ILossCalculatorForm>;
  ratings: IStatisticalRatingEntity[];
  totalExposure: number;
  clearResultsOfLossCalculation: () => void;
}

export const PortfolioAllocation = ({
  formMethods,
  ratings,
  totalExposure,
  clearResultsOfLossCalculation,
}: IPortfolioAllocationProps) => {
  const {
    reset,
    formState: { errors },
    clearErrors,
  } = formMethods;

  const { fields, replace, remove, append } = useFieldArray({
    name: 'rows',
    control: formMethods.control,
  });

  const isMultipleEntities = useMemo(() => fields.length > 1, [fields]);

  const entityError = errors.rows?.root;

  const availableDatesRange: IDatePickerRange = useMemo(
    () => ({
      start: dayjs(ratings[0].data[ratings[0].data.length - 1].timestamp.split('T')[0]),
      end: dayjs(ratings[0].data[0].timestamp.split('T')[0]),
    }),
    [ratings],
  );

  const [selectedDate, setSelectedDate] = useState<Dayjs>(availableDatesRange.end);

  const handleDatePickerChange = (newValue: unknown) => {
    if (dayjs.isDayjs(newValue)) {
      setSelectedDate(newValue);
    }
  };

  const exchangeList = useMemo(() => {
    const exchanges = ratings.map((sr) => sr.entity);

    return sortAlphabetically(exchanges);
  }, [ratings]);

  const timestamp = useMemo(() => {
    return selectedDate.format('YYYY-MM-DD');
  }, [selectedDate]);

  const { fileInputRef, handleFileInputChange, handleUploadExposureButtonClick } = useUploadCustomCSV({
    ratings,
    exchangeList,
    replace,
    timestamp,
  });

  const handleAddRow = () => {
    append(emptyRow);

    if (entityError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearErrors('rows.root');
    }
  };

  const handleAddCustomRow = () => {
    const emptyCustomRow = { ...emptyRow, isCustom: true };
    append(emptyCustomRow);

    if (entityError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearErrors('rows.root');
    }
  };

  const handleResetFields = () => {
    reset();
    clearResultsOfLossCalculation();
  };

  return (
    <StyledCalculatorTopRow>
      <StyledHeader>
        <StyledSectionTitle variant="h5">I. PORTFOLIO ALLOCATION</StyledSectionTitle>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title="Adding all entities along with their respective recovery percentages and exposure in USD is crucial for comprehensive risk assessment. The recovery percentage represents the expected proportion of the portfolio's value that can be recovered in the event of a default, while exposure in USD quantifies the total amount at risk for each entity. This detailed input allows for a more accurate simulation of potential losses."
        >
          <img src={HelpIcon} alt="Help icon" />
        </Tooltip>
      </StyledHeader>

      <StyledGridContainer>
        <StyledGridHeader>Select Date:</StyledGridHeader>
        <StyledGridHeader>Exchange ID</StyledGridHeader>
        <StyledGridHeader active={false}>1-YR PD value</StyledGridHeader>
        <StyledGridHeader>Recovery (%)</StyledGridHeader>
        <StyledGridHeader>Exposure (USD)</StyledGridHeader>
        <StyledGridHeader active={false}>Exposure (%)</StyledGridHeader>
        <StyledGridHeader />

        <div>
          <StyledDatePicker
            name="select-date"
            format="YYYY-MM-DD"
            minDate={availableDatesRange.start}
            maxDate={availableDatesRange.end}
            value={selectedDate}
            onChange={handleDatePickerChange}
          />
        </div>
        {fields.map((r, idx) => (
          <AllocationRow
            key={r.id}
            formMethods={formMethods}
            exchangeList={exchangeList}
            ratings={ratings}
            idx={idx}
            totalExposure={totalExposure}
            timestamp={timestamp}
            isCustom={r.isCustom}
            removeRow={remove}
            shouldDisplayDeleteBtn={isMultipleEntities}
          />
        ))}
      </StyledGridContainer>

      <Stack direction="row" gap="20px">
        <Button variant="contained" color="secondary" fitContentWidth onClick={handleResetFields}>
          Reset Fields
        </Button>
        <Button variant="contained" fitContentWidth onClick={handleAddRow}>
          + Add Exchange
        </Button>
        <Button variant="contained" fitContentWidth onClick={handleAddCustomRow}>
          + Add Custom Exchange
        </Button>
        <input type="file" accept=".csv" multiple={false} ref={fileInputRef} hidden onChange={handleFileInputChange} />
        <Button variant="contained" fitContentWidth onClick={handleUploadExposureButtonClick}>
          Upload Exposure
        </Button>
      </Stack>

      {!!entityError && <StyledFormHelperText>{entityError.message}</StyledFormHelperText>}
    </StyledCalculatorTopRow>
  );
};
