import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import UserList from '@/components/molecules/AdminUserList/AdminUserList';
import AdminPanelUserCounter from '@/components/molecules/AdminPanelUserCounter/AdminPanelUserCounter';
import { useGetUserList } from '@/api/adminUser/adminUser.hooks';
import { TablePagination } from '@/components/TablePagination/TablePagination';

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60vw;
`;

const AdminPanelWrapperUserList = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const availablePageSizes = [5, 10, 25];
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const { isLoading, userList, error, fetchNextPage, refetch, isFetching } = useGetUserList({ size });

  const totalUsers = useMemo(() => {
    return userList?.pages[0].total || 0;
  }, [userList]);

  const toggleCreateUserModal = () => {
    setCreateModalOpen(!isCreateModalOpen);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
    fetchNextPage();
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value);
    setPage(0);
  };

  if (isLoading) return <div style={{ color: 'white' }}>Loading users list...</div>;
  if (error) return <div style={{ color: 'white' }}>Error getting users: {error.message}</div>;
  if (!userList) return <div style={{ color: 'white' }}>There is no users to display</div>;

  return (
    <>
      <CounterWrapper>
        <AdminPanelUserCounter count={totalUsers} label="Total Users" />
        <AdminPanelUserCounter count={totalUsers} label="Active Users" />
        <AdminPanelUserCounter count={0} label="Suspended Users" />
      </CounterWrapper>
      <UserList
        title="User List"
        users={userList.pages[page]?.items || []}
        isCreateModalOpen={isCreateModalOpen}
        toggleCreateUserModal={toggleCreateUserModal}
        refetchUsersList={refetch}
      />
      <TablePagination
        pageSize={size}
        availablePageSizes={availablePageSizes}
        handlePageSize={handlePageSize}
        currentPage={page}
        handlePageChange={handlePageChange}
        totalDataNumber={totalUsers}
        isFetching={isFetching}
      />
    </>
  );
};
export default AdminPanelWrapperUserList;
