import styled from '@emotion/styled';
import { Pagination } from '@/components/atoms/Pagination/Pagination';

const COLLAPSED_ALERTS_LIST_WIDTH = '452px';

export const StyledContainerGrid = styled.div<{ isGridCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: ${({ isGridCollapsed }) => isGridCollapsed && COLLAPSED_ALERTS_LIST_WIDTH};
  width: ${({ isGridCollapsed }) => !isGridCollapsed && '100%'};
`;

export const StyledPagination = styled(Pagination)`
  display: flex;
  align-self: flex-end;
`;

export const StyledLoaderContainer = styled.div`
  padding-top: 20px;
  flex: 1 0 ${COLLAPSED_ALERTS_LIST_WIDTH};
`;
