import { RatingsRanking } from '@/views/RatingsView/components/RatingsRanking/RatingsRanking';
import { IndustryAverageTimeseries } from '@/views/RatingsView/components/IndustryAverageTimeseries/IndustryAverageTimeseries';
import { TopMovers } from '@/views/RatingsView/components/TopMovers/TopMovers';
import {
  StyledLeftContainer,
  StyledRightContainer,
  StyledTwoColumnLayout,
} from '@/views/RatingsView/RatingsView.styled';
import { IStatisticalRatingEntity, IStatisticalRatingDataPoint, IStatisticalRatingTopMovers } from '@/types/ratings';

export interface IRatingsViewProps {
  fetchedRatings: IStatisticalRatingEntity[];
  industryAverage: IStatisticalRatingDataPoint[];
  topMovers: IStatisticalRatingTopMovers[];
}

export const RatingsView = ({ fetchedRatings, industryAverage, topMovers }: IRatingsViewProps) => {
  return (
    <StyledTwoColumnLayout>
      <StyledLeftContainer>
        <RatingsRanking fetchedRatings={fetchedRatings} />
      </StyledLeftContainer>

      <StyledRightContainer>
        <IndustryAverageTimeseries industryAverage={industryAverage} />
        <TopMovers topMovers={topMovers} />
      </StyledRightContainer>
    </StyledTwoColumnLayout>
  );
};
