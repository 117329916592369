import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IDocumentationApi, IGetDocumentationResponse } from '@/api/documentation/documentation.types';

export class DocumentationApi implements IDocumentationApi {
  private getBaseUrl(): string {
    return ApiEndpoints.DOCUMENTATION;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getDocumentation() {
    return this.httpClient.get<IGetDocumentationResponse>(`${this.getBaseUrl()}whitepaper`);
  }
}
