import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetLossCalculations } from '@/api/calculators/calculators.hooks';
import { useMixpanelTracking } from '@/hooks/useMixpanelTracking';
import { formatFloatToPercent, formatToUSD } from '@/helpers/helpers';
import {
  mapDataDefaultsFrequencyToTransformedDataFrequency,
  mapDataLossFrequencyToTransformedDataFrequency,
} from '@/helpers/dataMappers';
import {
  defaultLossCalculatorFormValues,
  defaultResultOfLossCalculation,
} from '@/views/LossCalculatorView/utils/constants';
import { LossCalculatorSchema } from '@/views/LossCalculatorView/utils/validationSchema';
import { ILossCalculatorForm, IResultOfLossCalculation } from '@/views/LossCalculatorView/utils/types';
import { IGetLossCalculationsRequest, IGetLossCalculationsResponse } from '@/api/calculators/calculators.types';

export const useLossCalculatorForm = () => {
  const lossCalculatorMethods = useForm<ILossCalculatorForm>({
    defaultValues: defaultLossCalculatorFormValues,
    resolver: zodResolver(LossCalculatorSchema),
  });

  const { handleSubmit } = lossCalculatorMethods;

  const { getLossCalculations, isPending } = useGetLossCalculations();
  const { trackEvent } = useMixpanelTracking();

  const [resultOfLossCalculation, setResultOfLossCalculation] =
    useState<IResultOfLossCalculation>(defaultResultOfLossCalculation);

  const clearResultsOfLossCalculation = () => setResultOfLossCalculation(defaultResultOfLossCalculation);

  const onCalculateLossSubmit = useCallback(
    async (data: ILossCalculatorForm) => {
      const payload: IGetLossCalculationsRequest = {
        correlation: data.correlation / 10,
        entities: data.rows.map((r) => ({
          agioId: r.entity,
          probDefault: r.pd / 100,
          recoveryPct: r.recovery / 100,
          exposureUsd: r.exposure,
        })),
        lossThresholdPct: data.extremeLossProbability ? Number(data.extremeLossProbability) / 100 : undefined,
        alphaPct: data.extremeLossAlpha ? Number(data.extremeLossAlpha) / 100 : undefined,
      };

      if (data.extremeLossProbability || data.extremeLossAlpha) {
        trackEvent('Calculate Loss', { extremeThresholds: true });
      } else {
        trackEvent('Calculate Loss', { extremeThresholds: false });
      }

      getLossCalculations(payload, {
        onSuccess: (response: IGetLossCalculationsResponse) => {
          setResultOfLossCalculation({
            expectedLossUsd: formatToUSD(response.expectedLossUsd),
            unexpectedLossUsd: formatToUSD(response.unexpectedLossUsd),
            expectedLossPct: formatFloatToPercent(response.expectedLossPct),
            unexpectedLossPct: formatFloatToPercent(response.unexpectedLossPct),
            zeroDefaults: formatFloatToPercent(response.zeroDefaults),
            probExceedingLoss: response.probExceedingLoss
              ? formatFloatToPercent(response.probExceedingLoss)
              : undefined,
            creditVar: response.creditVar ? formatFloatToPercent(response.creditVar) : undefined,
            defaultsFrequency: response.defaultsFrequency
              ? mapDataDefaultsFrequencyToTransformedDataFrequency(response.defaultsFrequency)
              : undefined,
            lossFrequency: response.lossFrequency
              ? mapDataLossFrequencyToTransformedDataFrequency(response.lossFrequency)
              : undefined,
          });
        },
        onError: (error) => {
          clearResultsOfLossCalculation();
          console.error(error);
        },
      });
    },
    [getLossCalculations, trackEvent],
  );

  const handleLossCalculatorFormSubmit = handleSubmit(onCalculateLossSubmit);

  return {
    lossCalculatorMethods,
    handleLossCalculatorFormSubmit,
    resultOfLossCalculation,
    clearResultsOfLossCalculation,
    isCalculationLoading: isPending,
  };
};
