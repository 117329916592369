import styled from '@emotion/styled';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledPanelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  width: 80vw;
  margin-bottom: 35px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const StyledPanelWrapper = styled.div`
  width: 100vw;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80vw;
`;
