import { useCallback, useState } from 'react';
import { notificationsInitialState } from '@/constants/InitialState/notifications';
import { INotification } from '@/types/notifications';

export const useNotificationsPreferences = () => {
  const [notifications, setNotifications] = useState<INotification[]>(notificationsInitialState);

  const updateNotificationsPreferences = useCallback((newPreferences: INotification[]) => {
    setNotifications(newPreferences);
  }, []);

  const clearNotificationsPreferences = useCallback(() => setNotifications(notificationsInitialState), []);

  const handleNotificationPreferenceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setNotifications(notifications.map((n) => (n.name === name ? { ...n, enabled: checked } : n)));
    },
    [notifications],
  );

  return {
    notifications,
    updateNotificationsPreferences,
    clearNotificationsPreferences,
    handleNotificationPreferenceChange,
  };
};
