import { IEditUserPasswordRequest, IEditUserRequest, IUserApi } from '@/api/user/user.types';
import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IUserDto } from '@/types/user';

export class UserApi implements IUserApi {
  private getBaseUrl(): string {
    return ApiEndpoints.USER_BASE_URL;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getUser(token: string, id: string) {
    return this.httpClient.get<IUserDto>(`${this.getBaseUrl()}/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getUserById(id: string) {
    return this.httpClient.get<IUserDto>(`${this.getBaseUrl()}/${id}/`);
  }

  editUser({ id, ...payload }: IEditUserRequest) {
    return this.httpClient.patch<Partial<IEditUserRequest>, IUserDto>(`${this.getBaseUrl()}/${id}/`, payload);
  }

  editUserPassword({ id, ...payload }: IEditUserPasswordRequest) {
    return this.httpClient.patch<Partial<IEditUserPasswordRequest>, IUserDto>(
      `${this.getBaseUrl()}/${id}/update_password/`,
      payload,
    );
  }
}
