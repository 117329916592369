import { Typography } from '@mui/material';
import { useFetchAlertsList } from '@/views/AlertsView/components/AlertsList/useFetchAlertsList';
import CollapsibleDataGrid from '@/components/organims/DataGrid/CollapsibleDataGrid';
import Loader from '@/components/atoms/Loader/Loader';
import { StyledContainerGrid, StyledLoaderContainer, StyledPagination } from './AlertsList.styled';

interface IAlertsListProps {
  isGridCollapsed: boolean;
  handleSelectedAlert: (rowId: string) => void;
  handleToggleCollapse: () => void;
}

export const AlertsList = ({ isGridCollapsed, handleSelectedAlert, handleToggleCollapse }: IAlertsListProps) => {
  const {
    fetchedAlertsList,
    totalAlertsNumber,
    isAlertsListLoading,
    alertsListError,
    isAlertsListExist,
    pageSize,
    page,
    handlePage,
  } = useFetchAlertsList();

  if (isAlertsListLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (alertsListError) {
    throw alertsListError;
  }

  if (!isAlertsListExist) return <Typography color="white.100">There is no alerts to display.</Typography>;

  return (
    <StyledContainerGrid isGridCollapsed={isGridCollapsed}>
      <CollapsibleDataGrid
        data={fetchedAlertsList}
        isCollapsed={isGridCollapsed}
        toggleCollapse={handleToggleCollapse}
        onRowSelected={handleSelectedAlert}
      />
      <StyledPagination
        totalNumberOfItems={totalAlertsNumber}
        pageSize={pageSize}
        currentPage={page}
        setPage={handlePage}
      />
    </StyledContainerGrid>
  );
};
