import { useCallback } from 'react';
import { Box, Link, Typography } from '@mui/material';
import Button from '@/components/atoms/Button/Button';
import { TCommonErrorProps } from '@/components/Error/CommonError/CommonError.types';

export const CommonError = ({ variant, redirectUrl, onClick }: TCommonErrorProps) => {
  const generateButton = useCallback(() => {
    if (variant === 'button') {
      return (
        <Button variant="contained" fitContentWidth onClick={onClick} sx={{ marginTop: '10px' }}>
          Try again
        </Button>
      );
    }

    if (variant === 'reload') {
      const reloadPage = () => {
        window.location.reload();
      };

      return (
        <Button variant="contained" fitContentWidth onClick={reloadPage} sx={{ marginTop: '10px' }}>
          Reaload the page
        </Button>
      );
    }

    if (variant === 'link') {
      return (
        <Link href={redirectUrl} underline="hover" sx={{ marginTop: '25px' }}>
          Go to main page
        </Link>
      );
    }

    return null;
  }, [variant, redirectUrl, onClick]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="10px"
      p="10px 25px"
      m="40px auto"
    >
      <Typography variant="h2" color="white.100" align="center">
        Something went wrong
      </Typography>
      <Typography color="white.70" align="center">
        We couldn’t load the information you were looking for.
      </Typography>
      {generateButton()}
    </Box>
  );
};
