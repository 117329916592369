import { EFileKeys, IRow } from '@/views/LossCalculatorView/utils/types';

export function entitiesChanged(entities: IRow[], newRows: IRow[]): boolean {
  if (entities.length !== newRows.length) {
    return true;
  }

  for (let i = 0; i < entities.length; i++) {
    if (entities[i].entity !== newRows[i].entity) {
      return true;
    }

    if (entities[i].pd !== newRows[i].pd) {
      return true;
    }
  }

  return false;
}

export const checkRowsAreExcelRowsType = (rows: unknown[]): boolean => {
  if (!Array.isArray(rows)) return false;

  const areRowsExcelRows = rows.map((row) => {
    if (row && typeof row === 'object') {
      const keys = Object.keys(row);
      const enumValues = Object.values(EFileKeys);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return keys.every((key) => enumValues.includes(key));
    }

    return false;
  });

  if (areRowsExcelRows.length > 0) {
    return areRowsExcelRows.every((type) => type);
  }

  return false;
};
