import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserIdFromStorage } from '@/hooks/useUserIdFromToken';
import { defaultChangePasswordFormValues } from '@/components/organims/ChangePasswordForm/utils/constants';
import { IChangePasswordForm, SettingsSchema } from '@/components/organims/ChangePasswordForm/utils/types';
import { useEditUserPassword } from '@/api/user/user.hooks';
import { ICustomNotification } from '@/types/notifications';
import { initialCustomNotification } from '@/constants/InitialState/notifications';

export const useChangePasswordForm = () => {
  const userId = useUserIdFromStorage();

  const changePasswordMethods = useForm<IChangePasswordForm>({
    defaultValues: defaultChangePasswordFormValues,
    resolver: zodResolver(SettingsSchema),
  });
  const { handleSubmit, reset } = changePasswordMethods;

  const [changePasswordNotificationMessage, setChangePasswordNotificationMessage] =
    useState<ICustomNotification>(initialCustomNotification);

  const updatePasswordNotificationMessage = useCallback((props: ICustomNotification) => {
    setChangePasswordNotificationMessage(props);
  }, []);

  const clearPasswordNotificationMessage = useCallback(() => {
    setChangePasswordNotificationMessage(initialCustomNotification);
  }, []);

  const { editUserPassword, isLoading: isEditUserPasswordLoading } = useEditUserPassword();

  const onChangePasswordSubmit = useCallback(
    async (data: IChangePasswordForm) => {
      clearPasswordNotificationMessage();

      const payloadToSend = {
        id: userId || '',
        newPassword: data.newPass,
        currentPassword: data.currentPass,
      };
      editUserPassword(payloadToSend, {
        onSuccess: () => {
          reset(defaultChangePasswordFormValues);
          updatePasswordNotificationMessage({ text: 'Password updated successfully.', type: 'success' });
        },
        onError: (error) => {
          updatePasswordNotificationMessage({
            text: error.message,
            type: 'error',
          });
        },
      });
    },
    [userId],
  );

  const handleChangePasswordFormSubmit = handleSubmit(onChangePasswordSubmit);

  return {
    changePasswordMethods,
    changePasswordNotificationMessage,
    handleChangePasswordFormSubmit,
    isEditUserPasswordLoading,
  };
};
