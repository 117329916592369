import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IAdminAlertsApi,
  IGetAlertsListRequest,
  IGetAlertsListResponse,
  ICreateAlertUpdateRequest,
  IEditAlertRequest,
  IEditAlertUpdateRequest,
  IGetAlertRelatedDataResponse,
  ICreateAlertRequest,
} from '@/api/adminAlerts/adminAlerts.types';
import { IAlertDto, IExtendedAlertUpdateDto, IExtendedAlertDto } from '@/types/alert';
import { IEntity } from '@/types/entities';

export class AdminAlertsApi implements IAdminAlertsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_ALERTS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getAlertsList({ page, size }: Partial<IGetAlertsListRequest>) {
    const params = {
      page,
      size,
    };

    return this.httpClient.get<IGetAlertsListResponse>(this.getBaseUrl(), { params });
  }

  getAlertRelatedData(alertId: string) {
    return this.httpClient.get<IGetAlertRelatedDataResponse>(`${this.getBaseUrl()}${alertId}/related_data/`);
  }

  getAlertById(alertId: string) {
    return this.httpClient.get<IExtendedAlertDto>(`${this.getBaseUrl()}${alertId}/`);
  }

  createAlert({ anomalyId, insight, severity }: ICreateAlertRequest) {
    const params = {
      anomalyId,
      insight,
      severity,
    };

    return this.httpClient.post<Partial<ICreateAlertRequest>, IExtendedAlertDto>(this.getBaseUrl(), params);
  }

  editAlert({ alertId, insight, severity }: IEditAlertRequest) {
    const params = {
      insight,
      severity,
    };

    return this.httpClient.patch<Partial<Omit<IEditAlertRequest, 'alertId'>>, IAlertDto>(
      `${this.getBaseUrl()}${alertId}/`,
      params,
    );
  }

  deleteAlert(alertId: string) {
    return this.httpClient.delete<void>(`${this.getBaseUrl()}${alertId}/`);
  }

  createAlertUpdate({ alertId, title, timestamp, details, deleted, showChart, chartType }: ICreateAlertUpdateRequest) {
    const params = {
      title,
      timestamp,
      details,
      deleted,
      showChart,
      chartType,
    };

    return this.httpClient.post<Omit<ICreateAlertUpdateRequest, 'alertId'>, IExtendedAlertUpdateDto>(
      `${this.getBaseUrl()}${alertId}/updates/`,
      params,
    );
  }

  editAlertUpdate({ alertId, updateId, ...payload }: IEditAlertUpdateRequest) {
    const params = {
      title: payload.title,
      timestamp: payload.timestamp,
      details: payload.details,
      deleted: payload.deleted,
      showChart: payload.showChart,
      chartType: payload.chartType,
    };

    return this.httpClient.patch<
      Partial<Omit<IEditAlertUpdateRequest, 'alertId' | 'updateId'>>,
      IExtendedAlertUpdateDto
    >(`${this.getBaseUrl()}${alertId}/updates/${updateId}/`, params);
  }

  deleteAlertUpdate(alertId: string, updateId: string) {
    return this.httpClient.delete<void>(`${this.getBaseUrl()}${alertId}/updates/${updateId}/`);
  }

  getUniqueEntitiesList() {
    return this.httpClient.get<IEntity[]>(`${this.getBaseUrl()}entity`);
  }
}
