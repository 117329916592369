import { FormProvider } from 'react-hook-form';
import PersonalDetailsPanel from '@/components/molecules/PersonalDetailsPanel/PersonalDetailsPanel';
import NotificationPanel from '@/components/molecules/NotificationPanel/NotificationPanel';
import Button from '@/components/atoms/Button/Button';
import { usePersonalPreferencesForm } from '@/components/organims/PersonalPreferencesForm/utils/usePersonalPreferencesForm';
import {
  StyledPanelsContainer,
  StyledPanelWrapper,
  StyledButtonContainer,
  StyledForm,
} from './PersonalPreferencesForm.styled';
import { Typography } from '@mui/material';

export const PersonalPreferencesForm = () => {
  const {
    personalPreferencesMethods,
    handlePersonalPreferencesFormSubmit,
    isEditUserLoading,
    editUserNotificationMessage,
    fetchUserDataError,
  } = usePersonalPreferencesForm();

  if (fetchUserDataError) {
    throw fetchUserDataError;
  }

  return (
    <FormProvider {...personalPreferencesMethods}>
      <StyledForm onSubmit={handlePersonalPreferencesFormSubmit}>
        <StyledPanelsContainer>
          <StyledPanelWrapper>
            <PersonalDetailsPanel formMethods={personalPreferencesMethods} />
          </StyledPanelWrapper>

          <StyledPanelWrapper>
            <NotificationPanel formMethods={personalPreferencesMethods} />
          </StyledPanelWrapper>
        </StyledPanelsContainer>

        {!!editUserNotificationMessage && (
          <Typography color={editUserNotificationMessage.type === 'success' ? 'green' : 'red'}>
            {editUserNotificationMessage.text}
          </Typography>
        )}
        <StyledButtonContainer>
          <Button
            type="submit"
            variant="contained"
            fitContentWidth
            sx={{ minWidth: '142px' }}
            disabled={isEditUserLoading}
          >
            Save changes
          </Button>
        </StyledButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};
