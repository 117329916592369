import { LossCalculatorView } from '@/views/LossCalculatorView/LossCalculatorView';
import { useMixpanelTracking } from '@/hooks/useMixpanelTracking';
import Loader from '@/components/atoms/Loader/Loader';
import { Container } from '@/views/LossCalculatorView/LossCalculator.styled';
import { useGetRatingsList } from '@/api/ratings/ratings.hooks';

export const LossCalculator = () => {
  const { ratingsList, isLoading, error } = useGetRatingsList();
  const { trackPageView } = useMixpanelTracking();

  if (isLoading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (error) {
    throw error;
  }

  if (!ratingsList || (ratingsList && !ratingsList.data.length)) {
    throw Error('Not found ratings data');
  }

  trackPageView('Portfolio Risk Calculator');

  return <LossCalculatorView fetchedRatings={ratingsList.data} />;
};
