import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IAdminRatingsApi, IUpdateRatingsFileResponse } from '@/api/adminRatings/adminRatings.types';

export class AdminRatingsApi implements IAdminRatingsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_RATINGS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  updateRatingsFile(fileId = '1') {
    return this.httpClient.get<IUpdateRatingsFileResponse>(`${this.getBaseUrl()}update_ratings/${fileId}/`);
  }
}
