import React from 'react';
import { Detail } from '../Detail/Detail';
import { DirectionColouredText } from '@/components/DirectionColouredText/DirectionColouredText';

export const MovementDetail = ({ label, value }: { label: string; value: number }) => (
  <Detail
    label={label}
    value={
      <DirectionColouredText isPositiveDirection={value < 0} isNeutral={value === 0}>
        {value > 0 && '+'}
        {value.toFixed(2)}%
      </DirectionColouredText>
    }
  />
);
